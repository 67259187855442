import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  getSubZones,
  selectSubZone,
  unSelectSubZone,
} from "../../actions/campaign-planning/ZonesAndSubZonesActions";

// Components
import CheckBox from "../../../components/check-box/CheckBox";
import { DataLoading, HeaderWithActionButton } from "./PlanningUtils";

// Sub Zones
function SubZoneList({
  subZones = [],
  subZonesLoading = false,
  selectedZoneId = "",
  selectedZoneName = "",
  selectedZonesAndSubZonesMap = {},
}) {
  const dispatch = useDispatch();

  // checking sub zones present
  const isSubZonesPresent = subZones.length > 0;

  // sub zones for selected zone
  const selectedSubZones = selectedZonesAndSubZonesMap[selectedZoneId] || [];
  const selectedSubZonesIds = selectedSubZones.map(
    (subZone) => subZone.subZoneId
  );

  // function
  function handleSelectSubZone(e, subZoneId) {
    const { checked } = e.target;

    // find selected subZone
    const selectedSubZone = subZones.find(
      (subZone) => subZone.id === subZoneId
    );

    // construct subZone Object
    const subZone = {
      zoneId: selectedZoneId,
      zoneName: selectedZoneName,
      subZoneId: subZoneId,
      subZoneName: selectedSubZone.name,
    };

    // dispatch to select/unselect
    const dispatchFn = checked ? selectSubZone : unSelectSubZone;
    dispatch(dispatchFn(subZone));
  }

  return (
    <div className="col-6 px-0 border-left">
      <h5 className="my-1 px-3 py-2">Zone</h5>
      <hr className="my-0" />

      {/* sub zones loading */}
      {subZonesLoading && (
        <DataLoading
          dataType="Zone"
          className="my-2 px-3 py-2"
          spinnerClassName="spinner-border-sm"
        />
      )}

      {/* no sub zones */}
      {!subZonesLoading && !isSubZonesPresent && (
        <p className="text-center font-italic text-muted my-2 px-3 py-2">
          No Zone Available
        </p>
      )}

      {/* sub zones list */}
      {!subZonesLoading &&
        isSubZonesPresent &&
        subZones.map((subZone) => {
          const { name, id } = subZone;
          return (
            <div
              key={id}
              className="d-flex justify-content-between mt-1 px-3 py-2"
            >
              <p className="mb-0" title={name}>
                {name}
              </p>
              <CheckBox
                boxStyle="mb-0"
                checkBoxKey={id}
                showLabel={false}
                checked={selectedSubZonesIds.includes(id)}
                onChange={handleSelectSubZone}
              />
            </div>
          );
        })}
    </div>
  );
}

// Zones
function ZoneList({
  zones = [],
  selectedZoneId,
  setSelectedZoneId = () => {},
  setSelectedZoneName = () => {},
  selectedZonesAndSubZonesMap = {},
}) {
  const dispatch = useDispatch();

  function handleSelectZone(id, name) {
    setSelectedZoneId(id);
    setSelectedZoneName(name);

    // api to get sub zones
    dispatch(getSubZones(id));
  }

  return (
    <div className="col-6 px-0 border-right">
      <h5 className="my-1 px-3 py-2">City</h5>
      <hr className="my-0" />

      <div className="px-3">
        {zones.map((zone) => {
          const { name, id } = zone;
          const isZoneSelected = selectedZoneId === id;
          const selectedBgStyle = isZoneSelected ? "bg-alt rounded-lg" : "";
          const selectedSubZones = selectedZonesAndSubZonesMap[id] || [];
          return (
            <div
              key={id}
              className={`d-flex align-items-center justify-content-between p-2 cursor-pointer my-2 ${selectedBgStyle}`}
              onClick={() => handleSelectZone(id, name)}
            >
              <p className="mb-0">{name}</p>
              <p className="mb-0">
                {selectedSubZones.length > 0 && (
                  <span className="mr-2">({selectedSubZones.length})</span>
                )}
                <i
                  className={`fa ${
                    isZoneSelected
                      ? "fa-angle-down text-primary"
                      : "fa-angle-right"
                  }`}
                />
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

// Zones And SubZones List
function ZonesAndSubZonesList({
  zones = [],
  zonesLoading = false,
  subZones = [],
  subZonesLoading = false,
  selectedZonesAndSubZonesMap = {},
}) {
  const [selectedZoneId, setSelectedZoneId] = useState("");
  const [selectedZoneName, setSelectedZoneName] = useState("");

  if (zonesLoading) {
    return <DataLoading dataType="City / Zone" />;
  }

  if (zones.length === 0) {
    return (
      <p className="text-center font-italic text-muted">
        No City / Zone Available
      </p>
    );
  }

  return (
    <div className="w-100 d-flex border rounded-lg overflow-hidden">
      <ZoneList
        zones={zones}
        selectedZoneId={selectedZoneId}
        setSelectedZoneId={setSelectedZoneId}
        setSelectedZoneName={setSelectedZoneName}
        selectedZonesAndSubZonesMap={selectedZonesAndSubZonesMap}
      />
      {selectedZoneId && (
        <SubZoneList
          subZones={subZones}
          subZonesLoading={subZonesLoading}
          selectedZoneId={selectedZoneId}
          selectedZoneName={selectedZoneName}
          selectedZonesAndSubZonesMap={selectedZonesAndSubZonesMap}
        />
      )}
    </div>
  );
}

export default function ZonesTab({
  selectedCityId = "",
  handleTabHeaderBtnClick = () => {},
  tabHeaderBtnLoading = false,
}) {
  // zones
  const zones = useSelector((state) => state.zonesAndSubZones.zones);
  const zonesLoading = useSelector(
    (state) => state.zonesAndSubZones.zonesLoading
  );

  // subZones
  const subZones = useSelector((state) => state.zonesAndSubZones.subZones);
  const subZonesLoading = useSelector(
    (state) => state.zonesAndSubZones.subZonesLoading
  );

  // selected Zones And SubZones Map
  const selectedZonesAndSubZonesMap = useSelector(
    (state) => state.zonesAndSubZones.selectedZonesAndSubZonesMap
  );

  // find selected zone (that is city)
  const selectedZone =
    zones.find((eachZone) => eachZone.id === selectedCityId) || {};

  return (
    <>
      <HeaderWithActionButton
        title="City / Zone"
        buttonText="Confirm"
        onButtonClick={handleTabHeaderBtnClick}
        buttonLoading={tabHeaderBtnLoading}
      />

      {/* Zones and SubZones List */}
      <ZonesAndSubZonesList
        zones={[selectedZone]}
        zonesLoading={zonesLoading}
        subZones={subZones}
        subZonesLoading={subZonesLoading}
        selectedZonesAndSubZonesMap={selectedZonesAndSubZonesMap}
      />
    </>
  );
}
