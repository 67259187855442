import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Constant and Utils
import {
  constructDateString,
  getDifferenceInDays,
} from "../../../common-utils/date-utils/DateUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import {
  toLocaleString,
  formatText,
} from "../../../common-utils/string-utils/StringUtils";
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { CampaignState } from "./planningConstants";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";
import { getCitySpecificMediaList } from "../media-selection/MediaContainerUtil";

// Components
import { CityRedirect } from "./CampaignRedirect";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import TableHeaders from "../../../components/table/TableHeaders";

// Section functions
function getImpTableHeaders(isShowLiveHeaders = false) {
  // live headers for impressions
  if (isShowLiveHeaders) {
    return [
      {
        title: {
          displayName: "Live Target Imp",
          className: "text-right align-top",
        },
        subTitle: {
          displayName: "Est Target Imp",
          className: "sub-text text-right",
        },
      },
      {
        title: {
          displayName: "Live General Imp",
          className: "text-right align-top",
        },
        subTitle: {
          displayName: "Est General Imp",
          className: "sub-text text-right",
        },
      },
    ];
  }

  // only Est Imp
  return [
    {
      title: {
        displayName: "Est Target Imp",
        className: "text-right align-top",
      },
    },
    {
      title: {
        displayName: "Est General Imp",
        className: "text-right align-top",
      },
    },
  ];
}

function getActionSTableHeaderS(isDraftOrReviewCampaign) {
  if (!isDraftOrReviewCampaign) {
    return [];
  }

  // adding "actions" only if DRAFT & REVIEW
  return [
    {
      title: {
        displayName: "Actions",
        className: "text-right",
      },
    },
  ];
}

function cityDetailsHeaders(planStatus) {
  const isDraftCampaign = planStatus === CampaignState.DRAFT;
  const isReviewCampaign = planStatus === CampaignState.REVIEW;

  // hiding live headers for DRAFT & REVIEW state
  const isDraftOrReviewCampaign = isDraftCampaign || isReviewCampaign;
  const isShowLiveHeaders = !isDraftOrReviewCampaign;

  const impressionTableHeader = getImpTableHeaders(isShowLiveHeaders);
  const actionTableHeader = getActionSTableHeaderS(isDraftOrReviewCampaign);

  return [
    {
      title: {
        displayName: "City",
        className: "",
      },
      subTitle: {
        displayName: "Status",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "No. of Stretches",
        className: "align-top text-right",
      },
    },
    {
      title: {
        displayName: "No. of Sites",
        className: "align-top text-right",
      },
    },
    {
      title: {
        displayName: "Start and End date",
        className: "text-right",
      },
      subTitle: {
        displayName: "Duration",
        className: "sub-text text-right",
      },
    },
    ...impressionTableHeader,
    ...actionTableHeader,
  ];
}

function CityStatus({ cityInfo = {} }) {
  const { isCompleted, isLive, isUpcoming } = cityInfo || {};

  if (isUpcoming) {
    return <p className="mb-0 text-pending">Upcoming</p>;
  }

  if (isCompleted) {
    return <p className="mb-0 text-success">Completed</p>;
  }

  if (isLive) {
    return <p className="mb-0 text-success">Live</p>;
  }

  return null;
}

// Section Components
function CityName({
  basicCityDetails = {},
  campaignId,
  campaignPlanState,
  cityInfo = {},
}) {
  if (!basicCityDetails) {
    return null;
  }

  const { cityName = "--", cityId } = basicCityDetails || {};

  return (
    <td>
      <CityRedirect
        className="mb-0"
        campaignId={campaignId}
        cityId={cityId}
        campaignPlanState={campaignPlanState}
        displayText={cityName}
      />
      {/* <CityStatus cityInfo={cityInfo} /> */}
    </td>
  );
}

function StretchCount({ cityInfo }) {
  if (!cityInfo) {
    return null;
  }

  const { roadStretchOtsMap = {} } = cityInfo;
  const stretchCount = Object.keys(roadStretchOtsMap).length;

  const formattedStretchesCount =
    stretchCount === 0 ? 0 : formatText(toLocaleString(stretchCount));

  return (
    <td className="text-right">
      <span>{formattedStretchesCount}</span>
    </td>
  );
}

function SitesCount({ basicCityDetails = {} }) {
  const { mediaCount = 0, activeMediaCount = 0 } = basicCityDetails || {};

  const formattedMediaCount =
    mediaCount === 0 ? 0 : formatText(toLocaleString(mediaCount));
  const formattedActiveMediaCount =
    activeMediaCount === 0 ? 0 : formatText(toLocaleString(activeMediaCount));

  return (
    <td className="text-right">
      <p className="mb-0">
        <b>{formattedActiveMediaCount}</b> of <b>{formattedMediaCount}</b> are
        Live
      </p>
    </td>
  );
}

function Duration({ cityInfo }) {
  const { startTimestamp, endTimestamp } = cityInfo;
  const diff = getDifferenceInDays(startTimestamp, endTimestamp);
  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  return (
    <td className="text-right">
      <div className="text-right">
        {dateString}
        <span className="sub-text">{`${diff} days`}</span>
      </div>
    </td>
  );
}

function getLiveAndEstImpForTgAndGeneric(cityInfo = {}, cityMedias = []) {
  const { roadStretchOtsMap = {} } = cityInfo || {};
  const isCityMediasPresent = cityMedias.length > 0;

  if (!isCityMediasPresent) {
    return { genericImp: 0, targetImp: 0 };
  }

  const liveAndEstImpressionsMap = cityMedias.reduce(
    (acc, media) => {
      const { roadStretchId } = media || {};
      const stretchOtsInfo = roadStretchOtsMap[roadStretchId] || {};

      const { ots, otsLit, targetOts, targetOtsLit } = stretchOtsInfo || {};
      const finalGenericOts = otsLit ?? ots;
      const finalTargetOts = targetOtsLit ?? targetOts;

      // Total Impressions (generic, liveGeneric, target, liveTarget)
      if (finalGenericOts) {
        acc.genericImp = acc.genericImp + finalGenericOts;
      }
      if (finalTargetOts) {
        acc.targetImp = acc.targetImp + finalTargetOts;
      }

      return acc;
    },
    { genericImp: 0, targetImp: 0 }
  );

  return liveAndEstImpressionsMap;
}

function EstTgImp({
  cityInfo = {},
  cityMedias = [],
  campaignPerformance = {},
  isShowLiveMetrics = false,
}) {
  const { totalTargetOts, totalTargetOtsLit } = cityInfo || {};
  const estTargetImp = totalTargetOtsLit ?? totalTargetOts;
  const formattedEstTargetOts =
    estTargetImp === 0 ? 0 : formatText(toLocaleString(estTargetImp));
  const formattedLiveTargetOts = "--";

  // calculating total impression base on stretch specific medias
  const { targetImp } = getLiveAndEstImpForTgAndGeneric(cityInfo, cityMedias);
  const formattedTargetImp = formatText(toStringWithRoundUp(targetImp));

  // live imp
  const { targetOtsServedTotal } = campaignPerformance || {};
  const formattedTargetLiveImp = formatText(
    toStringWithRoundUp(targetOtsServedTotal)
  );

  return (
    <td className="text-right">
      {/* <p className="mb-0">{formattedLiveTargetOts}</p>
      <span className="sub-text">{formattedEstTargetOts}</span> */}

      {/* only est imp */}
      {!isShowLiveMetrics && <p className="mb-0">{formattedTargetImp}</p>}

      {/* both live & est */}
      {isShowLiveMetrics && (
        <>
          <p className="mb-0">{formattedTargetLiveImp}</p>
          <p className="mb-0 sub-text">{formattedTargetImp}</p>
        </>
      )}
    </td>
  );
}

function EstGeneralImp({
  cityInfo = {},
  cityMedias = [],
  campaignPerformance = {},
  isShowLiveMetrics = false,
}) {
  const { totalGenericOts, totalGenericOtsLit } = cityInfo || {};
  const estGenericImp = totalGenericOtsLit ?? totalGenericOts;

  const formattedEstGenericOts =
    estGenericImp === 0 ? 0 : formatText(toLocaleString(estGenericImp));
  const formattedLiveGenericOts = "--";

  // calculating total impression base on stretch specific medias
  const { genericImp } = getLiveAndEstImpForTgAndGeneric(cityInfo, cityMedias);
  const formattedGenericImp = formatText(toStringWithRoundUp(genericImp));

  // live imp
  const { genericOtsServedTotal } = campaignPerformance || {};
  const formattedGenericLiveImp = formatText(
    toStringWithRoundUp(genericOtsServedTotal)
  );

  return (
    <td className="text-right">
      {/* <p className="mb-0">{formattedLiveGenericOts}</p>
      <span className="sub-text">{formattedEstGenericOts}</span> */}

      {/* only est imp */}
      {!isShowLiveMetrics && <p className="mb-0">{formattedGenericImp}</p>}

      {/* both live & est */}
      {isShowLiveMetrics && (
        <>
          <p className="mb-0">{formattedGenericLiveImp}</p>
          <p className="mb-0 sub-text">{formattedGenericImp}</p>
        </>
      )}
    </td>
  );
}

function ContinuePlanning({ cityInfo }) {
  const { campaignId, cityId } = cityInfo;

  const redirectTo = `/campaign/${campaignId}/planning?_city=${cityId}`;

  return (
    <td className="text-right">
      <div>
        <Link to={constructRedirectPath(redirectTo)} className="shadow-none">
          {"Continue Planning"}
        </Link>
      </div>
    </td>
  );
}

function CityDetailsRow({
  cityInfo = {},
  basicCityDetails = {},
  campaignId,
  campaignPlanState,
  cityMedias = [],
  campaignPerformance = {},
}) {
  const EnableContinuePlanning = isAgencySpecific(ContinuePlanning);

  // checking for campaignPlan "review" & "draft" state
  const isDraftCampaign = campaignPlanState === CampaignState.DRAFT;
  const isReviewCampaign = campaignPlanState === CampaignState.REVIEW;
  const isDraftOrReviewCampaign = isDraftCampaign || isReviewCampaign;

  // hiding live metrics for DRAFT & REVIEW state
  const isShowLiveMetrics = !isDraftOrReviewCampaign;

  return (
    <tr>
      <CityName
        basicCityDetails={basicCityDetails}
        campaignId={campaignId}
        campaignPlanState={campaignPlanState}
        cityInfo={cityInfo}
      />
      <StretchCount cityInfo={cityInfo} />
      <SitesCount basicCityDetails={basicCityDetails} />
      <Duration cityInfo={cityInfo} />
      <EstTgImp
        cityInfo={cityInfo}
        cityMedias={cityMedias}
        campaignPerformance={campaignPerformance}
        isShowLiveMetrics={isShowLiveMetrics}
      />
      <EstGeneralImp
        cityInfo={cityInfo}
        cityMedias={cityMedias}
        campaignPerformance={campaignPerformance}
        isShowLiveMetrics={isShowLiveMetrics}
      />

      {/* only show for DRAFT & REVIEW state */}
      {isDraftOrReviewCampaign && EnableContinuePlanning && (
        <EnableContinuePlanning cityInfo={cityInfo} />
      )}
    </tr>
  );
}

export default function CityDetailsTable({
  planByCity = {},
  campaign = {},
  campaignPerformance = {}, // performance for LIVE Campaign
}) {
  // Selector
  const campaignMedias = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // campaign data
  const { id, infoByCity = {}, planStatus } = campaign || {};

  return (
    <div className="mt-5">
      <h4>{"City Highlights"}</h4>
      <div className="table-responsive mt-3">
        <table className="table table-media">
          <TableHeaders
            tableHeaders={cityDetailsHeaders(planStatus)}
            headerClass={"thead"}
          />
          <tbody>
            {Object.values(planByCity).map((cityInfo) => {
              const { cityId } = cityInfo;
              return (
                <CityDetailsRow
                  key={cityId}
                  campaignId={id}
                  cityInfo={cityInfo}
                  basicCityDetails={infoByCity[cityId]}
                  campaignPlanState={planStatus}
                  campaignMedias={campaignMedias}
                  cityMedias={getCitySpecificMediaList(campaignMedias, cityId)}
                  campaignPerformance={campaignPerformance[cityId]}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
