import { Poi } from "../../../constants/action-constants/map-view/poi/PoiActionConstants";
import {
  setDatalistStatus,
  setDatalistSelected,
  formatRawData,
  setDatalistAdded,
} from "../../util/mapViewUtil";

//initial state
const initialState = {
  originalData: {},
  dataList: [],
  getPoiLoading: false,
  getPoiError: "",
  brandLabelToId: {},
  brandToSectorMap: {},
  sectorToBrandMap: {},
  brandIdToLabel: {},
  sectorLabelToId: {},
  cityId: "",
};

function constructDataList(data) {
  // poiTypeTrees = [
  //  {
  //    "id": "POI-07R",
  //    "name": "Jewellery",
  //    "children": [],
  //    "enabled": false
  //  },
  //  {
  //    "id": "POI-13R",
  //    "name": "Clothes & Apparel",
  //    "children": [
  //      {
  //        "id": "POI-13R-03",
  //        "name": "Sportswear",
  //        "parentId": "POI-13R",
  //        "children": [],
  //        "enabled": false
  //      }
  //    ],
  //    "enabled": false
  //  }
  //]
  const poiTypeTrees = data.poiTypeTrees;

  //  brandsByPoiType = {
  //  "POI-11R": [
  //    {
  //      "id": "11R-001",
  //      "name": "Adidas",
  //      "company": "Adidas",
  //      "sectorId": "sec-sport",
  //      "poiTypeIds": [
  //        "POI-11R"
  //      ],
  //      "poiTypeIdsCsv": "POI-11R"
  //    },
  //    {
  //      "id": "11R-002",
  //      "name": "Nike",
  //      "company": "Nike",
  //      "sectorId": "sec-sport",
  //      "poiTypeIds": [
  //        "POI-11R"
  //      ],
  //      "poiTypeIdsCsv": "POI-11R"
  //    }
  //  ]
  //}

  const brandLabelToId = {};
  const brandIdToLabel = {};
  const sectorIdToLabel = {};
  const sectorLabelToId = {};
  const brandToSectorMap = {};
  const sectorToBrandMap = {};
  const brandsByPoiType = data.brandsByPoiType;
  const rawData = poiTypeTrees.reduce((acc, eachEntry) => {
    // If children exists then we mark it with a type.
    if (eachEntry.children.length > 0) {
      const typeData = eachEntry.children.reduce((childAcc, eachChild) => {
        // mapping eachSectorId to its label..
        sectorIdToLabel[eachChild.id] = eachChild.name;

        // mapping eachSectorId to brandIds;
        sectorToBrandMap[eachChild.id] = [];

        // mapping eachSectorLabel to its id..
        sectorLabelToId[eachChild.name] = eachChild.id;

        const brandEntries = brandsByPoiType[eachChild.id] || [];
        const brandData = brandEntries.reduce((brandAcc, eachBrand) => {
          brandAcc[eachBrand.name] = {};

          // mapping eachBrandName to its brandId..
          brandLabelToId[eachBrand.name] = eachBrand.id;

          // mapping eachBrandId to its label
          brandIdToLabel[eachBrand.id] = eachBrand.name;

          // mapping eachBrandId to its SectorId
          brandToSectorMap[eachBrand.id] = eachChild.id;

          // mapping eachSectorId to brandIds;
          sectorToBrandMap[eachChild.id].push(eachBrand.id);
          return brandAcc;
        }, {});
        childAcc[eachChild.name] = brandData;
        return childAcc;
      }, {});

      typeData.type = eachEntry.name;
      acc[eachEntry.name] = typeData;
      return acc;
    }

    //  TODO:Remove this when everything is working fine
    // mapping eachSectorId to brandIds;
    // sectorToBrandMap[eachEntry.id] = [];

    // // mapping eachSectorId to its label..
    // sectorIdToLabel[eachEntry.id] = eachEntry.name;

    // // mapping eachSectorlabel to its id..
    // sectorLabelToId[eachEntry.name] = eachEntry.id;

    // const brandEntries = brandsByPoiType[eachEntry.id] || [];
    // const brandData = brandEntries.reduce((brandAcc, eachBrand) => {
    //   brandAcc[eachBrand.name] = {};
    //   // mapping eachBrandLabel to its Id..
    //   brandLabelToId[eachBrand.name] = eachBrand.id;

    //   // mapping eachBrandId to its SectorId
    //   brandToSectorMap[eachBrand.id] = eachEntry.id;

    //   // mapping eachBrandId to its label
    //   brandIdToLabel[eachBrand.id] = eachBrand.name;

    //   // mapping eachSectorId to brandIds;
    //   sectorToBrandMap[eachEntry.id].push(eachBrand.id);
    //   return brandAcc;
    // }, {});
    // acc[eachEntry.name] = brandData;
    return acc;
  }, {});

  return {
    rawData,
    brandLabelToId,
    brandToSectorMap,
    brandIdToLabel,
    sectorIdToLabel,
    sectorLabelToId,
    sectorToBrandMap,
  };
}

const poiReducer = (state = initialState, action) => {
  switch (action.type) {
    case Poi.GET_POI:
      return {
        ...state,
        getPoiLoading: true,
        cityId: action.payload.cityId,
      };

    case Poi.GET_POI_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {
        rawData,
        brandLabelToId,
        brandToSectorMap,
        brandIdToLabel,
        sectorIdToLabel,
        sectorLabelToId,
        sectorToBrandMap,
      } = constructDataList(action.payload);
      return {
        ...state,
        getPoiLoading: false,
        originalData: action.payload,
        dataList: formatRawData(rawData),
        brandLabelToId,
        brandToSectorMap,
        brandIdToLabel,
        sectorIdToLabel,
        sectorLabelToId,
        sectorToBrandMap,
      };

    case Poi.GET_POI_FAILURE:
      return {
        ...state,
        getPoiLoading: false,
        getPoiError: action.payload,
      };
    case Poi.SET_CHECKLIST_SELECTED: {
      const { path, label } = action.payload;
      const dataList = setDatalistSelected(state.dataList, path, label);
      return {
        ...state,
        dataList,
      };
    }
    case Poi.SET_CHECKBOX_STATUS: {
      const { path, label, status } = action.payload;
      const dataList = setDatalistStatus(state.dataList, path, label, status);
      return {
        ...state,
        dataList,
      };
    }

    case Poi.RESET:
      return {
        ...state,
        dataList: [],
      };

    case Poi.SET_POI_STATUS: {
      const { label, status } = action.payload;
      const dataList = setDatalistAdded(
        state.dataList,
        { label: label },
        status
      );
      return {
        ...state,
        dataList,
      };
    }

    default:
      return state;
  }
};

export default poiReducer;
