export const PerformanceCardWidth = {
  0: "col-2",
  1: "col-3",
  2: "col-4",
  3: "col-3",
};

// for Selected and unSelected Poly lines(roads)
export const SelectedRouteStyle = {
  weight: 10,
  color: "green",
  opacity: 0.8,
};
export const UnSelectedRouteStyle = {
  weight: 10,
  color: "grey",
};

// Influence Circle Styles
export const InfluenceCircleStyles = {
  media: { color: "grey", fillColor: "blue" },
};
