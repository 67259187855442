import { useSelector } from "react-redux";

// Utils & Constants
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import {
  constructDateString,
  getDifferenceInDays,
} from "../../../common-utils/date-utils/DateUtils";
import { constructTotalImpAndCost } from "../../utils/campaignPlanningUtil";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";
import { getCitySpecificMediaList } from "../media-selection/MediaContainerUtil";
import { getTotalCostOfMedias } from "../../../utils/MediaUtils";

// Components
import PerformanceCards from "../../components/performance-card/PerformanceCard";

// Page Components
function getStretchesAndMediaCount(campaignPlan = {}, campaignMedias = []) {
  const card = {
    subValue: "",
  };

  const { roadStretchOtsMap = {}, cityId } = campaignPlan || {};
  const planStretchesCount = Object.keys(roadStretchOtsMap).length;
  const formattedStretchesCount =
    planStretchesCount === 0 ? 0 : toLocaleString(planStretchesCount);

  // city specific medias
  const citySpecificMediaList = getCitySpecificMediaList(
    campaignMedias,
    cityId
  );
  const mediaCount = citySpecificMediaList.length;

  card.subValue = (
    <div className="d-flex justify-content-between mt-n2">
      <div>
        <p>Stretches</p>
        <h3>{formattedStretchesCount}</h3>
      </div>
      <div className="text-truncate ml-2" title="Media Sites">
        <p>Media Sites</p>
        <h3>{mediaCount === 0 ? 0 : toLocaleString(mediaCount)}</h3>
      </div>
    </div>
  );

  return card;
}

// get City Total Generic And Tg Ots
function getCityTotalGenericAndTgOts(cityPlan = {}, campaignMedias = []) {
  // if no media in campaign, no ots
  if (campaignMedias.length === 0) {
    return { totalGenericImp: 0, totalTargetImp: 0 };
  }

  const { cityId, roadStretchOtsMap = {} } = cityPlan || {};

  // city mediaList
  const cityMedias = getCitySpecificMediaList(campaignMedias, cityId);

  const totalImpressionsMap = cityMedias.reduce(
    (acc, media) => {
      const { roadStretchId } = media;
      const stretchOtsInfo = roadStretchOtsMap[roadStretchId] || {};
      const { ots, otsLit, targetOts, targetOtsLit } = stretchOtsInfo || {};
      const finalGenericOts = otsLit ?? ots;
      const finalTargetOts = targetOtsLit ?? targetOts;

      // Total Impressions (generic, target)
      if (finalGenericOts) {
        acc.totalGenericImp = acc.totalGenericImp + finalGenericOts;
      }
      if (finalTargetOts) {
        acc.totalTargetImp = acc.totalTargetImp + finalTargetOts;
      }

      return acc;
    },
    { totalGenericImp: 0, totalTargetImp: 0 }
  );

  return totalImpressionsMap;
}

function getCardInfo(cityPlan = {}, campaignMedias = [], roadStretchMap) {
  const {
    cityId,
    roadStretchOtsMap = {},
    genericCPM,
    targetGroupCPM,
    startTimestamp,
    endTimestamp,
    totalGenericOts,
    totalGenericOtsLit,
    totalTargetOts,
    totalTargetOtsLit,
    totalEstimatedCost,
  } = cityPlan || {};

  const totalDays = getDifferenceInDays(startTimestamp, endTimestamp);
  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  const otsSpecific = totalTargetOtsLit ?? totalTargetOts;
  const otsGeneric = totalGenericOtsLit ?? totalGenericOts;

  const formattedSpecificOts =
    otsSpecific === 0
      ? 0
      : formatText(toStringWithRoundUp(otsSpecific * totalDays));

  const formattedGenericOts =
    otsGeneric === 0
      ? 0
      : formatText(toStringWithRoundUp(otsGeneric * totalDays));

  const formattedEstCost =
    totalEstimatedCost === 0
      ? 0
      : formatText(toStringWithRoundUp(totalEstimatedCost * totalDays));

  // const selectedRoadStretches = Object.keys(roadStretchOtsMap).reduce(
  //   (acc, eachStretchKey) => {
  //     acc[eachStretchKey] = roadStretchMap[eachStretchKey];
  //     return acc;
  //   },
  //   {}
  // );

  // const { genericImpressions, specificImpressions, specificCost, genericCost } =
  //   constructTotalImpAndCost(
  //     selectedRoadStretches,
  //     roadStretchOtsMap,
  //     totalDays,
  //     targetGroupCPM,
  //     genericCPM
  //   );
  // const totalEstCost = specificCost ? specificCost + genericCost : genericCost;

  // stretch and media Count
  const stretchesAndMediaCount = getStretchesAndMediaCount(
    cityPlan,
    campaignMedias
  );

  // getting total impression for generic and tg based on stretches
  const { totalGenericImp, totalTargetImp } = getCityTotalGenericAndTgOts(
    cityPlan,
    campaignMedias
  );
  const formattedTotalGenericImp = formatText(
    toStringWithRoundUp(totalGenericImp)
  );
  const formattedTotalTargetImp = formatText(
    toStringWithRoundUp(totalTargetImp)
  );

  // total cost of medias
  const totalCostOfMedias = getTotalCostOfMedias(campaignMedias);
  const formattedTotalCostOfMedias = formatText(
    toStringWithRoundUp(totalCostOfMedias)
  );

  const cardInfo = [
    stretchesAndMediaCount,
    {
      title: "Dates",
      value: dateString,
      subValue: `Duration: ${totalDays} days`,
    },
    {
      title: "Impressions (Estimated)",
      // value: <p className="mb-0">TG: {formattedSpecificOts}</p>,
      // subValue: <p className="mb-0 h4">General: {formattedGenericOts}</p>,
      value: <p className="mb-0">TG: {formattedTotalTargetImp}</p>,
      subValue: <p className="mb-0 h4">General: {formattedTotalGenericImp}</p>,
    },
    {
      title: "Total Est Cost",
      // value: `Rs. ${formattedEstCost}`,
      value: `Rs. ${formattedTotalCostOfMedias}`,
    },
  ];

  return cardInfo;
}

/**
 * CityView Cards Component
 */
function CityViewCards({
  tgName,
  campaign = {},
  cityPlan = {},
  campaignMedia = [],
}) {
  // RoadStretches
  const roadStretchMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  if (
    Object.keys(cityPlan).length < 1 ||
    !campaign ||
    Object.keys(roadStretchMap) < 1
  ) {
    return null;
  }

  const cardInfo = getCardInfo(cityPlan, campaignMedia, roadStretchMap);

  const { infoByCity = {} } = campaign || {};
  const { cityId } = cityPlan || {};
  const { cityName } = infoByCity[cityId] || "";

  return (
    <>
      <h4>{cityName}</h4>
      <div className="row mx-0">
        {cardInfo.map((info, i) => (
          <PerformanceCards info={info} key={i} className={`col-3`} />
        ))}
      </div>
    </>
  );
}

export default CityViewCards;
