/* eslint-disable no-case-declarations */
import { RouteTypes } from "../../constants/action-constants/RouteTypesActionConstants";

const initialState = {
  // get Route Types
  // -----------------------------------------------------------------------
  routeTypesLoading: false,
  routeTypes: [],
  routeTypesError: "",

  // selected route types
  selectedArterialRoutes: {}, // {type: false, type: false}
};

// toggleRouteTypeSelection
function toggleRouteTypeSelection(obj, key) {
  const clonedObj = { ...obj };
  const prevSelectedKeys = Object.keys(clonedObj);

  if (prevSelectedKeys.includes(key)) {
    delete clonedObj[key];
  } else {
    clonedObj[key] = true;
  }

  return clonedObj;
}

export default (state = initialState, action) => {
  switch (action.type) {
    // get Route Types
    // -----------------------------------------------------------------------
    case RouteTypes.GET_ROUTE_TYPES:
      return {
        ...state,
        routeTypesLoading: true,
      };
    case RouteTypes.GET_ROUTE_TYPES_SUCCESS: {
      const { routeTypes } = action.payload || {};

      // filtering only true values from array of strings
      // removing : { null, false, "" }
      const filteredRouteTypes = routeTypes.filter(Boolean);

      return {
        ...state,
        routeTypesLoading: false,
        routeTypes: filteredRouteTypes,
      };
    }
    case RouteTypes.GET_ROUTE_TYPES_FAILURE:
      return {
        ...state,
        routeTypesLoading: false,
        routeTypesError: action.payload,
      };

    // clear
    case RouteTypes.CLEAR_ROUTE_TYPES: {
      return {
        ...state,
        routeTypes: [],
        routeTypesError: "",
      };
    }

    // toggle selection
    case RouteTypes.TOGGLE_ROUTE_TYPE_SELECTION: {
      const { routeType } = action.payload || {};
      const updatedRoutes = toggleRouteTypeSelection(
        state.selectedArterialRoutes,
        routeType
      );

      return {
        ...state,
        selectedArterialRoutes: updatedRoutes,
      };
    }

    // clear selection
    case RouteTypes.CLEAR_ROUTE_TYPES_SELECTION: {
      return {
        ...state,
        selectedArterialRoutes: {},
      };
    }

    default:
      return state;
  }
};
