import { TargetGroup } from "../../constants/action-constants/TargetGroupActionConstants";

export const getTargetGroups = (
  isArchived,
  keyWord = "",
  pageNumber = 1,
  pageSize = 10,
  excludeTgWithName
) => ({
  type: TargetGroup.GET_TARGET_GROUPS,
  payload: { isArchived, keyWord, pageNumber, pageSize, excludeTgWithName },
});

export const getTargetGroupAttributes = () => ({
  type: TargetGroup.GET_TARGET_GROUP_ATTRIBUTES,
});

export const resetTargetGroupAttributes = () => ({
  type: TargetGroup.RESET_TARGET_GROUP_ATTRIBUTES,
});

export const createTargetGroup = (targetGroupBean, history) => ({
  type: TargetGroup.CREATE_TARGET_GROUP,
  payload: { targetGroupBean, history },
});

export const updateTargetGroup = (
  targetGroupBean,
  history,
  tgId,
  existingEditableTgName
) => ({
  type: TargetGroup.UPDATE_TARGET_GROUP,
  payload: { targetGroupBean, history, tgId, existingEditableTgName },
});

export const setSelected = (path, label) => ({
  type: TargetGroup.SET_TG_SELECTED,
  payload: {
    path,
    label,
  },
});

export const setTgStatus = (labelId, status) => ({
  type: TargetGroup.SET_TG_STATUS,
  payload: {
    labelId,
    status,
  },
});

export const setTgAttributes = (tgInfo, tgAttributes) => ({
  type: TargetGroup.SET_TARGET_GROUP_ATTRIBUTES,
  payload: {
    tgInfo,
    tgAttributes,
  },
});

export const archiveTg = (tgId) => ({
  type: TargetGroup.ARCHIVE_TARGET_GROUP,
  payload: {
    tgId,
  },
});

export const restoreTg = (tgId) => ({
  type: TargetGroup.RESTORE_TARGET_GROUP,
  payload: {
    tgId,
  },
});

export const getResultingPoiLayers = (dataList) => ({
  type: TargetGroup.REVIEW_TARGET_GROUP,
  payload: {
    dataList,
  },
});

export const getTgInfo = (tgId) => ({
  type: TargetGroup.GET_TG_BASIC_INFO,
  payload: { tgId },
});

export const clearTgInfo = () => ({
  type: TargetGroup.CLEAR_TG_BASIC_INFO,
});

export const setSelectedTgId = (tgId) => ({
  type: TargetGroup.SET_SELECTED_TG_ID,
  payload: { tgId },
});

export const removeSelectedTgId = () => ({
  type: TargetGroup.REMOVE_SELECTED_TG_ID,
});

export const resetTgGroup = () => ({
  type: TargetGroup.RESET_TARGET_GROUPS,
});
