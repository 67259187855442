import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  closeRoadStretchMetaDataUpdateForm,
  updateRoadStretchMetaData,
} from "../../../actions/org/OrgRoadStretchActions";

// Constants
import { FormDataTargets } from "../../../constants/GeneralConstants";

export default function RoadStretchMetaDataUpdateForm() {
  const dispatch = useDispatch();

  const stretchInfo = useSelector(
    (state) => state.orgRoadStretch.processDataObject
  );

  const { metaData = {}, id = "" } = stretchInfo || {};

  const [formData, setFormData] = useState({
    zoneName: "",
    locationName: "",
    directions: "",
    routeType: "",
  });

  const inputFields = [
    {
      displayTag: "Zone Name",
      placeholder: "Enter zone name",
      name: "zoneName",
      value: formData.zoneName,
    },

    {
      displayTag: "Location Name",
      placeholder: "Enter location name",
      name: "locationName",
      value: formData.locationName,
    },

    {
      displayTag: "directions",
      placeholder: "Enter directions",
      name: "directions",
      value: formData.directions,
    },
    {
      displayTag: "Route Type",
      placeholder: "Enter route type",
      name: "routeType",
      value: formData.routeType,
    },
  ];

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  function onCancel() {
    setFormData({
      zoneName: metaData?.zoneName || "",
      locationName: metaData?.locationName || "",
      directions: metaData?.directions || "",
      routeType: metaData?.routeType || "",
    });
    dispatch(closeRoadStretchMetaDataUpdateForm());
  }

  function onSubmit() {
    dispatch(updateRoadStretchMetaData(formData, id));
    dispatch(closeRoadStretchMetaDataUpdateForm());
  }

  useEffect(() => {
    setFormData({
      zoneName: metaData?.zoneName || "",
      locationName: metaData?.locationName || "",
      directions: metaData?.directions || "",
      routeType: metaData?.routeType || "",
    });
  }, [JSON.stringify(stretchInfo)]);

  return (
    <div
      className="modal fade"
      id={FormDataTargets.roadStretchMetaDataUpdateForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* Form Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">Update Road Stretch Meta data</h4>
          </div>
          {/* modal body */}
          <div className="modal-body px-5 pt-4 mt-2">
            {inputFields.map((info, i) => (
              <div key={i} className="form-group row">
                <label className="col-3 col-form-label">
                  {info.displayTag}
                </label>
                <div className="col-9">
                  <input
                    className={`form-control shadow-none rounded-lg`}
                    placeholder={info.placeholder}
                    name={info.name}
                    value={info.value}
                    onChange={handleChange}
                  />
                </div>
              </div>
            ))}
          </div>

          {/* Form Footer */}
          <div className="modal-footer border-0 px-5">
            <button
              type="button"
              className="btn btn-outline-primary rounded-lg shadow-none"
              data-dismiss="modal"
              onClick={onCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-primary rounded-lg ml-2 shadow-none"
              data-dismiss="modal"
              onClick={onSubmit}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
