/**
 * Filtered Media & Stretches after Show/Hide From Map
 * @param {*} selectedFiltersMap => show data filters map
 * @param {*} campaignMedias => campaign media list
 * @param {*} campaignPlan => campaign plan for city
 * @param {*} roadStretchMap => all (part of campaign or not) road stretches (including orgMedia)
 */
export function getMapViewData(
  selectedFiltersMap = {},
  campaignMedias = [],
  campaignPlan = {},
  roadStretchMap = {}
) {
  // Legend selected data
  const {
    selectedSubZoneIds = [],
    selectedRouteTypes = [],
    selectedMediaTypes = [],
  } = selectedFiltersMap || {};

  // checking filter checked or not
  const isAnySubZonesChecked = selectedSubZoneIds.length > 0;
  const isAnyRouteTypesChecked = selectedRouteTypes.length > 0;
  const isAnyMediaTypesChecked = selectedMediaTypes.length > 0;

  // campaign plan data
  const {
    roadStretchOtsMap = {},
    zones = [],
    mediaTypes = [],
    routeTypes = [],
    cityId,
  } = campaignPlan || {};

  const planStretchIds = Object.keys(roadStretchOtsMap);
  const isPlanZonesApplied = zones.length > 0;
  const isPlanRouteTypesApplied = routeTypes.length > 0;
  const isPlanMediaTypesApplied = mediaTypes.length > 0;

  // city specific medias
  const citySpecificMedias = campaignMedias.filter(
    (media) => media.cityId === cityId
  );
  // campaign city Media ids
  const campaignCityMediaIds = citySpecificMedias.map((media) => media.mediaId);

  // final stretches
  const zoneFilteredStretchesMap = {};
  const zoneAndRouteTypeFilteredStretchesMap = {};
  const zoneAndRouteTypeAndMediaTypeFilteredMediasMap = {};

  // if there is no stretches in campaignPlan
  if (planStretchIds.length === 0) {
    return {
      filteredStretchesMap: zoneAndRouteTypeFilteredStretchesMap,
      filteredMediasMap: zoneAndRouteTypeAndMediaTypeFilteredMediasMap,
    };
  }

  // filter campaign plan selected stretches
  const planStretchesMap = {};
  Object.keys(roadStretchMap).forEach((key) => {
    const stretchInfo = roadStretchMap[key];

    // if stretch is part of campaign plan then add to "planStretchesMap"
    if (planStretchIds.includes(key)) {
      planStretchesMap[key] = stretchInfo;
    }
  });

  // zones filter
  Object.keys(planStretchesMap).forEach((stretchId) => {
    const stretchInfo = planStretchesMap[stretchId];
    const { zoneId } = stretchInfo || {};

    // if no planZones applied
    if (!isPlanZonesApplied) {
      zoneFilteredStretchesMap[stretchId] = stretchInfo;
      return;
    }

    // if planZones applied and checked
    if (isPlanZonesApplied && isAnySubZonesChecked) {
      const isSelectedZonesIncludesSubZone =
        selectedSubZoneIds.includes(zoneId);

      if (!isSelectedZonesIncludesSubZone) {
        delete zoneFilteredStretchesMap[stretchId];
      }

      if (isSelectedZonesIncludesSubZone) {
        zoneFilteredStretchesMap[stretchId] = stretchInfo;
      }
    }
  });

  // routeType filter
  Object.keys(zoneFilteredStretchesMap).forEach((stretchId) => {
    const stretchInfo = zoneFilteredStretchesMap[stretchId];
    const { metaData = {} } = stretchInfo || {};
    const { routeType } = metaData || {};

    // if no planRouteType applied
    if (!isPlanRouteTypesApplied) {
      zoneAndRouteTypeFilteredStretchesMap[stretchId] = stretchInfo;
      return;
    }

    // if planRouteType applied and checked
    if (isPlanRouteTypesApplied && isAnyRouteTypesChecked) {
      const isSelectedRouteTypesIncludesRoute =
        selectedRouteTypes.includes(routeType);

      if (!isSelectedRouteTypesIncludesRoute) {
        delete zoneAndRouteTypeFilteredStretchesMap[stretchId];
      }

      if (isSelectedRouteTypesIncludesRoute) {
        zoneAndRouteTypeFilteredStretchesMap[stretchId] = stretchInfo;
      }
    }
  });

  // mediaType filter
  Object.keys(zoneAndRouteTypeFilteredStretchesMap).forEach((stretchId) => {
    const stretchInfo = zoneFilteredStretchesMap[stretchId];
    const { orgMedias = [] } = stretchInfo || {};

    orgMedias.forEach((media) => {
      const { mediaTag, mediaId } = media;

      // checking is campaign city media & data
      const isCampaignCityMedia = campaignCityMediaIds.includes(mediaId);
      const campaignCityMediaInfo = citySpecificMedias.find(
        (media) => media.mediaId === mediaId
      );
      const {
        duration,
        startTimestamp,
        endTimestamp,
        otsSummary = {}, // it includes tgOts as well for campaignCity duration
      } = campaignCityMediaInfo || {};

      // adding more fields to orgMedia
      const constructedMedia = {
        ...media,
        duration,
        startTimestamp,
        endTimestamp,
        otsSummary,
        stretchInfo,
      };

      // only handle campaign city medias
      if (!isCampaignCityMedia) {
        return;
      }

      // if no planMediaType applied, then add media
      if (!isPlanMediaTypesApplied) {
        zoneAndRouteTypeAndMediaTypeFilteredMediasMap[mediaId] =
          constructedMedia;
        return;
      }

      // if no mediaTypes checked, remove all medias
      if (!isAnyMediaTypesChecked) {
        Object.keys(zoneAndRouteTypeAndMediaTypeFilteredMediasMap).forEach(
          (key) => delete zoneAndRouteTypeAndMediaTypeFilteredMediasMap[key]
        );
        return;
      }

      // checking selectedMediaTypes includes mediaType
      const isSelectedMediaTypesIncludesMediaTag =
        selectedMediaTypes.includes(mediaTag);

      // out of campaign medias, only adding media of checked mediaTag
      if (!isSelectedMediaTypesIncludesMediaTag) {
        delete zoneAndRouteTypeAndMediaTypeFilteredMediasMap[mediaId];
        return;
      }

      if (isSelectedMediaTypesIncludesMediaTag) {
        zoneAndRouteTypeAndMediaTypeFilteredMediasMap[mediaId] =
          constructedMedia;
      }
    });
  });

  return {
    filteredStretchesMap: zoneAndRouteTypeFilteredStretchesMap,
    filteredMediasMap: zoneAndRouteTypeAndMediaTypeFilteredMediasMap,
  };
}
