import { useDispatch } from "react-redux";

// Actions
import { closePlatformUpdatesForm } from "../../actions/PlatformUpdatesFromActions";

// Constants and Utils
import { getItem, removeItem } from "../../utils/localstorage";
import {
  FormDataTargets,
  LocalStorageKeys,
  OrganizationIdsMapping,
  UrlKeys,
} from "../../constants/GeneralConstants";
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";
import { checkIsOrgSpecific } from "../../utils/OrgUtils";

// Modal Components
function PlatformUpdatesHeader({ user = {} }) {
  const dispatch = useDispatch();

  // data
  const { org = {} } = user || {};
  const { logoURL, name } = org || {};

  // Getting key from "localStorage" (JSON.parse(string "true") => boolean true)
  const isUserFirstVisit = JSON.parse(
    getItem(LocalStorageKeys.PLATFORM_UPDATES_MODAL)
  );

  function closeUpdatesModal() {
    // removing the Key from "localStorage"
    if (isUserFirstVisit) {
      removeItem(LocalStorageKeys.PLATFORM_UPDATES_MODAL);
    }
    // closing Popup
    dispatch(closePlatformUpdatesForm());
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      {/* brand-logo */}
      <img src={logoURL} alt={name} className="brand-logo" />

      {/* heading */}
      <h1 className="text-center font-weight-bold mb-0">
        What&apos;s New : v3.4
      </h1>

      {/* close form button */}
      <button
        className="btn px-2 shadow-none"
        data-dismiss="modal"
        onClick={closeUpdatesModal}
      >
        <h4 className="mb-0">X</h4>
      </button>
    </div>
  );
}

// Left Content Section
function LeftContentSection({ user = {} }) {
  const { orgId } = user;

  // Checking is Rapport Specific
  const { rapportFactor } = OrganizationIdsMapping;
  const isRapportSpecific = checkIsOrgSpecific(orgId, rapportFactor);

  return (
    <div className="col">
      <h3 className="text-primary">Updates & Improvements</h3>

      {/* items */}
      <ul>
        <li>Ratio Bar visualization for Impression & TG Impression</li>
        <li>Ratio Bar visualization for Reach & TG Reach</li>
        <li>Campaign creation with predefined TG</li>
        <li>Frequency wise split numbers for Media Site Reach & TG Reach</li>
        <li>Dynamic Graphs and Charts</li>
        <li>Updated Campaign Excel Report</li>
        <li>Multiple PDF download for a city</li>

        {/* only for Rapport */}
        {isRapportSpecific && (
          <li>Upgradation in Map Tiles (better visualization)</li>
        )}
      </ul>
    </div>
  );
}

// Right Content Section
function RightContentSection() {
  // checking is mantaray specific
  const isMantaraySpecific = useUrlKeyCheck(UrlKeys.mantaraySpecific);

  return (
    <div className="col">
      {/* Launched v3.4 features */}
      <h3 className="text-success">Launched Features in v3.4</h3>
      <ul>
        {!isMantaraySpecific && (
          <>
            <li>Route Analysis Tool</li>
            <li>Campaign Graph Report with Dynamic Graphs</li>
          </>
        )}
        <li>Help video content added for multiple features</li>
      </ul>

      {/* Upcoming in v3.5 */}
      <h3 className="text-success">Upcoming Features in v3.5</h3>
      <ul>
        <li>Junction Analysis Tool</li>
        <li>Filter Media Groups on Map View</li>
        <li>
          Includes bus routes, custom routes and analysis for ads on wheels
        </li>
      </ul>
    </div>
  );
}

/**
 * MODAL :: Platform Updates
 */
export default function PlatformUpdatesForm() {
  // Getting key from "localStorage"
  const user = JSON.parse(getItem(LocalStorageKeys.USER));

  return (
    <div
      className="modal fade"
      id={FormDataTargets.platformUpdatesForm}
      data-backdrop="static"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content p-4 bg-alt whats-new">
          {/* modal header */}
          <PlatformUpdatesHeader user={user} />

          {/* modal body */}
          <div className="row pt-3">
            {/* Content :: Left side */}
            <LeftContentSection user={user} />

            {/* Content :: Right side */}
            <RightContentSection />
          </div>
        </div>
      </div>
    </div>
  );
}
