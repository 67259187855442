// Utils and Constants
import { RoadStretch } from "../../../constants/action-constants/RoadStretchActionConstants";
import { updateRoadTrace, updateImpDetails } from "../../utils/RoadUtil";

const initialState = {
  loading: false,
  error: "",
  roadStretchDetailsMap: {},
  requestSuccess: false,

  // Base Impressions for progress bars (We use the highest impressions as the base)
  baseOts: 0,

  // Below details stores the "roadStretch-selections"
  selectedStretches: {},
};

function updateImpAndTraceDetails(roadStretches = []) {
  const updatedRoadStretchesMap = {};
  let baseOts = 0;

  roadStretches.forEach((stretch) => {
    const { roadStretch, ...otherFields } = stretch || {};
    const updatedStretchWithTraceInfo = updateRoadTrace(roadStretch);
    const updatedStretch = { ...otherFields, ...updatedStretchWithTraceInfo };
    const updatedImpStretch = updateImpDetails(updatedStretch);
    updatedRoadStretchesMap[roadStretch.id] = updatedImpStretch;
    const { otsAvg, otsLitAvg } = updatedStretch;
    const impressions = otsLitAvg ?? otsAvg;
    baseOts = impressions > baseOts ? impressions : baseOts;
  });

  return { updatedRoadStretchesMap, baseOts };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RoadStretch.GET_CITY_ROAD_STRETCHES:
      return {
        ...state,
        loading: true,
      };

    case RoadStretch.GET_CITY_ROAD_STRETCHES_SUCCESS: {
      const { updatedRoadStretchesMap, baseOts } = updateImpAndTraceDetails(
        action.payload
      );
      return {
        ...state,
        roadStretchDetailsMap: updatedRoadStretchesMap,
        baseOts: baseOts,
        requestSuccess: true,
        loading: false,
      };
    }

    case RoadStretch.GET_CITY_ROAD_STRETCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RoadStretch.SELECT_ROAD_STRETCH: {
      const roadStretch = action.payload.roadStretch;
      return {
        ...state,
        selectedStretches: {
          ...state.selectedStretches,
          [roadStretch.id]: roadStretch,
        },
      };
    }

    case RoadStretch.SELECT_ROAD_STRETCHS: {
      const { roadStretchsMap } = action.payload;
      const newSelectedStretches = Object.keys(roadStretchsMap).reduce(
        (acc, eachStretchId) => {
          acc[eachStretchId] = roadStretchsMap[eachStretchId];
          return acc;
        },
        {}
      );
      return {
        ...state,
        selectedStretches: newSelectedStretches,
      };
    }

    case RoadStretch.UN_SELECT_ROAD_STRETCH: {
      const { roadStretch } = action.payload;
      const selectedStretches = { ...state.selectedStretches };
      delete selectedStretches[roadStretch.id];
      return {
        ...state,
        selectedStretches,
      };
    }

    case RoadStretch.RESET_ROAD_STRETCHES:
      return initialState;

    default:
      return state;
  }
};
