import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Actions
import { getCampaignPlan } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { mediaSetupMonitoring } from "../../actions/campaign-confirmation/CampaignConfirmationActions";
import { getRoadStretchesByCityId } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";

// Utils
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import MediaDetailsTable from "../components/MediaDetailsTable";
import MapView from "../components/map-view/MapView";
import CityViewCards from "../components/CityViewCards";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import MediaSetupMonitoringForm from "../../../components/media-setup-monitoring-form/MediaSetupMonitoringForm";
import { EstimatedPriceBreakDown } from "../components/EstimatedPriceBreakDown";
import { CampaignDetails } from "../components/HeaderNav";

/**
 * Page
 */
export default function CampaignConfirmedPage() {
  const { campaignId, cityId } = useParams();
  const dispatch = useDispatch();

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { targetGroupId } = campaignPlan || {};

  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Campaign Media
  const campaignMedia = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // for Setup-Monitoring Popup
  const openSetupMonitoringForm = useSelector(
    (state) => state.monitoringSettings.openModal
  );

  // monitoring mediaId
  const monitoringMediaId = useSelector(
    (state) => state.monitoringSettings.monitoringMediaId
  );

  // Monitoring Media Dates
  const monitoringMediaDateObj = useSelector(
    (state) => state.monitoringSettings.monitoringMediaDateObj
  );

  // City road stretches
  const roadStretchMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  useEffect(() => {
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getRoadStretchesByCityId(cityId, ""));
  }, [dispatch, campaignId, cityId]);

  // get tg info
  useGetTgInfo(targetGroupId);

  // Sidebar Settings
  const citySelected = { cityId };
  const redirectLinkObj = {
    campaignOverviewLink: `/campaign/${campaignId}/confirm`,
    citySummaryLink: `/campaign/${campaignId}/city/:cityId/confirm`,
  };

  // Setup Monitoring Form
  const EnableMediaSetupMonitoringForm = isAgencySpecific(
    MediaSetupMonitoringForm
  );

  // Create Monitoring Settings Function
  function onSubmitMonitoringSettings(inputFieldBean) {
    dispatch(
      mediaSetupMonitoring(inputFieldBean, campaignId, cityId, [
        monitoringMediaId,
      ])
    );
  }

  // Page Title
  const pageTitle = <CampaignDetails campaign={campaign} />;

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      />

      <div className="content-wrapper">
        <PageHeader title={pageTitle} shadow={true} />

        <div className="page-content">
          {/* city view Cards */}
          <CityViewCards
            tgName={tgName}
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedia={campaignMedia}
          />

          {/* TODO:Uncomment if required */}
          {/* Estimated Price Breakdown Table */}
          {/* <EstimatedPriceBreakDown
            tgName={tgName}
            campaign={campaign}
            campaignPlan={campaignPlan}
            submissionImpact={false}
          /> */}

          {/* Map view */}
          <MapView
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />

          {/* Media Sites Table */}
          <MediaDetailsTable
            campaignId={campaignId}
            campaign={campaign}
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />
        </div>
      </div>

      {/* modals */}
      {openSetupMonitoringForm && EnableMediaSetupMonitoringForm && (
        <EnableMediaSetupMonitoringForm
          onFormSubmit={onSubmitMonitoringSettings}
          defaultDateRange={monitoringMediaDateObj}
        />
      )}
    </>
  );
}
