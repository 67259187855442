import { DivIcon } from "leaflet";
import { Marker } from "react-leaflet";

// Css
import "./TrafficFlowArrow.scss";

// Component Functions

/**
 * Measures angle between two coordinates in Radians
 *
 * @param {[long, lat]} point1 Starting point
 * @param {[long, lat]} point2 Ending point
 */
function angleInRadians(point1 = [], point2 = []) {
  const [y1, x1] = point1;
  const [y2, x2] = point2;

  const yDiff = y2 - y1;
  const xDiff = x2 - x1;
  const slopeRadians = Math.atan(yDiff / xDiff);

  // -ve X-Diff means 2nd and 3rd quadrants => needs correction with 180deg
  // Ref:
  // https://chortle.ccsu.edu/VectorLessons/vch05/vch05_4.html
  // https://chortle.ccsu.edu/VectorLessons/vch05/vch05_5.html
  return xDiff < 0 ? Math.PI + slopeRadians : slopeRadians;
}

/**
 * Leaflet DivIcon with arrow
 */
function getArrowDivIcon(arrowAngleInRad) {
  // Calculate angle of rotation
  const angleDegrees = (arrowAngleInRad / Math.PI) * 180;
  const rotateDegrees = 90 - angleDegrees;

  return new DivIcon({
    className: "map-icon-arrow",
    html: `<i class="fa fa-long-arrow-alt-up" style="transform:rotate(${rotateDegrees}deg)"></i>`,
  });
}

/**
 * Main Component
 */
export default function TrafficFlowArrow({ trace = [] }) {
  // Trace Length
  const trLength = trace.length;

  // estimate no of arrows needed (default start, end)
  // Start
  const startAngle = angleInRadians(trace[0], trace[1]);
  const startArrowIcon = getArrowDivIcon(startAngle);

  // End
  const endAngle = angleInRadians(trace[trLength - 2], trace[trLength - 1]);
  const endArrowIcon = getArrowDivIcon(endAngle);

  return (
    <>
      <Marker position={trace[0]} icon={startArrowIcon}></Marker>
      <Marker position={trace[trLength - 1]} icon={endArrowIcon}></Marker>
    </>
  );
}
