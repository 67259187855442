import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { toggleRouteTypeSelection } from "../../../actions/org/OrgRouteTypesActions";

// Components
import {
  ArterialRouteAndMediaTypeCheckboxList,
  HeaderWithActionButton,
} from "./PlanningUtils";

// Arterial Route Tab
export default function ArterialRouteTab({
  handleTabHeaderBtnClick = () => {},
  tabHeaderBtnLoading = false,
}) {
  const dispatch = useDispatch();

  // route types
  const routeTypes = useSelector((state) => state.orgRouteTypes.routeTypes);

  // Selected Arterial Routes Map
  const selectedArterialRoutesMap = useSelector(
    (state) => state.orgRouteTypes.selectedArterialRoutes
  );

  function handleCheckboxChange(routeType) {
    dispatch(toggleRouteTypeSelection(routeType));
  }

  return (
    <>
      <HeaderWithActionButton
        title="Route Type"
        buttonText="Confirm"
        onButtonClick={handleTabHeaderBtnClick}
        buttonLoading={tabHeaderBtnLoading}
      />

      <ArterialRouteAndMediaTypeCheckboxList
        data={routeTypes}
        selectedMap={selectedArterialRoutesMap}
        onCheckboxChange={handleCheckboxChange}
      />
    </>
  );
}
