/* eslint-disable require-yield */
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";
import { Poi } from "../../../constants/action-constants/map-view/poi/PoiActionConstants";
import { ActionTypes } from "../../../constants/ActionConstants";
import { getErrorMessage } from "../../../utils/util";

export function* fetchSelectedPois(action) {
  const { cityId, poiBrandIds, bbox } = action.payload;

  try {
    // TODO:uncomment when getting brandinfo using brandIds
    // const actions = brandIds.reduce((acc, brandId) => {
    //   acc.push(
    //     put({
    //       type: Poi.GET_BRAND_INFO,
    //       payload: { cityId, brandId },
    //     })
    //   );
    //   return acc;
    // }, []);
    const actions = Object.keys(poiBrandIds).reduce((acc, poiTypeId) => {
      poiBrandIds[poiTypeId].forEach((brandId) => {
        acc.push(
          put({
            type: Poi.GET_BRAND_INFO,
            payload: {
              cityId,
              poiTypeId,
              brandId,
              cityBBox: bbox,
            },
          })
        );
      });
      return acc;
    }, []);

    // As this is asynchronous action ==> we are not able to show
    // Loader beside PoiSelecton button.
    // TODO: Find a alterative to show the loader..
    yield all(actions);

    yield put({
      type: ActionTypes.PoiSelectionForm.GET_SELECTED_POI_INFO_SUCCESS,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.PoiSelectionForm.GET_SELECTED_POI_INFO_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.PoiSelectionForm.GET_SELECTED_POI_INFO,
      fetchSelectedPois
    ),
  ]);
}
