import { toast } from "react-toastify";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// API
import {
  archiveRoadStretch,
  createRoadStretch,
  getRoadStretchesByIds,
  getRoadStretchesOfOrg,
  restoreRoadStretch,
  getRoadStretchInfoByIdFn,
  roadStretchPriceRefreshFn,
  updateRoadStretchMetaDataFn,
  updateRoadStretchFn,
} from "../../apis/OrgRoadStretchAPI";
import { closestRoadSegmentsData } from "../../apis/RoadSegmentAPI";

// Constants and Utils
import {
  CreateNewRoadStretches,
  RoadStretch,
} from "../../constants/action-constants/RoadStretchActionConstants";
import { getErrorMessage } from "../../utils/util";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

export function* getRoadStretches(action) {
  const { stretchIds } = action.payload;
  try {
    // api response for "road-stretch-details"
    const roadStretchDetailsMap = yield getRoadStretchesByIds(stretchIds);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCHES_BY_IDS_SUCCESS,
      payload: { roadStretchDetailsMap },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCHES_BY_IDS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getRoadStretchesOfOrganization(action) {
  const { isArchived, cityId, keyWord, pageNumber, pageSize } = action.payload;
  try {
    // api response for "road-stretch-details"
    const result = yield getRoadStretchesOfOrg(
      isArchived,
      cityId,
      keyWord,
      pageNumber,
      pageSize
    );

    yield put({
      type: RoadStretch.GET_ROAD_STRETCHES_OF_ORG_SUCCESS,
      payload: { roadStretches: result.items, pagination: result.pagination },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCHES_OF_ORG_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* archiveStretch(action) {
  const { stretchId } = action.payload;
  try {
    yield archiveRoadStretch(stretchId);
    yield put({
      type: RoadStretch.UPDATE_ROAD_STRETCH_STATUS,
      payload: { id: stretchId, isArchived: true },
    });
    yield put({
      type: RoadStretch.ARCHIVE_ROAD_STRETCH_SUCCESS,
      payload: { stretchId },
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: RoadStretch.ARCHIVE_ROAD_STRETCH_FAILURE,
      payload: { stretchId, errMsg },
    });
    toast.error(errMsg);
  }
}

function* restoreStretch(action) {
  const { stretchId } = action.payload;
  try {
    yield restoreRoadStretch(stretchId);
    yield put({
      type: RoadStretch.UPDATE_ROAD_STRETCH_STATUS,
      payload: { id: stretchId, isArchived: false },
    });
    yield put({
      type: RoadStretch.RESTORE_ROAD_STRETCH_SUCCESS,
      payload: { stretchId },
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: RoadStretch.RESTORE_ROAD_STRETCH_FAILURE,
      payload: { stretchId, errMsg },
    });
    toast.error(errMsg);
  }
}

// for creating a new Road Stretch
export function* createStretch(action) {
  const { stretchName, segmentIds, history } = action.payload;
  try {
    const stretchBean = { name: stretchName, roadSegmentIds: segmentIds };

    yield createRoadStretch(stretchBean);
    yield put({
      type: CreateNewRoadStretches.CREATE_ROAD_STRETCH_SUCCESS,
    });

    // Here we are resetting the state of createRoadStretch Reducer.
    yield put({
      type: CreateNewRoadStretches.RESET,
    });

    // Redirecting to the Dashboard
    history.push(constructRedirectPath("/org/road-stretch/list"));

    // Success message on the Dashboard Page
    toast.success(`Successfully created Road-Stretch : ${stretchName}`);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: CreateNewRoadStretches.CREATE_ROAD_STRETCH_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getClosestRoadSegments(action) {
  const { locationStr, inclNullableTraffic } = action.payload;

  try {
    const roadSegments = yield closestRoadSegmentsData(
      locationStr,
      inclNullableTraffic
    );

    yield put({
      type: CreateNewRoadStretches.GET_CLOSEST_ROAD_SG_SUCCESS,
      payload: { roadSegments: roadSegments },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: CreateNewRoadStretches.GET_CLOSEST_ROAD_SG_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

// Single Road-Stretch Info By ID Saga
export function* getRoadStretchInfoById(action) {
  const { stretchId } = action.payload;

  try {
    const stretchInfo = yield getRoadStretchInfoByIdFn(stretchId);

    yield put({
      type: RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID_SUCCESS,
      payload: { stretchInfo },
    });
  } catch (error) {
    const errMsg = getErrorMessage(error);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID_FAILURE,
      payload: error,
    });
    toast.error(errMsg);
  }
}

export function* RoadStretchPriceRefresh(action) {
  const { stretchId } = action.payload;

  try {
    yield roadStretchPriceRefreshFn(stretchId);

    yield put({
      type: RoadStretch.ROAD_STRETCH_PRICE_REFRESH_SUCCESS,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadStretch.ROAD_STRETCH_PRICE_REFRESH_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* updateRoadStretchMetaData(action) {
  const { roadStretchBean, stretchId } = action.payload;

  try {
    yield updateRoadStretchMetaDataFn(roadStretchBean, stretchId);

    // api response for "road-stretch-details"
    const result = yield getRoadStretchesOfOrg(false);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCHES_OF_ORG_SUCCESS,
      payload: { roadStretches: result.items, pagination: result.pagination },
    });

    yield put({
      type: RoadStretch.UPDATE_ROAD_STRETCH_META_DATA_SUCCESS,
      payload: { stretchId },
    });

    // toast message
    toast.success("Successfully updated Meta Data");
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: RoadStretch.UPDATE_ROAD_STRETCH_META_DATA_FAILURE,
      payload: { errorMessage, stretchId },
    });
    toast.error(errorMessage);
  }
}

function* updateRoadStretch(action) {
  const { stretchId, stretchName, segmentIds } = action.payload;
  try {
    const stretchBean = { name: stretchName, roadSegmentIds: segmentIds };

    // update
    yield updateRoadStretchFn(stretchId, stretchBean);

    // updated stretch info
    const stretchInfo = yield getRoadStretchInfoByIdFn(stretchId);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID_SUCCESS,
      payload: { stretchInfo },
    });

    // update success
    yield put({
      type: RoadStretch.UPDATE_ROAD_STRETCH_SUCCESS,
    });

    // toast message
    toast.success("Successfully updated Road Stretch");
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: RoadStretch.UPDATE_ROAD_STRETCH_FAILURE,
      payload: { errorMessage },
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(RoadStretch.GET_ROAD_STRETCHES_BY_IDS, getRoadStretches),

    takeLatest(
      RoadStretch.GET_ROAD_STRETCHES_OF_ORG,
      getRoadStretchesOfOrganization
    ),

    takeLatest(RoadStretch.ARCHIVE_ROAD_STRETCH, archiveStretch),
    takeLatest(RoadStretch.RESTORE_ROAD_STRETCH, restoreStretch),
    takeEvery(
      CreateNewRoadStretches.GET_CLOSEST_ROAD_SG,
      getClosestRoadSegments
    ),
    takeLatest(CreateNewRoadStretches.CREATE_ROAD_STRETCH, createStretch),
    takeEvery(RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID, getRoadStretchInfoById),
    takeEvery(RoadStretch.ROAD_STRETCH_PRICE_REFRESH, RoadStretchPriceRefresh),
    takeLatest(
      RoadStretch.UPDATE_ROAD_STRETCH_META_DATA,
      updateRoadStretchMetaData
    ),
    takeLatest(RoadStretch.UPDATE_ROAD_STRETCH, updateRoadStretch),
  ]);
}
