export const MediaTypes = ["Head on", "LHS", "RHS"];

export const TouchPointsInputItems = [
  { label: "Malls", id: "malls" },
  { label: "Office", id: "office" },
  { label: "Cyber City", id: "cyberCity" },
  { label: "One Horizon", id: "oneHorizon" },
  { label: "Promenade", id: "promenade" },
];

// Key for Tg Keys : "BEHAVIORAL"
export const TargetGroupKeys = {
  behavioral: "BEHAVIORAL",
};

// Default Tg Name
export const DefaultTgName = "default";
