import { RouteTypes } from "../../constants/action-constants/RouteTypesActionConstants";

export const getRouteTypes = () => ({
  type: RouteTypes.GET_ROUTE_TYPES,
});

export const clearRouteTypes = () => ({
  type: RouteTypes.CLEAR_ROUTE_TYPES,
});

export const toggleRouteTypeSelection = (routeType) => ({
  type: RouteTypes.TOGGLE_ROUTE_TYPE_SELECTION,
  payload: { routeType },
});

export const clearRouteTypesSelection = () => ({
  type: RouteTypes.CLEAR_ROUTE_TYPES_SELECTION,
});
