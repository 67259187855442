import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";

export function ProgressBarLarge({
  impression,
  plannedEstImpressions,
  className,
}) {
  const percentage = (impression / plannedEstImpressions) * 100 || 0;
  const displayImpressions = `${toLocaleString(
    impression
  )} (${percentage.toFixed()}%)`;

  return (
    <div className="progress mb-1 d-flex justify-content-between progress-bar-lg">
      <div
        className={`progress-bar ${className}`}
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: `${percentage}%` }}
        title={displayImpressions}
      >
        {displayImpressions}
      </div>
      <span className="p-2 mt-1">{toLocaleString(plannedEstImpressions)}</span>
    </div>
  );
}
