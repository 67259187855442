import React from "react";
import { useState } from "react";

// CSS
import "./CardCollapsible.scss";

// Component
// ----------------------------------------------------------------------------

function CardCollapsible({
  children,
  className = "",
  cardBodyClassName = "",
  id,
  header,
  subHeader,
  collapsed = true,
  disabled,
}) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const cardIconClassName = isCollapsed ? "fa-plus" : "fa-minus";

  return (
    <div className={`card card-collapsible ${className}`} id={id}>
      <div
        className="card-header"
        data-toggle="collapse"
        data-target={`#body-${id}`}
        aria-expanded={!isCollapsed}
        onClick={() => setIsCollapsed(!isCollapsed)}
        disabled={disabled}
      >
        {/** Chevron */}
        <i className={`fa ${cardIconClassName} cursor-pointer`} />

        {/* Header & SubHeader */}
        <label>{header}</label>
        {subHeader && <label className="sub-text">{subHeader}</label>}
      </div>

      {/* Card Content */}
      <div
        id={`body-${id}`}
        className={`card-body collapse ${cardBodyClassName}`}
        aria-expanded={!isCollapsed}
      >
        {children}
      </div>
    </div>
  );
}

export default CardCollapsible;
