import { combineReducers } from "redux";
import cityReducer from "./cityReducer";

// MapView Reducers
import MediaSitesReducer from "./map-view/media-sites/MediaSitesReducer";
import MediaSitesSelectionReducer from "./map-view/media-sites/MediaSitesSelectionReducer";
import ExploreSitesReducer from "./map-view/explore-sites/ExploreSitesReducer";
import ExploreSitesSelectionReducer from "./map-view/explore-sites/ExploreSitesSelectionReducer";
import MetaDataReducer from "./map-view/MetaDataReducer";
import SaveMapStateReducer from "./map-view/SaveMapStateReducer";
import PoiMapViewReducer from "./map-view/poi/PoiMapViewReducer";
import SecReducer from "./map-view/SecReducer";
import PoiSelectionReducer from "./map-view/poi/PoiSelectionReducer";
import BoundariesReducer from "./map-view/BoundariesReducer";
import LegendAndInfluenceReducer from "./map-view/LegendAndInfluenceReducer";
import RoadStretchesSelectionReducer from "./map-view/RoadStretchesSelectionReducer";
import DataLayersSelectionReducer from "./map-view/DataLayersSelectionReducer";
import MapImageReducer from "./map-image/MapImageReducer";

import filtersReducer from "./filtersReducer";
import CampaignBasicInfoReducer from "./campaign/CampaignBasicInfoReducer";
import CampaignListReducer from "./campaign/CampaignListReducer";
import RoadSegmentReducer from "./road-segments/RoadSegmentReducer";
import PoiReducer from "./PoiReducer";
import SiteUploadReducer from "./SiteUploadReducer";

// "Multi Cities" Selection Reducer
import MultiCitySelectionModalReducer from "./campaign-planning/MultiCitySelectionModalReducer";
// Single City Selection Reducer
import SingleCitySelectionModalReducer from "./SingleCitySelectionModalReducer";

// Org Reducers imports
import OrgTargetGroupReducer from "./org/OrgTargetGroupReducer";
import OrgDataLayerReducer from "./org/OrgDataLayerReducer";
import OrgRoadStretchReducer from "./org/OrgRoadStretchReducer";
import OrgUserManageReducer from "./org/OrgUserManageReducer";
import OrgProfileReducer from "./org/OrgProfileReducer";
import OrgMediaPriceReducer from "./org/OrgMediaPriceReducer";
import OrgMediaReducer from "./org/OrgMediaReducer";
import OrgRouteTypesReducer from "./org/OrgRouteTypesReducer";

import AuthReducer from "./auth/AuthReducer";

// Monitoring Reducers
import MonitoringSettingsReducer from "./campaign-monitoring/MonitoringSettingsReducer";
import MonitoringLogReducer from "./campaign-monitoring/MonitoringLogReducer";

// Regions Reducer import
import RegionReducer from "./regions/RegionReducer";
// KYP and ATLAS reducer imports
import GeoDataReducer from "./geo-data/GeoDataReducer";

// Seller
import SellerReducer from "./seller/SellerReducer";
import SellerMediaReducer from "./seller/SellerMediaReducer";
import SellerAnalyticsReducer from "./seller/SellerAnalyticsReducer";

// CampaignMedia
import CampaignMediaReducer from "./campaign-media/CampaignMediaReducer";

//campaign report reducer
import CampaignReportReducer from "./campaign-report/CampaignReportReducer";
import CampaignCityReportReducer from "./campaign-report/CampaignCityReportReducer";
import CampaignReducer from "./campaign/CampaignReducer";

// Buyer
import BuyerReducer from "./buyer/BuyerReducer";

// Prooh Reducers
import { proohReducers } from "../prooh/reducers/RootReducer";

// Mantaray Reducers
import { mantarayReducers } from "../mantaray/reducers/RootReducer";

// Seller Pitching Reducers
import sellerExcelUploadReducer from "./sellerExcelUpload/sellerExcelUploadReducer";
import sellerExcelOverviewReducer from "./sellerExcelOverview/sellerExcelOverviewReducer";

// CampaignPlan Reducer
import CampaignPlanReducer from "./campaign-plan/CampaignPlanReducer";

// Location Summary Reducer
import LocationSummaryReducer from "./location-summary/LocationSummaryReducer";

// Mavin Tools Reducer
import OtsToolReducer from "./mavin-tools/OtsToolReducer";
import LtsToolReducer from "./mavin-tools/LtsToolReducer";
import CompareLocationsToolReducer from "./mavin-tools/CompareLocationsToolReducer";
import ReachAndFrequencyToolReducer from "./mavin-tools/ReachAndFrequencyToolReducer";
import SingleSiteAnalyserToolReducer from "./mavin-tools/SingleSiteAnalyserToolReducer";
import RouteAnalysisToolReducer from "./mavin-tools/RouteAnalysisToolReducer";

// Confirm-Action Form
import ConfirmationModalReducer from "./ConfirmationModalReducer";

// platform-updates Form
import PlatformUpdatesModalReducer from "./PlatformUpdatesModalReducer";

// Alert Form
import AlertModalReducer from "./AlertModalReducer";

// Graph Reports
import CampaignGraphReportReducer from "./graph-reports/CampaignGraphReportReducer";

const mavinReducers = {
  cities: cityReducer,

  // MapView
  mapSitesSelection: MediaSitesSelectionReducer,
  mapViewMediaSites: MediaSitesReducer,
  mapViewExploreSites: ExploreSitesReducer,
  exploreSitesSelection: ExploreSitesSelectionReducer,
  saveMapState: SaveMapStateReducer,
  roadStretchesSelection: RoadStretchesSelectionReducer,
  poiSelection: PoiSelectionReducer,
  boundaries: BoundariesReducer,
  poi: PoiMapViewReducer,
  sec: SecReducer,
  dataLayersSelection: DataLayersSelectionReducer,
  mapViewImage: MapImageReducer,
  mapMetaData: MetaDataReducer,
  legendAndInfluence: LegendAndInfluenceReducer,

  // Filter Reducer
  filter: filtersReducer,

  // Campaign Report
  campaignReport: CampaignReportReducer,
  campaignCityReport: CampaignCityReportReducer,
  campaign: CampaignReducer,
  campaignMedia: CampaignMediaReducer,

  // Multi-City selection Reducer
  multiCitySelectionModal: MultiCitySelectionModalReducer,
  // Single City Selection Reducer
  singleCitySelectionModal: SingleCitySelectionModalReducer,

  // Component related reducers --> Start with c_**
  c_campaignBasicInfo: CampaignBasicInfoReducer,
  c_campaignList: CampaignListReducer,
  c_roadSegments: RoadSegmentReducer,
  c_poiNames: PoiReducer,
  c_siteUpload: SiteUploadReducer,

  // Monitoring
  monitoringSettings: MonitoringSettingsReducer,
  monitoringLog: MonitoringLogReducer,

  // Org-Reducers
  orgDataLayers: OrgDataLayerReducer,
  orgRoadStretch: OrgRoadStretchReducer,
  orgTargetGroup: OrgTargetGroupReducer,
  orgUserManage: OrgUserManageReducer,
  orgProfile: OrgProfileReducer,
  orgMediaPrice: OrgMediaPriceReducer,
  orgMedia: OrgMediaReducer,
  orgRouteTypes: OrgRouteTypesReducer,

  // Auth reducer
  auth: AuthReducer,

  // Region data reducer
  region: RegionReducer,
  // Kyp and ATLAS reducer
  geoData: GeoDataReducer,

  //Seller
  seller: SellerReducer,
  sellerMedia: SellerMediaReducer,
  sellerAnalytics: SellerAnalyticsReducer,

  // Seller Pitching Reducers
  sellerExcelUpload: sellerExcelUploadReducer,
  sellerExcelOverview: sellerExcelOverviewReducer,

  //Buyer
  buyer: BuyerReducer,

  // CampaignPlan
  campaignPlan: CampaignPlanReducer,

  // LocationSummary
  locationSummary: LocationSummaryReducer,

  // Mavin Tool
  otsTool: OtsToolReducer,
  ltsTool: LtsToolReducer,
  compareLocationsTool: CompareLocationsToolReducer,
  reachAndFrequencyTool: ReachAndFrequencyToolReducer,
  singleSiteAnalyserTool: SingleSiteAnalyserToolReducer,
  routeAnalysisTool: RouteAnalysisToolReducer,

  // Confirm-Action Form
  confirmationModal: ConfirmationModalReducer,

  // platform-updates form
  platformUpdatesModal: PlatformUpdatesModalReducer,

  // Alert Form
  alertModal: AlertModalReducer,

  // Graph Reports
  campaignGraphReport: CampaignGraphReportReducer,
};

// Combining Reducers
// -----------------------------------------------------------------------------------------
export default combineReducers({
  // mavin Reducers
  ...mavinReducers,
  // Prooh Reducers
  ...proohReducers,

  // mantarayReducers
  ...mantarayReducers,
});
