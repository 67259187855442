import { useSelector } from "react-redux";

// Constants and Utils
import { CPM, FIXED_PRICE } from "../../../constants/PriceMode";
import { calculatePriceFactor } from "../../utils/PriceFactorUtil";
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import {
  toFixedIfDecimalNumber,
  toStringWithRoundUp,
} from "../../../common-utils/number-utils/NumberUtils";
import { getMediaCostForCampaign } from "../../../utils/MediaUtils";
import {
  getDifferenceInDays,
  getTimestamp,
} from "../../../common-utils/date-utils/DateUtils";
import { DATE_FORMATS, LIT_STATUS } from "../../../constants/GeneralConstants";

// Components
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import Spinner from "../../../components/spinner/Spinner";

// Section Component Cells
export function MediaTag({ media = {}, tag }) {
  const { mediaId } = media || {};

  const isMediaSelected = useSelector(
    (state) => state.mediaSelection.selectedMedia[mediaId]
  );

  const tagBgColor = isMediaSelected ? "bg-success" : "bg-danger";

  return (
    <div className={`media-tag mr-2 text-center ${tagBgColor}`}>
      <b className="text-white">{tag}</b>
    </div>
  );
}

export function MediaImage({
  media = {},
  iconClassName = "",
  className = "",
  showNoImageMessage = false,
  addDefaultImageClass = true,
}) {
  const { fileItems = [] } = media || {};
  const { thumbnailUrl } = fileItems[0] || {};

  const defaultImageClass = addDefaultImageClass
    ? "media__default-image fa-4x"
    : "";

  if (!thumbnailUrl) {
    return (
      <div className="text-center">
        <i
          className={`far fa-images text-primary ${defaultImageClass} ${iconClassName}`}
        ></i>
        {showNoImageMessage && (
          <h6 className="opacity-50 font-italic mt-2">
            Media Image Not Available.
          </h6>
        )}
      </div>
    );
  }

  return (
    <img
      className={`w-100 ${className}`}
      src={thumbnailUrl}
      alt="thumbnail-image"
    />
  );
}

export function MediaInfo({ media = {} }) {
  const { title, type, height, width, litStatus, region } = media || {};
  const litStatusString = LIT_STATUS[litStatus];

  return (
    <div className="d-flex align-items-center">
      <div className="my-2">
        <h6 className="text-primary text-truncate-2" title={title}>
          {title}
        </h6>

        {/* meta data */}
        <p className="mb-0">
          {type} {litStatus ? `| ${litStatusString}` : ""}{" "}
          {region ? `| ${region}` : ""}
        </p>
        <p className="mb-0">{`Height: ${height} ft | Width: ${width} ft`}</p>
        <p className="mb-0">{region}</p>
      </div>
    </div>
  );
}

export function AvailableDateAndOtsAndCPICell({
  media = {},
  duration,
  setDuration,
  isMediaSelected,
  initialPriceDetails = {},
}) {
  const { otsSummary = {}, pricing = {}, suggestedPrice = {} } = media || {};
  const { ots, durationDays } = otsSummary || {};

  const impressions = ots ? ots : 0;
  const formattedImpressions = formatText(toStringWithRoundUp(impressions));

  const startTs = getTimestamp(
    duration.startDate,
    DATE_FORMATS.date_month_year
  );
  const endTs = getTimestamp(duration.endDate, DATE_FORMATS.date_month_year);
  const dayCount = getDifferenceInDays(startTs, endTs);

  // calculate "MOP" from pricing
  const costForCampaign = getMediaCostForCampaign(pricing, dayCount);
  const mop = costForCampaign ? costForCampaign : 0;
  const formattedMop = costForCampaign
    ? toStringWithRoundUp(costForCampaign)
    : 0;

  // cpm ==> mop / impressions
  const toFixedCpm = toFixedIfDecimalNumber(mop / impressions);
  const formattedCpm = formatText(toFixedCpm);

  // calculate suggested price
  const suggPriceForCampaign = getMediaCostForCampaign(
    suggestedPrice,
    dayCount
  );
  const suggPrice = suggPriceForCampaign
    ? toStringWithRoundUp(suggPriceForCampaign)
    : 0;

  //to get previous date
  // TODO: Revisit this logic
  // const previousDate = new Date(campaign.startDate);
  // previousDate.setDate(previousDate.getDate() - 1);

  // function onApplyDates(selectedDate) {
  //   // .toDate() will retrieve the dateObject from "moment"
  //   const startDate = selectedDate.startDate.toDate();
  //   const endDate = selectedDate.endDate.toDate();
  //   setDuration({ startDate, endDate });
  // }

  // const { totalOts } = initialPriceDetails || {};

  // DateRangePicker Style
  const styleObject = {
    border: false,
    // isPickerDisabled: isMediaSelected,
    isPickerDisabled: true,
    calenderIcon: false,
    placeholderTextStyle: "px-0",
  };

  return (
    <div className="my-2">
      {/* Impressions */}
      <span className="d-block">
        Imp: <b>{formattedImpressions}</b>
      </span>
      <span className="mt-2">CPM: {formattedCpm}</span>

      {/* date */}
      <div className="mt-3">
        <b className="mb-0">Available Dates</b>
        <div>
          <BootstrapDateRangePicker
            initialStartDate={duration.startDate}
            initialEndDate={duration.endDate}
            // onApplyDates={onApplyDates}
            minDate={new Date()}
            styleObject={styleObject}
          />
        </div>
      </div>

      {/* MOP & Suggested Price */}
      <div className="my-3 d-flex">
        <b className="mr-4">MOP: {formattedMop}</b>
        <b>Sugg.: {suggPrice}</b>
      </div>
    </div>
  );
}

export function PricingModeCell({
  media,
  campaignMedia,
  isMediaSelected,
  priceMode,
  setPriceMode,
}) {
  let checked = priceMode;
  if (isMediaSelected) {
    checked = campaignMedia.priceMode;
  }

  if (media.isCpmEnabled) {
    return (
      <div>
        <p className="mb-0 pr-2">Cpm Pricing is Enabled</p>
      </div>
    );
  }
  return (
    <div className="my-3 col-3 px-0">
      <b className="mb-1">Pricing</b>
      <div>
        <input
          type="radio"
          className="mr-2"
          id={`cpm-${media.mediaId}`}
          name={media.mediaId}
          value={CPM}
          checked={checked === CPM}
          onClick={(e) => setPriceMode(e.target.value)}
          disabled={isMediaSelected}
        />
        <label htmlFor={`cpm-${media.mediaId}`} className="mb-0">
          CPM
        </label>
      </div>
      <div>
        <input
          type="radio"
          id={`fixed-${media.mediaId}`}
          name={media.mediaId}
          className="mr-2"
          value={FIXED_PRICE}
          checked={checked === FIXED_PRICE}
          onClick={(e) => setPriceMode(e.target.value)}
          disabled={isMediaSelected}
        />
        <label htmlFor={`fixed-${media.mediaId}`} className="mb-0">
          FIXED
        </label>
      </div>
    </div>
  );
}

export function BuyerPriceCell({
  buyerPrice,
  setBuyerPrice,
  campaignMedia,
  mop,
}) {
  const buyerPriceFromCampaignMedia =
    campaignMedia && campaignMedia.pricing.price
      ? toLocaleString(campaignMedia.pricing.price)
      : "";

  // Is CampaignMediaExists..
  const isMediaSelected = campaignMedia;
  return (
    <div className="my-4">
      <p className="mb-0">
        {"MOP: "}
        <b>{`Rs:${toLocaleString(mop)}`}</b>
      </p>
      <div className="input-group-sm col-8 px-0">
        <label className="mb-0">Buyer Price</label>
        <input
          type="text"
          onChange={(e) => setBuyerPrice(e.target.value)}
          value={isMediaSelected ? buyerPriceFromCampaignMedia : buyerPrice}
          className="form-control shadow-none"
          disabled={isMediaSelected}
        />
      </div>
    </div>
  );
}

function SuggestedPrice({ media, suggestedPrice }) {
  const priceFactor = calculatePriceFactor(media.ltsSummary).toFixed(2);
  let priceFactorIndicator = "";
  if (priceFactor > 1) {
    priceFactorIndicator = "fa fa-arrow-up text-success pl-2";
  } else if (priceFactor < 1) {
    priceFactorIndicator = "fa fa-arrow-down text-danger pl-2";
  }

  if (!suggestedPrice) {
    return <span>&nbsp;</span>;
  }

  return (
    <p className="mb-0">
      Sugg: <b>Rs. {toStringWithRoundUp(suggestedPrice)}</b>
      <span>
        <i className={priceFactorIndicator} title={priceFactor}></i>
      </span>
    </p>
  );
}

function SellerPrice({ sellerPrice, setSellerPrice, campaignMedia }) {
  // Is CampaignMedia Exists..
  const isMediaSelected = campaignMedia;

  const sellerPriceFromMedia =
    campaignMedia && campaignMedia.sellerPrice
      ? toLocaleString(campaignMedia.sellerPrice)
      : "";
  return (
    <div className="input-group-sm col-7 px-0">
      <label className="mb-0">Seller Earning</label>
      <input
        type="text"
        onChange={(e) => setSellerPrice(e.target.value)}
        value={isMediaSelected ? sellerPriceFromMedia : sellerPrice}
        className="form-control shadow-none"
        disabled={isMediaSelected}
      />
    </div>
  );
}

export function MarginValueCell({ sellerPrice, buyerPrice, campaignMedia }) {
  let marginValue = "";
  if (campaignMedia) {
    const buyerPriceFromCampaignMedia = campaignMedia.pricing.price;
    const sellerPriceFromCampaignMedia = campaignMedia.sellerPrice;
    marginValue = buyerPriceFromCampaignMedia - sellerPriceFromCampaignMedia;
  }
  if (sellerPrice && buyerPrice) {
    marginValue = new Number(buyerPrice) - new Number(sellerPrice);
  }
  return (
    <p className="my-4">
      Margin: <b>Rs. {marginValue ? toLocaleString(marginValue) : "NA"}</b>
    </p>
  );
}

export function SellerEarningCell({
  sellerPrice,
  setSellerPrice,
  campaignMedia,
  media,
  suggestedPrice,
}) {
  return (
    <div className="my-4">
      {/* Suggested Price Details*/}
      <SuggestedPrice media={media} suggestedPrice={suggestedPrice} />

      {/* Seller Earning :: Payment to Seller */}
      <SellerPrice
        sellerPrice={sellerPrice}
        setSellerPrice={setSellerPrice}
        campaignMedia={campaignMedia}
      />
    </div>
  );
}

export function AdditionalButtonState({
  isMediaSelected = false,
  selectMedia = () => {},
  unSelectMedia = () => {},
  selectUnSelectMediaLoading = false,
  disabled = false,
}) {
  const btnClassName = isMediaSelected
    ? "added-button btn-outline-success"
    : "add-button btn-outline-primary";

  const onClickFn = isMediaSelected ? unSelectMedia : selectMedia;
  const btnLabel = isMediaSelected ? "Added" : "Add";

  return (
    <button
      type="button"
      className={`btn shadow-none mt-4 ${btnClassName}`}
      onClick={onClickFn}
      disabled={selectUnSelectMediaLoading || disabled}
    >
      {/* Icon */}
      {isMediaSelected && <i className="fa fa-check mr-1"></i>}

      {/* Label */}
      <b>{btnLabel}</b>

      {/* Loading */}
      {!isMediaSelected && selectUnSelectMediaLoading && (
        <Spinner className="ml-2 spinner-border-sm" />
      )}
    </button>
  );
}
