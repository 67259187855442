import { useDispatch } from "react-redux";

// Actions
import { openSetupMonitoringForm } from "../../../actions/campaign-monitoring/MonitoringSettingsActions";

// Constants and Utils
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import {
  constructDateString,
  constructSingleDateString,
  getDifferenceInDays,
} from "../../../common-utils/date-utils/DateUtils";
import {
  FormDataTargets,
  DATE_FORMATS,
} from "../../../constants/GeneralConstants";

// Components
import { MediaImage } from "../../../components/campaign-media-table-row/MediaRow";

// Page Components
export function RoadInfo({ road, roadType }) {
  return <td>{`${roadType}:${road.name}`}</td>;
}

export function MediaDuration({ media }) {
  const { startTimestamp, endTimestamp } = media;

  const diff = getDifferenceInDays(startTimestamp, endTimestamp);
  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );
  return (
    <td className="align-top">
      <div className="text-right">
        {dateString}
        <span className="sub-text">{`${diff} days`}</span>
      </div>
    </td>
  );
}

export function MediaInfo({ media }) {
  const { title, type, width, height, litStatus, region } = media;
  return (
    <td>
      <div className="d-flex">
        <MediaImage media={media} />
        <div className="pl-2">
          <span>{title}</span>
          <span className="sub-text">
            {type} | {width}ft x {height}ft | {litStatus}
          </span>
          <span className="sub-text">{region}</span>
        </div>
      </div>
    </td>
  );
}

export function TgSpecificOts({
  estTgSpecific,
  liveTgSpecific,
  isShowLiveMetrics = false,
}) {
  const formattedEstTg = formatText(toStringWithRoundUp(estTgSpecific));
  const formattedLiveTg = formatText(toStringWithRoundUp(liveTgSpecific));

  return (
    <td className="align-top">
      <div className="text-right">
        {/* only est imp */}
        {!isShowLiveMetrics && <p className="mb-0">{formattedEstTg}</p>}

        {/* both live & est */}
        {isShowLiveMetrics && (
          <>
            <p className="mb-0">{formattedLiveTg}</p>
            <p className="mb-0 sub-text">{formattedEstTg}</p>
          </>
        )}
      </div>
    </td>
  );
}

export function GenericOts({
  estGeneric,
  liveGeneric,
  isShowLiveMetrics = false,
}) {
  const formattedEstGeneric = formatText(toStringWithRoundUp(estGeneric));
  const formattedLiveGeneric = formatText(toStringWithRoundUp(liveGeneric));

  return (
    <td className="align-top">
      <div className="text-right">
        {/* only est imp */}
        {!isShowLiveMetrics && <p className="mb-0">{formattedEstGeneric}</p>}

        {/* both live & est */}
        {isShowLiveMetrics && (
          <>
            <p className="mb-0">{formattedLiveGeneric}</p>
            <p className="mb-0 sub-text">{formattedEstGeneric}</p>
          </>
        )}
      </div>
    </td>
  );
}

export function SetUpMonitoring({ media }) {
  const { id, startTimestamp, endTimestamp, isMonitoringEnabled } = media;
  const mediaDateObj = { startTimestamp, endTimestamp };

  const dispatch = useDispatch();
  function openSetupMonitoringFormFunction() {
    dispatch(openSetupMonitoringForm(id, mediaDateObj));
  }

  // If Monitoring is True
  if (isMonitoringEnabled) {
    return (
      <td className="text-right align-top">
        <b className="text-warning">{"Upcoming"}</b>
      </td>
    );
  }

  // If Monitoring is False
  return (
    <td className="align-top">
      <div className="text-right">
        <button
          type="button"
          className="btn btn-link p-0 shadow-none"
          data-toggle="modal"
          data-target={`#${FormDataTargets.setupMonitoringForm}`}
          onClick={openSetupMonitoringFormFunction}
        >
          {"Setup Monitoring"}
        </button>
      </div>
    </td>
  );
}

export function MonitoringScheduled({ media }) {
  const { startTimestamp, isMonitoringEnabled } = media;
  if (!isMonitoringEnabled) {
    return (
      <td className="text-right align-top">
        <p className="mb-0"> {"Not Scheduled"} </p>
      </td>
    );
  }
  const startDateObj = new Date(startTimestamp);
  const startDateString = constructSingleDateString(
    startDateObj,
    DATE_FORMATS.full_month_with_date_year
  );
  return (
    <td className="text-right align-top">
      <p className="mb-0"> {"Scheduled"} </p>
      <span className="text-muted d-block">{startDateString}</span>
    </td>
  );
}

export function MonitoringLive({ media }) {
  return (
    <td className="text-right align-top">
      <p className="text-success mb-0">
        <i className="fa fa-circle"></i>
        {" Live"}
      </p>
      <span className="btn-link">{"3 updates"}</span>
      <span className="text-muted d-block">{"Nov 13, 2022"}</span>
    </td>
  );
}

export function MonitoringComplete({ media }) {
  const { endTimestamp } = media;
  const endDateObj = new Date(endTimestamp);
  const endDateString = constructDateString(
    undefined,
    endDateObj,
    DATE_FORMATS.full_month_with_date_year
  );
  return (
    <td className="text-right align-top">
      <p className="mb-0"> {"Completed"} </p>
      <span className="text-muted d-block">{endDateString}</span>
    </td>
  );
}

export function TotalOts({ estTotal, liveTotal }) {
  return (
    <td>
      <div className="text-right">
        {toLocaleString(estTotal)}
        {liveTotal && (
          <span className="sub-text">{toLocaleString(liveTotal)}</span>
        )}
      </div>
    </td>
  );
}

export function Cost({ estCost, liveCost }) {
  return (
    <td>
      <div className="text-right">
        {estCost}
        {liveCost && (
          <span className="sub-text">{toStringWithRoundUp(liveCost)}</span>
        )}
      </div>
    </td>
  );
}
