import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// Actions
import {
  clearRoadStretchData,
  getRoadStretchesByIds,
} from "../../../actions/org/OrgRoadStretchActions";
import {
  getTargetGroupAttributes,
  getTgInfo,
  resetTgGroup,
} from "../../../actions/org/OrgTargetGroupActions";
import { getSellerMediaByStretchIds } from "../../../actions/seller/SellerMediaActions";
import {
  getPoiHierarchy,
  resetPoiInfo,
} from "../../../actions/map-view/poi/PoiActions";
import {
  getTgHeatMapData,
  removeTgHeatMapData,
} from "../../../actions/geo-data/GeoDataActions";
import { resetMetaData } from "../../../actions/map-view/MetaDataActions";
import { resetMediaSites } from "../../../actions/map-view/media-sites/MediaSitesActions";
import {
  resetMediaInfluence,
  resetMediaMarkers,
} from "../../../actions/map-view/media-sites/LegendAndInfluenceActions";
import {
  resetBrandInfo,
  resetPoiInfluenceCircleRadius,
} from "../../../actions/map-view/poi/PoiSelectionActions";
import { resetSecInfo } from "../../../actions/map-view/SecActions";
import { resetDataLayerInfo } from "../../../actions/map-view/DataLayerSelectionActions";
import { resetBoundariesInfo } from "../../../actions/map-view/BoundariesActions";
import { clearRegionDataByCity } from "../../../actions/regions/RegionActions";
import { resetExploreSites } from "../../../actions/map-view/media-sites/ExploreSitesActions";
import { resetSelectedRoadStretches } from "../../../actions/map-view/RoadStretchesSelectionActions";
import { resetExploreSiteSelection } from "../../../actions/map-view/media-sites/ExploreSitesSelectionActions";
import { getZones } from "../../../prooh/actions/campaign-planning/ZonesAndSubZonesActions";
import { getRouteTypes } from "../../../actions/org/OrgRouteTypesActions";

export function useMemoCompare(next, compare) {
  const previousRef = useRef();
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}

export function useGetPoiHierarchy(cityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cityId) {
      dispatch(getPoiHierarchy(cityId));
      return;
    }
  }, [dispatch, cityId]);

  return null;
}

export function useGetTgAttributes(cityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cityId) {
      dispatch(getTargetGroupAttributes());
      return;
    }
  }, [dispatch, cityId]);

  return null;
}

export function useGetZones(cityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cityId) {
      dispatch(getZones());
      return;
    }
  }, [dispatch, cityId]);

  return null;
}

export function useGetRouteTypes(cityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cityId) {
      dispatch(getRouteTypes());
      return;
    }
  }, [dispatch, cityId]);

  return null;
}

export function useGetTgInfo(tgId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (tgId) {
      dispatch(getTgInfo(tgId));
      return;
    }
  }, [dispatch, tgId]);

  return null;
}

export function useGetSellerMediaByStretchIds(stretchIds) {
  const dispatch = useDispatch();
  const stretchIdsLength = stretchIds.length;

  useEffect(() => {
    if (stretchIdsLength > 0) {
      dispatch(getSellerMediaByStretchIds(stretchIds));
      return;
    }
  }, [dispatch, stretchIdsLength]);

  return null;
}

export function useGetStretchByIds(stretchIds) {
  const dispatch = useDispatch();
  const stretchIdsLength = stretchIds.length;

  useEffect(() => {
    if (stretchIdsLength > 0) {
      dispatch(getRoadStretchesByIds(stretchIds));
      return;
    }
  }, [dispatch, stretchIdsLength]);

  return null;
}

export function useGetTgHeatMap(resPoiLayers, bbox) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (resPoiLayers.length > 0 && bbox) {
      dispatch(getTgHeatMapData(resPoiLayers, bbox));
      return;
    }
  }, [dispatch, JSON.stringify(resPoiLayers), bbox]);

  return null;
}

export function useGetQueryParam(...keys) {
  const { search } = useLocation();

  if (keys.length < 1) {
    return {};
  }

  return keys.reduce((acc, queryKey) => {
    const queryValue = new URLSearchParams(search).get(queryKey);
    acc[queryKey] = queryValue;
    return acc;
  }, {});
}

/**
 * Checks the url pathname  which includes given key
 * @param {*} keys
 * @returns true or false
 */
export function useUrlKeyCheck(...keys) {
  const { pathname } = useLocation();

  const isPresent = keys.some((key) => pathname.includes(key));

  return isPresent;
}

/**
 * This function is used to reset all the states of the map view page
 * ...Media (Inventory/CampaignMedia)
 * ...TargetGroup
 * ...Boundaries
 * ...DataLayers
 * ...RoadStretches
 * ...Pois
 */
export function useResetMapState() {
  const dispatch = useDispatch();
  useEffect(() => {
    // Clear Region Data of the city..
    dispatch(clearRegionDataByCity());

    // Reset Media MetaData Info
    dispatch(resetMetaData());

    // Reset Media Details Info
    dispatch(resetMediaSites());

    // Reset Media Legend Actions (Markers and Influence Circle)
    dispatch(resetMediaMarkers());
    dispatch(resetMediaInfluence());

    // Reset Inventory Media, selection
    dispatch(resetExploreSites());
    dispatch(resetExploreSiteSelection());

    // Reset Poi Brand Info, poi Info, poi influence details
    dispatch(resetBrandInfo());
    dispatch(resetPoiInfo());
    dispatch(resetPoiInfluenceCircleRadius());

    // Reset Boundaries
    dispatch(resetBoundariesInfo());

    // Reset Target Group Heat Map Data
    dispatch(removeTgHeatMapData());

    // Reset Target Groups
    dispatch(resetTgGroup());

    // Reset DataLayers
    dispatch(resetDataLayerInfo());

    // Reset Road Stretches
    dispatch(clearRoadStretchData());

    // Reset Sec
    dispatch(resetSecInfo());

    // Reset RoadStretches selection and Road Stretch Data
    dispatch(resetSelectedRoadStretches());
    dispatch(clearRoadStretchData());
  }, [dispatch]);
  return null;
}
