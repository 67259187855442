import { useState } from "react";

// Constants
import { Status } from "../../constants/GeneralConstants";

// Components
import {
  filterDataList,
  getSelectedIndex,
  getTitle,
  isSubSectionOpen,
  getTgTag,
} from "../nested-checklist/nestedCheckListUtil";
import Spinner from "../spinner/Spinner";

// Page Components
/**
 * @param {{isSelected: boolean, tag: string}} props
 */
function Tag({ isSelected, selectedCount, totalCount }) {
  const faIconClass = isSelected ? "fa fa-angle-down" : "fa fa-angle-right";

  return (
    <>
      {(isSelected || selectedCount > 0) && (
        <span className="pr-1">{`${selectedCount}/${totalCount}`}</span>
      )}
      <i className={`mt-1 ${faIconClass}`}></i>
    </>
  );
}

function SelectBrandButton({ data, onAddButton, loading }) {
  const { label, status } = data;
  function onClickAction(e, status) {
    e.stopPropagation();
    onAddButton(label, status);
  }

  if (status === Status.CHECKED) {
    return (
      <button
        type="button"
        className="added-button btn shadow-none btn-outline-success"
        onClick={(e) => onClickAction(e, Status.UNCHECKED)}
        disabled={loading}
      >
        <i className="fa fa-check"></i> <b>Added</b>
      </button>
    );
  }
  return (
    <button
      type="button"
      className="add-button btn shadow-none btn-outline-primary"
      onClick={(e) => onClickAction(e, Status.CHECKED)}
      disabled={loading}
    >
      <b>Add</b>
    </button>
  );
}

function SelectBrandCheckbox({ data, onInputAction, loading }) {
  const { label, status } = data;
  function onChangeAction(e, status) {
    e.stopPropagation();
    onInputAction(label, status);
  }

  if (status === Status.CHECKED) {
    return (
      <input
        type="checkbox"
        checked={true}
        className="cursor-pointer"
        onChange={(e) => {
          onChangeAction(e, Status.UNCHECKED);
        }}
        disabled={loading}
      />
    );
  }
  return (
    <input
      type="checkbox"
      checked={false}
      className="cursor-pointer"
      onChange={(e) => {
        onChangeAction(e, Status.CHECKED);
      }}
      disabled={loading}
    />
  );
}

function isLoading(data, label, brandLoadingMap) {
  if (!brandLoadingMap) {
    return false;
  }

  if (!data.children || data.children.length === 0) {
    return brandLoadingMap[label] === true;
  }

  return data.children.some(
    (child) =>
      brandLoadingMap[child.label] === true ||
      (child.children &&
        child.children.some(
          (grandChild) => brandLoadingMap[grandChild.label] === true
        ))
  );
}

function ListElement({
  data,
  index,
  onLabelClick,
  onAddButton,
  brandLoadingMap,
  shouldShowCheckboxes = false,
  onCheckboxChange = () => {},
}) {
  const key = `${data.label}_${index}`;
  const { label, isSelected, level } = data;
  const hasChildren = data.children && data.children.length > 0;
  const { checkedCount, totalCount } = getTgTag(data.children);
  const loading = isLoading(data, label, brandLoadingMap);
  const selectedClassName = isSelected ? "font-weight-bold" : "";
  return (
    <div
      key={key}
      className={`navbar p-2 ${selectedClassName}`}
      onClick={(e) => {
        if (e.target.tagName !== "INPUT" && e.target.tagName !== "BUTTON") {
          onLabelClick && onLabelClick(label);
        }
      }}
    >
      <span className="form-check p-0 cursor-pointer">{label}</span>
      <div className="d-flex align-items-center">
        {loading && <Spinner className="spinner-grow-sm m-1" />}

        {/* If checkboxes should be shown and data level is 3  */}
        {shouldShowCheckboxes && level === 3 && (
          <SelectBrandCheckbox
            data={data}
            onInputAction={onCheckboxChange}
            loading={loading}
          />
        )}
        {/* If checkboxes should not be shown and data level is 23*/}
        {!shouldShowCheckboxes && level === 3 && (
          <SelectBrandButton
            data={data}
            onAddButton={onAddButton}
            loading={loading}
          />
        )}

        {/* Render Tag if there are children */}
        {hasChildren && (
          <Tag
            isSelected={isSelected}
            selectedCount={checkedCount}
            totalCount={totalCount}
          />
        )}
      </div>
    </div>
  );
}

function checkAnyChildrenChecked(children = []) {
  const isAnyChildrenChecked = children.some((eachChild) =>
    eachChild.children.some(
      (eachSubChild) => eachSubChild.status === Status.CHECKED
    )
  );

  return isAnyChildrenChecked;
}

function CollapseList({
  data,
  index,
  onLabelClick,
  onAddButton,
  onCheckboxChange,
  shouldShowCheckboxes,
  brandLoadingMap,
}) {
  const [isActive, setIsActive] = useState(false);

  const { label, children = [] } = data || {};

  const btnClassName = isActive && "text-primary";
  const iconClassName = isActive ? "fa fa-angle-down" : "fa fa-angle-right";
  const cardBodyClassName = isActive ? "bg-alt" : "";

  const isAnyChildrenChecked = checkAnyChildrenChecked(children);

  return (
    <div key={index} className="accordion" id={`accordion-${index}`}>
      <div className="card border-0 ">
        <div className="card-header bg-white p-0" id={`heading-${index}`}>
          <button
            className={`btn shadow-none px-2 w-100 border-0 text-left ${btnClassName}`}
            type="button"
            data-target={`#collapse-${index}`}
            aria-expanded={isActive ? "true" : "false"}
            aria-controls={`collapse-${index}`}
            onClick={() => setIsActive(!isActive)}
          >
            {label}
            <span className="float-right">
              {isAnyChildrenChecked && (
                <i className="fa fa-check-circle text-success" />
              )}
              <i className={`ml-2 ${iconClassName}`} />
            </span>
          </button>
        </div>
        <div
          id={`collapse-${index}`}
          className={`collapse ${isActive ? "show" : ""}`}
          aria-labelledby={`heading-${index}`}
          data-parent={`#accordion-${index}`}
        >
          <div
            className={`card-body my-2 px-3 p-0 max-h-250 overflow-auto ${cardBodyClassName}`}
          >
            {children.map((childData, childIndex) => (
              <ListElement
                key={`${childData.label}_${childIndex}`}
                data={{ ...childData }}
                index={childIndex}
                onLabelClick={onLabelClick}
                onAddButton={onAddButton}
                shouldShowCheckboxes={shouldShowCheckboxes}
                onCheckboxChange={onCheckboxChange}
                brandLoadingMap={brandLoadingMap}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * @param {{
 *    dataList: [{label: string, isSelected: boolean, status: string}],
 *    onLabelClick: (label: string) => void,
 *    onStatusChange: (label: string, isChecked: boolean) => void,
 * }} props
 */
function List({
  dataList,
  brandLoadingMap,
  onLabelClick,
  onAddButton,
  onCheckboxChange,
  shouldShowCheckboxes,
}) {
  if (!dataList || !dataList.length) {
    return null;
  }

  return dataList.map((data, index) => {
    if (data.type) {
      return (
        <CollapseList
          key={index}
          data={data}
          index={index}
          onLabelClick={onLabelClick}
          onAddButton={onAddButton}
          onCheckboxChange={onCheckboxChange}
          shouldShowCheckboxes={shouldShowCheckboxes}
          brandLoadingMap={brandLoadingMap}
        />
      );
    }

    return (
      <ListElement
        key={`${data.label}_${index}`}
        data={{ ...data }}
        index={index}
        onLabelClick={onLabelClick}
        onAddButton={onAddButton}
        brandLoadingMap={brandLoadingMap}
        shouldShowCheckboxes={shouldShowCheckboxes}
        onCheckboxChange={onCheckboxChange}
      />
    );
  });
}

function TitleAndSelectAllAttrSection({
  title,
  dataList,
  setAdded,
  setAddButtonStatus,
  selectedIndex,
  selectAllAttr,
  brandLoadingMap = {},
}) {
  if (!title) {
    return null;
  }

  // Checking whether all attributes are selected or not: true/false
  const isAllPoisAttrSelected = dataList.every(
    (data) => data.status === Status.CHECKED
  );
  const btnClassName = isAllPoisAttrSelected
    ? "btn-outline-danger"
    : "btn-outline-primary";
  const btnLabel = isAllPoisAttrSelected ? "Remove All" : "Add All";
  const btnAction = isAllPoisAttrSelected ? removeAllPois : addAllPois;

  // Functions
  function addAllPois() {
    dataList.forEach((data) => {
      const { label, status } = data;
      if (status === Status.UNCHECKED) {
        setAdded(label, Status.CHECKED);
        setAddButtonStatus(selectedIndex, label, Status.CHECKED);
      }
    });
  }

  function removeAllPois() {
    dataList.forEach((data) => {
      const { label } = data;
      setAdded(label, Status.UNCHECKED);
      setAddButtonStatus(selectedIndex, label, Status.UNCHECKED);
    });
  }

  const hasLoading = Object.values(brandLoadingMap).some((value) => value);

  return (
    <div className="navbar p-2 border-bottom">
      <h3 className="font-weight-bold text-truncate col-8 px-0" title={title}>
        {title}
      </h3>

      {selectAllAttr && (
        <div className="d-flex gap-2">
          <button
            className={`btn add-button shadow-none ${btnClassName}`}
            onClick={btnAction}
            disabled={hasLoading}
          >
            <b>{btnLabel}</b>
          </button>
        </div>
      )}
    </div>
  );
}

/**
 * Component
 */
function NestedList({
  data,
  title,
  path,
  brandLoadingMap,
  setSelected,
  setAdded,
  setAddButtonStatus,
  selectAllAttr, // select all attributes button "boolean"
  // these are used for the checkbox selections
  shouldShowCheckboxes = false,
  onCheckboxChange,
}) {
  const dataList = filterDataList(data, path);
  const selectedIndex = getSelectedIndex(dataList);
  const subSectionOpen = isSubSectionOpen(selectedIndex, dataList);
  const titleToBeDisplayed = getTitle(selectedIndex, dataList);

  return (
    <div className={`list ${title ? "col-6" : ""}`}>
      <div className="d-flex">
        <div className={`${title ? "col-12 px-0" : "col-6"}`}>
          {/* Title and Add All poi attr button */}
          <TitleAndSelectAllAttrSection
            title={title}
            dataList={dataList}
            setAdded={setAdded}
            setAddButtonStatus={setAddButtonStatus}
            selectedIndex={selectedIndex}
            selectAllAttr={selectAllAttr}
            brandLoadingMap={brandLoadingMap}
          />

          {/* listing */}
          <List
            dataList={dataList}
            brandLoadingMap={brandLoadingMap}
            onLabelClick={(label) => setSelected(selectedIndex, label)}
            onAddButton={(label, status) => {
              setAdded(label, status);
              setAddButtonStatus(
                selectedIndex,
                label,
                status === Status.CHECKED ? Status.CHECKED : Status.UNCHECKED
              );
            }}
            shouldShowCheckboxes={shouldShowCheckboxes}
            onCheckboxChange={(label, status) => {
              setAdded(label, status);
              onCheckboxChange(
                selectedIndex,
                label,
                status === Status.CHECKED ? Status.CHECKED : Status.UNCHECKED
              );
            }}
          />
        </div>

        {/* nested listing of attributes */}
        {subSectionOpen && (
          <>
            <div className="border-left"></div>
            <NestedList
              data={dataList}
              brandLoadingMap={brandLoadingMap}
              title={titleToBeDisplayed}
              path={selectedIndex}
              setSelected={setSelected}
              setAdded={setAdded}
              setAddButtonStatus={setAddButtonStatus}
              selectAllAttr={selectAllAttr}
              shouldShowCheckboxes={shouldShowCheckboxes}
              onCheckboxChange={onCheckboxChange}
            />
          </>
        )}
      </div>
    </div>
  );
}

NestedList.defaultProps = {
  path: {},
  selectAllAttr: false,
};

export default NestedList;
