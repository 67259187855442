import { useSelector } from "react-redux";

// Constants
import { DurationConstants } from "../../../constants/GeneralConstants";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import { ProgressBarLarge } from "./ProgressBarLarge";

/**
 *
 * @param {*} campaignMedia
 * @returns prepares a map of
 * 1. RoadStretchID to its MediaIds ==> roadStretchToMediaIdMap
 * 2. Stretch to Active MediaCount ==> roadStretchToActiveMediaCount
 */
function getRoadStretchToMediaInfo(campaignMedia) {
  const roadStretchToMediaIdMap = {};
  const roadStretchToActiveMediaCount = {};
  for (const idx in campaignMedia) {
    const media = campaignMedia[idx];
    const stretchId = media.roadStretchId;

    // getting Road-Stretch to mediaMap i.e, {rs1: [m1, m2], rs2: [m1, m2]}
    // ---------------------------------------------------
    const mediaId = media.id;
    // get the existing data
    const exStretchMediaIds = roadStretchToMediaIdMap[stretchId] || [];
    exStretchMediaIds.push(mediaId);
    // update the map
    roadStretchToMediaIdMap[stretchId] = exStretchMediaIds;

    // getting active "MediaCount" for each "Road-Stretch" i.e, {rs1: 1, rs2: 0, rs3: 1}
    // --------------------------------------------------------
    const isActiveCnt = media.isLive ? 1 : 0;
    // get the existing data
    const exStretchMediaCnt = roadStretchToActiveMediaCount[stretchId] || 0;
    // update the map
    roadStretchToActiveMediaCount[stretchId] = exStretchMediaCnt + isActiveCnt;
  }

  return { roadStretchToMediaIdMap, roadStretchToActiveMediaCount };
}

/**
 * Gives the TotalActive MediaCount for a City
 */
function getTotalActiveMediaCount(activeMediaCountMap = {}) {
  if (Object.keys(activeMediaCountMap).length === 0) {
    return 0;
  }
  const total = Object.values(activeMediaCountMap).reduce(
    (pre, curr) => pre + curr,
    0
  );
  return total;
}

/**
 * Returns Road-Stretch Accumulated "OTS map" for all the stretches for ProgressBar
 */
function prepareRoadStretchAccOtsMap(
  roadStretchToMediaIdMap,
  campaignMediaOtsLogSummary
) {
  const roadStretchAccOtsMap = {};

  // Accumulate OTS for each Road Stretch
  Object.keys(roadStretchToMediaIdMap).forEach((stretchId) => {
    const rsMediaIds = roadStretchToMediaIdMap[stretchId];
    if (!rsMediaIds || rsMediaIds.length === 0) {
      // Check if no media present in the Road Stretch
      return;
    }

    const accOts = {
      mediaCount: rsMediaIds.length,
      otsServedTotal: 0,
      targetOtsServedTotal: 0,
      genericOtsServedTotal: 0,
    };

    for (const idx in rsMediaIds) {
      const mediaId = rsMediaIds[idx];
      const mediaOtsSummary = campaignMediaOtsLogSummary[mediaId];

      accOts["otsServedTotal"] += mediaOtsSummary?.otsServedTotal;
      accOts["targetOtsServedTotal"] += mediaOtsSummary?.targetOtsServedTotal;
      accOts["genericOtsServedTotal"] += mediaOtsSummary?.genericOtsServedTotal;
    }

    roadStretchAccOtsMap[stretchId] = accOts;
  });

  return roadStretchAccOtsMap;
}

// Table Headers
function getCityPerformanceTableHeaders(
  roadStretchMap,
  campaignMedia,
  roadStretchToActiveMediaCount
) {
  const stretchCount = Object.keys(roadStretchMap).length || "0";
  const mediaCount = campaignMedia.length || "0";

  const totalActiveMediaCount = getTotalActiveMediaCount(
    roadStretchToActiveMediaCount
  );

  return [
    {
      title: {
        displayName: `Total Stretches: ${stretchCount}`,
        className: "col-3 border-right",
      },
    },
    {
      title: {
        displayName: `Active Media Sites: ${totalActiveMediaCount}/${mediaCount}`,
        className: "col-9",
      },
    },
  ];
}

function CityPerformanceTableRow({
  eachStretch,
  eachStretchAccOts = {},
  eachStretchActiveMediaCnt,
  eachStretchPlanInfo = {},
}) {
  const { name: stretchName } = eachStretch || {};
  const { genericOts, genericOtsLit, targetOts, targetOtsLit } =
    eachStretchPlanInfo;

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { targetGroupId } = campaignPlan || {};

  // TODO:: Remove 30 as multiplication factor - fix PlanData
  // Planned Est. Impressions
  const plannedGenericOts =
    (genericOtsLit ?? genericOts) * DurationConstants.THIRTY_DAYS;
  const plannedTargetOts =
    (targetOtsLit ?? targetOts) * DurationConstants.THIRTY_DAYS;

  // Accumulated Ots From Performance
  const { genericOtsServedTotal, targetOtsServedTotal, mediaCount } =
    eachStretchAccOts;

  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  return (
    <tr>
      <td className="border-right">
        <div className="text-right">
          <p className="mb-1 text-primary text-truncate" title={stretchName}>
            {stretchName || "--"}
          </p>
          <span className="text-muted mb-1">{"TG"}</span>
          <span className="text-muted d-block mt-1">{"General"}</span>
        </div>
      </td>
      <td>
        <div className="text-left">
          <p className="mb-1">{`${
            eachStretchActiveMediaCnt || "0"
          } of ${mediaCount} sites are Active`}</p>

          {/* for TG impressions */}
          {targetGroupId && (
            <ProgressBarLarge
              impression={targetOtsServedTotal}
              plannedEstImpressions={plannedTargetOts}
              className={"bg-success"}
            />
          )}

          {/* for General Impressions */}
          <ProgressBarLarge
            impression={genericOtsServedTotal}
            plannedEstImpressions={plannedGenericOts}
          />
        </div>
      </td>
    </tr>
  );
}

/**
 * Stretches And Medias Performance Table
 */
function StretchesAndMediasPerformanceTable({
  campaignPlan = "",
  campaignMedia,
  campaignMediaOtsLogSummary,
  roadStretchMap,
}) {
  const { roadStretchOtsMap = {} } = campaignPlan;

  // Get Performance
  const { roadStretchToMediaIdMap, roadStretchToActiveMediaCount } =
    getRoadStretchToMediaInfo(campaignMedia);

  const roadStretchAccOtsMap = prepareRoadStretchAccOtsMap(
    roadStretchToMediaIdMap,
    campaignMediaOtsLogSummary
  );

  // Getting City Performance Table Header
  const tableHeaders = getCityPerformanceTableHeaders(
    roadStretchMap,
    campaignMedia,
    roadStretchToActiveMediaCount
  );

  return (
    <div className="mt-5">
      <h4>{"Performance"}</h4>
      <div className="table-responsive">
        <table className="table table-fixed">
          <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
          <tbody>
            {Object.values(roadStretchMap).map((eachStretch) => {
              const stretchId = eachStretch.id;
              const stretchMediaIds = roadStretchToMediaIdMap[stretchId];
              if (!stretchMediaIds || stretchMediaIds.length === 0) {
                return null;
              }

              return (
                <CityPerformanceTableRow
                  key={stretchId}
                  eachStretch={eachStretch}
                  eachStretchAccOts={roadStretchAccOtsMap[stretchId]}
                  eachStretchActiveMediaCnt={
                    roadStretchToActiveMediaCount[stretchId]
                  }
                  eachStretchPlanInfo={roadStretchOtsMap[stretchId]}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StretchesAndMediasPerformanceTable;
