import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getCampaignMedia } from "../../../actions/campaign-media/CampaignMediaActions";

// Constants and utils
import { defaultPagination } from "../../../constants/UrlConstants";
import { CampaignState } from "./planningConstants";
import { formatText } from "../../../common-utils/string-utils/StringUtils";
import { getCitySpecificMediaList } from "../media-selection/MediaContainerUtil";

//  Components
import TableHeaders from "../../../components/table/TableHeaders";
import { LtsCell } from "../../../components/campaign-media-table-row/MediaRow";
import {
  MediaInfo,
  TgSpecificOts,
  GenericOts,
  MediaDuration,
  SetUpMonitoring,
  MonitoringScheduled,
  MonitoringLive,
  MonitoringComplete,
} from "./MediaRow";

function getEstTableHeaders() {
  return [
    {
      title: {
        displayName: "Site Name",
        className: "col-3",
      },
    },

    {
      element: (
        <th className="col-2">
          <p className="mb-1">Zone</p>
          <p className="mb-1">Area</p>
          <p className="mb-0">Route</p>
        </th>
      ),
    },
    {
      title: {
        displayName: "Road Stretch",
        className: "col-1 text-right",
      },
    },

    {
      title: {
        displayName: "Start and End Date",
        className: "col-2 text-right",
      },
      subTitle: {
        displayName: "Duration",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Target Imp",
        className: "col-2 text-right",
      },
    },
    {
      title: {
        displayName: "Est General Imp",
        className: "col-1 text-right",
      },
    },
    {
      title: {
        displayName: "LTS",
        className: "col-1 text-right",
      },
    },
  ];
}

function getConfirmedTableHeaders() {
  return [
    {
      title: {
        displayName: "Site Name",
        className: "col-3",
      },
    },
    {
      element: (
        <th className="col-2">
          <p className="mb-1">{"Zone"}</p>
          <p className="mb-1">{"Area"}</p>
          <p className="mb-0">{"Route"}</p>
        </th>
      ),
    },

    {
      title: {
        displayName: "Road Stretch",
        className: "align-top text-right",
      },
    },

    {
      title: {
        displayName: "Start and End Date",
        className: "text-right",
      },
      subTitle: {
        displayName: "Duration",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Target Imp",
        className: "text-right align-top",
      },
    },
    {
      title: {
        displayName: "Est General Imp",
        className: "text-right align-top",
      },
    },
    {
      title: {
        displayName: "LTS",
        className: "align-top text-right",
      },
    },

    // {
    //   title: {
    //     displayName: "Monitoring",
    //     className: "align-top text-right",
    //   },
    // },
  ];
}

function getLiveTableHeaders() {
  return [
    {
      title: {
        displayName: "Site Name",
        className: "col-3",
      },
    },
    {
      element: (
        <th className="col-2">
          <p className="mb-0">Zone</p>
          <p className="mb-0">Area</p>
          <p className="mb-0">Route</p>
        </th>
      ),
    },
    {
      title: {
        displayName: "Road Stretch",
        className: "col-1 text-right align-top ",
      },
    },
    {
      title: {
        displayName: "Start and End Date",
        className: "text-right align-top",
      },
      subTitle: {
        displayName: "Duration",
        className: "sub-text",
      },
    },

    {
      title: {
        displayName: "Live Target Imp",
        className: "text-right align-top",
      },
      subTitle: {
        displayName: "Est Target Imp",
        className: "sub-text",
      },
    },

    {
      title: {
        displayName: "Live General Imp",
        className: "align-top text-right",
      },
      subTitle: {
        displayName: "Est General Imp",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "LTS",
        className: "text-right align-top",
      },
    },

    // {
    //   title: {
    //     displayName: "Monitoring",
    //     className: "text-right align-top",
    //   },
    //   subTitle: {
    //     displayName: "Last updated",
    //     className: "sub-text",
    //   },
    // },
  ];
}

function getFinishedTableHeaders() {
  return [
    {
      title: {
        displayName: "Site Name",
        className: "col-3",
      },
    },

    {
      title: {
        displayName: "Road Stretch",
        className: "align-top text-right",
      },
    },

    {
      title: {
        displayName: "Start and End Date",
        className: "text-right",
      },
      subTitle: {
        displayName: "Duration",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Target Imp",
        className: "text-right align-top",
      },
    },
    {
      title: {
        displayName: "Est General Imp",
        className: "text-right align-top",
      },
    },
    {
      title: {
        displayName: "LTS",
        className: "align-top text-right",
      },
    },

    // {
    //   title: {
    //     displayName: "Monitoring",
    //     className: "align-top text-right",
    //   },
    // },
  ];
}

function getTableHeaders(planStatus) {
  // if (planStatus === CampaignState.LIVE) {
  //   return getLiveTableHeaders();
  // }
  // if (planStatus === CampaignState.COMPLETED) {
  //   return getFinishedTableHeaders();
  // }
  // if (
  //   planStatus === CampaignState.CONFIRMED ||
  //   planStatus === CampaignState.SCHEDULED
  // ) {
  //   return getConfirmedTableHeaders();
  // }
  // return getEstTableHeaders();

  const isDraftCampaign = planStatus === CampaignState.DRAFT;
  const isReviewCampaign = planStatus === CampaignState.REVIEW; // send for confirmation
  const isDraftOrReviewCampaign = isDraftCampaign || isReviewCampaign;

  // DRAFT & REVIEW
  if (isDraftOrReviewCampaign) {
    return getEstTableHeaders();
  }

  // others
  return getLiveTableHeaders();
}

export function RoadStretchInfo({ stretchInfo = {} }) {
  return <td className="text-right align-top">{stretchInfo.name}</td>;
}

function ZoneRouteAndAreaInfo({ media }) {
  const { zoneName, region, roadStretchName } = media;
  return (
    <td className="align-top">
      <p className="mb-1" title={zoneName}>
        {formatText(zoneName)}
      </p>
      <p className="mb-1" title={region}>
        {formatText(region)}
      </p>
      <p className="mb-0" title={roadStretchName}>
        {formatText(roadStretchName)}
      </p>
    </td>
  );
}

function MonitorSettings({ media }) {
  const { isMonitoringEnabled } = media;
  if (isMonitoringEnabled) {
    return <MonitoringScheduled media={media} />;
  }
  return <SetUpMonitoring media={media} />;
}

function MediaDetailsRow({
  media = {},
  campaign,
  mediaPerformance,
  stretchInfo = {},
  roadStretchOtsMap = {},
}) {
  const { roadStretchId } = media || {};

  // stretch ots details
  const stretchFromCityPlan = roadStretchOtsMap[roadStretchId];
  const { ots, otsLit, targetOts, targetOtsLit } = stretchFromCityPlan || {};

  // ots values
  const estGeneric = otsLit ?? ots;
  const estTgSpecific = targetOtsLit ?? targetOts;

  // Calculation for Live Impressions
  const { targetOtsServedTotal, genericOtsServedTotal } =
    mediaPerformance || {};

  const { planStatus } = campaign || {};

  const isDraftCampaign = planStatus === CampaignState.DRAFT;
  const isReviewCampaign = planStatus === CampaignState.REVIEW;

  // hiding live metrics for DRAFT & REVIEW state
  const isDraftOrReviewCampaign = isDraftCampaign || isReviewCampaign;
  const isShowLiveMetrics = !isDraftOrReviewCampaign;

  return (
    <tr>
      <MediaInfo media={media} />
      <ZoneRouteAndAreaInfo media={media} />
      <RoadStretchInfo stretchInfo={stretchInfo} />
      <MediaDuration media={media} />
      <TgSpecificOts
        estTgSpecific={estTgSpecific}
        liveTgSpecific={targetOtsServedTotal}
        isShowLiveMetrics={isShowLiveMetrics}
      />
      <GenericOts
        estGeneric={estGeneric}
        liveGeneric={genericOtsServedTotal}
        isShowLiveMetrics={isShowLiveMetrics}
      />
      <LtsCell ltsElementClass={"text-right align-top"} media={media} />

      {/* Monitoring */}
      {/* {planStatus === CampaignState.SCHEDULED && (
        <MonitoringScheduled media={media} />
      )}
      {planStatus === CampaignState.CONFIRMED && (
        <MonitorSettings media={media} />
      )}
      {planStatus === CampaignState.LIVE && (
        <MonitoringLive media={media} />
      )}
      {planStatus === CampaignState.COMPLETED && (
        <MonitoringComplete media={media} />
      )} */}
    </tr>
  );
}

/**
 * Table
 */
function MediaDetailsTable({
  campaignId,
  campaign,
  campaignPlan,
  campaignMediaOtsLogSummary = {},
  roadStretchMap = {},
}) {
  const dispatch = useDispatch();

  // Selector
  const campaignMedias = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  const { roadStretchOtsMap = {}, cityId } = campaignPlan || {};
  const { planStatus } = campaign || {};

  // city specific medias
  const citySpecificMediaList = getCitySpecificMediaList(
    campaignMedias,
    cityId
  );

  const { pageNo, pageSize } = defaultPagination;
  useEffect(() => {
    dispatch(getCampaignMedia(campaignId, pageNo, pageSize));
  }, [dispatch, campaignId, cityId]);

  if (
    campaignMedias.length < 1 ||
    !campaignPlan ||
    Object.keys(roadStretchMap).length < 1
  ) {
    return null;
  }

  const tableHeaders = getTableHeaders(planStatus);

  return (
    <div className="table-responsive mt-5">
      <h4>{"Media Sites"}</h4>
      <table className="table table-media">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
        <tbody>
          {citySpecificMediaList.map((eachMedia) => (
            <MediaDetailsRow
              key={eachMedia.id}
              media={eachMedia}
              campaign={campaign}
              mediaPerformance={campaignMediaOtsLogSummary[eachMedia.id] || {}}
              stretchInfo={roadStretchMap[eachMedia.roadStretchId]}
              roadStretchOtsMap={roadStretchOtsMap}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MediaDetailsTable;
