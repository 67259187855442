import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

//Apis
import {
  getTgOtsOfRoadStretchesOfCity,
  getTgSpecificOtsData,
  getTgSpecificOtsForSegmentFn,
} from "../../utils/api/campaignPlanning";

// Constants and Utils
import { ActionTypes } from "../../constants/ActionConstants";
import { getErrorMessage } from "../../utils/util";

/**
 *
 * @param {*} tgId
 * @param {*} segmentIds
 * @returns All Segments TargetGroup OTS map
 */
function* getAllSegmentsTgOtsMap(tgId, segmentIds) {
  const allSegmentsTgOtsMap = {};
  for (let i = 0; i < segmentIds.length; i++) {
    const segmentId = segmentIds[i];

    // calling API to get each road-segment TgSpecific ots
    const response = yield getTgSpecificOtsForSegmentFn(tgId, segmentId);

    // Storing as responseMap ==> {segmentId: {splitted ots}, segmentId: {splitted ots}}
    allSegmentsTgOtsMap[segmentId] = {
      genericOts: response.genericOts,
      genericOtsLit: response.genericOtsLit,
      targetOts: response.targetOts,
      targetOtsLit: response.targetOtsLit,
      otsLit: response.otsLit,
      ots: response.ots,
    };
  }
  return allSegmentsTgOtsMap;
}

export function* getTgSpecificOts(action) {
  const { tgId, stretchIds, segmentIds, mediaIds } = action.payload;

  try {
    const tgOtsMap = yield getTgSpecificOtsData(
      tgId,
      stretchIds,
      segmentIds,
      mediaIds
    );

    yield put({
      type: ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_SUCCESS,
      tgOtsMap: tgOtsMap,
      tgId: tgId,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getTgSpecificOtsForCity(action) {
  const { tgId, cityId } = action.payload;
  try {
    const stretchesTgOtsMap = yield getTgOtsOfRoadStretchesOfCity(tgId, cityId);

    yield put({
      type: ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_CITY_SUCCESS,
      tgOtsMap: stretchesTgOtsMap,
      tgId: tgId,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_CITY_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getTgSpecificOtsForSegments(action) {
  const { tgId, segmentIds } = action.payload;

  try {
    // looping over all segmentIds to get TgOtsMap
    const allSegmentsTgOtsMap = yield getAllSegmentsTgOtsMap(tgId, segmentIds);

    yield put({
      type: ActionTypes.CampaignPlanner
        .GET_TG_SPECIFIC_OTS_FOR_SEGMENTS_SUCCESS,
      payload: { allSegmentsTgOtsMap },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPlanner
        .GET_TG_SPECIFIC_OTS_FOR_SEGMENTS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS,
      getTgSpecificOts
    ),
    takeLatest(
      ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_CITY,
      getTgSpecificOtsForCity
    ),
    takeLatest(
      ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_SEGMENTS,
      getTgSpecificOtsForSegments
    ),
  ]);
}
