import { toLocaleString } from "../string-utils/StringUtils";

export function roundUpNearestInteger(number) {
  return Math.round(number);
}

export function toStringWithRoundUp(number) {
  return toLocaleString(roundUpNearestInteger(number));
}

// calculates percentage and returns percentage fixed to two decimal places
export function calculatePercentage(numerator, denominator) {
  if (denominator === 0 || denominator === "") {
    return 0;
  }

  return ((numerator / denominator) * 100).toFixed(2);
}

// to fixed (only when number is decimal)
export function toFixedIfDecimalNumber(number, decimalCount = 2) {
  const toFixedValue = Number(
    number.toFixed(decimalCount).replace(/\.00$/, "")
  );

  return toFixedValue;
}
