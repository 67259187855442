import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { getCampaignPlan } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getCampaignPerformanceByCityId } from "../../actions/campaign-performance/CampaignPerformanceActions";

// Constants and Utils
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import { getRoadStretchesByCityId } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import CityViewCards from "../components/CityViewCards";
import MapView from "../components/map-view/MapView";
import { CampaignDetails } from "../components/HeaderNav";
import MediaDetailsTable from "../components/MediaDetailsTable";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import StretchesAndMediasPerformanceTable from "../components/StretchesAndMediasPerformanceTable";

/**
 * Page
 */
export default function CampaignLivePage() {
  const dispatch = useDispatch();
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { targetGroupId } = campaignPlan || {};

  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Campaign Media
  const campaignMedia = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // live ots
  const campaignMediaOtsLogSummary = useSelector(
    (state) => state.campaignPerformance.campaignMediaOtsLogSummary
  );

  // City road stretches
  const roadStretchMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  useEffect(() => {
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getCampaignPerformanceByCityId(campaignId, cityId));
    dispatch(getRoadStretchesByCityId(cityId, ""));
  }, [dispatch, campaignId, cityId]);

  // get tg info
  useGetTgInfo(targetGroupId);

  // Sidebar Settings
  const citySelected = { cityId };
  const redirectLinkObj = {
    campaignOverviewLink: `/campaign/${campaignId}/live`,
    citySummaryLink: `/campaign/${campaignId}/city/:cityId/live`,
  };

  // Page Title
  const pageTitle = <CampaignDetails campaign={campaign} />;

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar
        campaign={campaign}
        redirectLinkObj={redirectLinkObj}
        citySelected={citySelected}
      />

      <div className="content-wrapper">
        <PageHeader title={pageTitle} shadow={true} />

        {/* Page-Content */}
        <div className="page-content">
          <CityViewCards
            tgName={tgName}
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedia={campaignMedia}
          />

          {/* Stretches And Medias Performance Table */}
          <StretchesAndMediasPerformanceTable
            campaignPlan={campaignPlan}
            campaignMedia={campaignMedia}
            roadStretchMap={roadStretchMap}
            campaignMediaOtsLogSummary={campaignMediaOtsLogSummary}
          />

          {/* Map View */}
          <MapView
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />

          {/* Media Sites Table */}
          <MediaDetailsTable
            campaignId={campaignId}
            campaign={campaign}
            campaignPlan={campaignPlan}
            campaignMediaOtsLogSummary={campaignMediaOtsLogSummary}
            roadStretchMap={roadStretchMap}
          />
        </div>
      </div>
    </>
  );
}
