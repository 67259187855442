import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { removeTgHeatMapData } from "../../../actions/geo-data/GeoDataActions";
import { getRoadStretchesByCityId } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";
import { manageTgAndGetTgOtsForCityStretches } from "../../actions/campaign-planning/CampaignPlanningActions";

// Constants and Utils
import { MediaTypes } from "./TabConstants";
import { extractSubZoneIds } from "../../utils/campaignPlanningUtil";

// Tabs
import TargetGroupTab from "./TargetGroupsTab";
import ZonesTab from "./ZonesTab";
import MediaTypesTab from "./MediaTypesTab";
import ArterialRouteTab from "./ArterialRouteTab";
import PointsOfInterestTab from "./PointsOfInterestTab";
import TouchPointsTab from "./TouchPointsTab";

// Page Functions
function getTabConstants(
  isTgApplied,
  isZoneAndSubZoneApplied,
  isMediaTypeApplied,
  isArterialRouteApplied,
  isPoiSelected
) {
  // constants
  return [
    {
      isContentApplied: isTgApplied,
      content: (
        <>
          <i className="fas fa-users my-2 fa-2x opacity-75"></i>
          <p className="pb-0">Target Group</p>
        </>
      ),
    },
    {
      isContentApplied: isZoneAndSubZoneApplied,
      content: (
        <>
          <i className="fas fa-border-all my-2 fa-2x opacity-75"></i>
          <p className="pb-0">Zone</p>
        </>
      ),
    },
    {
      isContentApplied: isMediaTypeApplied,
      content: (
        <>
          <i className="fas fa-image my-2 fa-2x opacity-75"></i>
          <p className="pb-0">Media Type</p>
        </>
      ),
    },
    {
      isContentApplied: isArterialRouteApplied,
      content: (
        <>
          <i className="fas fa-border-none my-2 fa-2x opacity-75"></i>
          <p className="pb-0">Arterial Route</p>
        </>
      ),
    },
    {
      isContentApplied: isPoiSelected,
      content: (
        <>
          <i className="fas fa-map-marker-alt my-2 fa-2x opacity-75"></i>
          <p className="pb-0">Points of Interest</p>
        </>
      ),
    },
    {
      isContentApplied: "",
      content: (
        <>
          <i className="fas fa-hand-pointer my-2 fa-2x opacity-75"></i>
          <p className="pb-0">Touch Point</p>
        </>
      ),
    },
  ];
}

// Selected Tab Content
function SelectedContent({
  cityId,
  cityBBox,
  selectedTab,
  setSelectedTab = () => {},
}) {
  const dispatch = useDispatch();

  // Road Stretches Loading"
  const roadStretchesLoading = useSelector(
    (state) => state.planningRoadStretches.loading
  );

  // create and update tg, get heatmap, get tgOts for city stretches
  const manageTgAndGetOtsForCityStretchesLoading = useSelector(
    (state) => state.campaignPlanning.manageTgAndGetOtsForCityStretchesLoading
  );

  // Selected Media types
  const selectedMediaTypesMap = useSelector(
    (state) => state.campaignPlanning.selectedMediaTypes
  );
  const selectedMediaTypes = MediaTypes.filter(
    (mediaType) => selectedMediaTypesMap[mediaType]
  );

  // Selected Route Types
  const selectedArterialRoutesMap = useSelector(
    (state) => state.orgRouteTypes.selectedArterialRoutes
  );
  const selectedArterialRoutes = Object.keys(selectedArterialRoutesMap);

  // Selected Zones And SubZones Map
  const selectedZonesAndSubZonesMap = useSelector(
    (state) => state.zonesAndSubZones.selectedZonesAndSubZonesMap
  );
  const selectedSubZones = extractSubZoneIds(selectedZonesAndSubZonesMap);

  // handle Filter Stretches
  function handleFilterStretches() {
    dispatch(
      getRoadStretchesByCityId(
        cityId,
        "",
        selectedArterialRoutes,
        selectedMediaTypes,
        selectedSubZones,
        setSelectedTab // optional key for closing tab
      )
    );
  }

  // handle Generate Heatmap
  function handleGenerateHeatmap(resPoiLayers, tgBean) {
    dispatch(removeTgHeatMapData());

    // create and update tg, get heatmap, get tgOts for city stretches
    dispatch(
      manageTgAndGetTgOtsForCityStretches(
        tgBean,
        resPoiLayers,
        cityId,
        cityBBox,
        setSelectedTab // optional key for closing tab
      )
    );
  }

  switch (selectedTab) {
    case 1:
      return (
        <TargetGroupTab
          handleTabHeaderBtnClick={handleGenerateHeatmap}
          tabHeaderBtnLoading={manageTgAndGetOtsForCityStretchesLoading}
        />
      );
    case 2:
      return (
        <ZonesTab
          selectedCityId={cityId}
          handleTabHeaderBtnClick={handleFilterStretches}
          tabHeaderBtnLoading={roadStretchesLoading}
        />
      );
    case 3:
      return (
        <MediaTypesTab
          handleTabHeaderBtnClick={handleFilterStretches}
          tabHeaderBtnLoading={roadStretchesLoading}
        />
      );
    case 4:
      return (
        <ArterialRouteTab
          handleTabHeaderBtnClick={handleFilterStretches}
          tabHeaderBtnLoading={roadStretchesLoading}
        />
      );
    case 5:
      return <PointsOfInterestTab cityId={cityId} cityBBox={cityBBox} />;
    case 6:
      return <TouchPointsTab />;

    default:
      return null;
  }
}

export default function PlanningMapTabsSection({ cityId, cityBBox }) {
  // State for Opened Tab
  const [selectedTab, setSelectedTab] = useState(0);

  // selected items from tabs contents
  const selectedZonesAndSubZonesMap = useSelector(
    (state) => state.zonesAndSubZones.selectedZonesAndSubZonesMap
  );

  // selected map
  const selectedArterialRoutesMap = useSelector(
    (state) => state.orgRouteTypes.selectedArterialRoutes
  );
  const selectedArterialRoutes = Object.keys(selectedArterialRoutesMap);

  const selectedMediaTypesMap = useSelector(
    (state) => state.campaignPlanning.selectedMediaTypes
  );
  const selectedMediaTypes = MediaTypes.filter(
    (type) => selectedMediaTypesMap[type]
  );

  const selectedTgAttributesMap = useSelector(
    (state) => state.campaignPlanning.selectedTgAttributesMap
  );
  const brandIds = useSelector((state) => state.poiSelection.brandIds) || {};

  const isTgApplied = Object.keys(selectedTgAttributesMap).length > 0;
  const isZoneAndSubZoneApplied =
    Object.keys(selectedZonesAndSubZonesMap).length > 0;
  const isMediaTypeApplied = selectedMediaTypes.length > 0;
  const isArterialRouteApplied = selectedArterialRoutes.length > 0;
  const isPoiSelected = Object.keys(brandIds).length > 0;

  // tab constants
  const tabConstants = getTabConstants(
    isTgApplied,
    isZoneAndSubZoneApplied,
    isMediaTypeApplied,
    isArterialRouteApplied,
    isPoiSelected
  );

  // tab click
  function tabOnClick(index) {
    const isSameTabClicked = selectedTab === index;
    const newTabIndex = isSameTabClicked ? 0 : index;

    // update state
    setSelectedTab(newTabIndex);
  }

  return (
    <div className="toohl-map-tabs-container">
      <div className="tab-items">
        {tabConstants.map((eachTab, index) => {
          const { isContentApplied = false, content } = eachTab;
          const tabClassName = isContentApplied
            ? "text-success"
            : "text-primary";
          return (
            <div
              key={index}
              className={`tab-item ${tabClassName} ${
                selectedTab === index + 1 ? "active" : ""
              }`}
              onClick={() => tabOnClick(index + 1)}
              disabled={!cityId}
            >
              {content}
            </div>
          );
        })}
      </div>
      {!!selectedTab && (
        <div className="tab-cont bg-white">
          <SelectedContent
            cityId={cityId}
            cityBBox={cityBBox}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      )}
    </div>
  );
}
