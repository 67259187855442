import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  setTitle,
  updateCampaignDates,
} from "../../actions/campaign-planning/CampaignPlanningActions";
import { openSingleCitySelectionForm } from "../../../actions/SingleCitySelectionFormActions";
import {
  getRoadStretchesByCityId,
  resetRoadStretches,
} from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";
import {
  selectSubZone,
  unSelectSubZone,
} from "../../actions/campaign-planning/ZonesAndSubZonesActions";

// Constants and Utils
import {
  getDifferenceInDays,
  getTimestamp,
} from "../../../common-utils/date-utils/DateUtils";
import {
  DATE_FORMATS,
  FormDataTargets,
} from "../../../constants/GeneralConstants";
import { MediaTypes } from "./TabConstants";

// Components
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import TitleEditor from "../../../components/title-editor/TitleEditor";
import { DropdownWithCheckboxes } from "../../../components/dropdown/Dropdown";

// Page Functions
function constructFrequentlyOptedLocations(frequentlyOptedLocations = []) {
  if (frequentlyOptedLocations.length === 0) {
    return [];
  }

  const constructedFOL = frequentlyOptedLocations.map((location) => {
    const { zoneId, zoneName = "", percentage } = location || {};
    return {
      id: zoneId,
      label: `${zoneName}-(${percentage}%)`,
      name: zoneName,
    };
  });

  return constructedFOL;
}

function getInitialDateAndDuration(startTimestamp, endTimestamp) {
  if (!startTimestamp && !endTimestamp) {
    return { startDateObj: "", duration: "", endDateObj: "" };
  }

  const duration = getDifferenceInDays(startTimestamp, endTimestamp);

  return {
    startDateObj: new Date(startTimestamp),
    endDateObj: new Date(endTimestamp),
    duration,
  };
}

// Frequently Opted Locations
function FrequentlyOptedLocations({ regionsData = {} }) {
  const dispatch = useDispatch();

  // State
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSubZoneSelectionDispatched, setIsSubZoneSelectionDispatched] =
    useState(false);

  // data
  const { id: selectedCityId, name } = regionsData || {};

  // Freq Opt Locations
  const frequentlyOptedLocations = useSelector(
    (state) => state.zonesAndSubZones.frequentlyOptedLocations
  );
  const constructedFreqOptLocations = constructFrequentlyOptedLocations(
    frequentlyOptedLocations
  );

  // Selected ZOnes & SubZones Map
  const selectedZonesAndSubZonesMap = useSelector(
    (state) => state.zonesAndSubZones.selectedZonesAndSubZonesMap
  );

  const cityZonesAndSubZonesData =
    selectedZonesAndSubZonesMap[selectedCityId] || [];

  const selectedSubZonesIds = cityZonesAndSubZonesData.map(
    (subZone) => subZone.subZoneId
  );

  // Selected Media types
  const selectedMediaTypesMap = useSelector(
    (state) => state.campaignPlanning.selectedMediaTypes
  );
  const selectedMediaTypes = MediaTypes.filter(
    (mediaType) => selectedMediaTypesMap[mediaType]
  );

  // Selected Route Types
  const selectedArterialRoutesMap = useSelector(
    (state) => state.orgRouteTypes.selectedArterialRoutes
  );
  const selectedArterialRoutes = Object.keys(selectedArterialRoutesMap);

  useEffect(() => {
    if (isSubZoneSelectionDispatched) {
      dispatch(
        getRoadStretchesByCityId(
          selectedCityId,
          "",
          selectedArterialRoutes,
          selectedMediaTypes,
          selectedSubZonesIds
        )
      );
      setIsSubZoneSelectionDispatched(false);
    }
  }, [isSubZoneSelectionDispatched, dispatch]);

  function onOptionSelection(selectedSubZone = {}) {
    const updatedSelectedSubZone = {
      zoneId: selectedCityId,
      zoneName: name,
      subZoneId: selectedSubZone.id,
      subZoneName: selectedSubZone.name,
    };

    if (Object.keys(updatedSelectedSubZone.length > 0)) {
      const isSelectedZone = selectedSubZonesIds.includes(
        updatedSelectedSubZone.subZoneId
      );

      const dispatchFn = isSelectedZone ? unSelectSubZone : selectSubZone;
      dispatch(dispatchFn(updatedSelectedSubZone));
      setIsSubZoneSelectionDispatched(true);
    }
  }

  function handleCheckboxChange(option) {
    const updatedSelections = [...selectedItems];

    if (selectedItems.some((item) => item.id === option.id)) {
      // If already selected, remove it
      const index = updatedSelections.findIndex(
        (item) => item.id === option.id
      );
      updatedSelections.splice(index, 1);
    } else {
      // If not selected, add it
      updatedSelections.push(option);
    }

    setSelectedItems(updatedSelections);
    onOptionSelection(option);
  }

  return (
    <div className="mx-2">
      <p className="mb-0">Freq. Opt. Locations</p>
      <DropdownWithCheckboxes
        options={constructedFreqOptLocations}
        placeHolder="Select Area"
        dropMenuClassName="dropdown-fixed-height min-w-200"
        boxStyle="d-flex align-items-center"
        handleCheckboxChange={handleCheckboxChange}
        selectedItems={selectedSubZonesIds}
      />
    </div>
  );
}

// Date And Duration Section
function DateAndDurationSection() {
  const dispatch = useDispatch();

  // Selector
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { startTimestamp, endTimestamp } = campaignPlan || {};
  const {
    startDateObj,
    endDateObj,
    duration: planDuration,
  } = getInitialDateAndDuration(startTimestamp, endTimestamp);

  // State
  const [duration, setDuration] = useState(planDuration);

  // DatePicker Style
  const styleObject = {
    autoApply: true,
    border: true,
    buttonClassName: "min-w-150 px-0",
  };

  // duration string
  const durationString = duration ? `(${duration} Days)` : "";

  // Functions
  function onDateSelect(selectedDate) {
    const { startDate, endDate } = selectedDate || {};
    const startTs = getTimestamp(startDate, DATE_FORMATS.date_month_year);
    const endTs = getTimestamp(endDate, DATE_FORMATS.date_month_year);
    const dayCount = getDifferenceInDays(startTs, endTs) - 1;
    setDuration(dayCount);
    dispatch(updateCampaignDates({ startDate, endDate }, dayCount));
  }

  return (
    <div className="d-flex">
      <div>
        <p className="mb-0">{`Start Date ~ End Date ${durationString}`}</p>
        <BootstrapDateRangePicker
          initialStartDate={startDateObj}
          initialEndDate={endDateObj}
          onApplyDates={onDateSelect}
          styleObject={styleObject}
          // minDate={new Date()}
        />
      </div>
    </div>
  );
}
function SingleCitySelectionSelector() {
  const dispatch = useDispatch();

  const regionsData = useSelector(
    (state) => state.campaignPlanning.regionsData
  );
  const { name: selectedCityName } = regionsData || {};

  return (
    <div>
      <p className="mb-0">Select City</p>
      <button
        type="button"
        className="btn border rounded-lg shadow-none p-2 dropdown-toggle"
        data-toggle="modal"
        data-target={`#${FormDataTargets.singleCitySelectionForm}`}
        onClick={() => dispatch(openSingleCitySelectionForm())}
      >
        {selectedCityName ? selectedCityName : "Select City"}
      </button>
    </div>
  );
}

/**
 * Section
 */
function PlanningHeaderSection({ regionsData = {} }) {
  const dispatch = useDispatch();

  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);
  const { title } = campaign || {};

  const campaignPlanTitle = useSelector(
    (state) => state.campaignPlanning.campaignTitle
  );

  const campaignTitle = campaignPlanTitle ? campaignPlanTitle : title;

  return (
    <>
      <div className="d-flex align-items-center m-3">
        <TitleEditor
          title={campaignTitle}
          onTitleChange={(titleStr) => dispatch(setTitle(titleStr))}
          inputBoxClass="rounded-lg"
          titleClass="px-0 mb-0 h3"
        />
      </div>
      <hr className="my-0"></hr>
      <div className="d-flex align-items-center justify-content-between px-3 py-2">
        <SingleCitySelectionSelector />
        <FrequentlyOptedLocations regionsData={regionsData} />
        <DateAndDurationSection />
      </div>
      <hr className="my-0"></hr>
    </>
  );
}

export default PlanningHeaderSection;
