import { ActionTypes } from "../../../constants/ActionConstants";

export const getCampaignMedia = (campaignId, pageNo, pageSize) => ({
  type: ActionTypes.MediaSelection.GET_CAMPAIGN_MEDIA,
  payload: { campaignId, pageNo, pageSize },
});

export const selectMedia = (campaignId, cityId, campaignMediaBean) => ({
  type: ActionTypes.MediaSelection.SELECT_MEDIA,
  payload: { campaignId, cityId, campaignMediaBean },
});

export const unSelectMedia = (campaignId, campaignMedia, mediaId) => ({
  type: ActionTypes.MediaSelection.UN_SELECT_MEDIA,
  payload: { campaignId, campaignMedia, mediaId },
});

export const confirmCampaignPlan = (campaignId, campaignName, history) => ({
  type: ActionTypes.MediaSelection.CONFIRM_CAMPAIGN_PLAN,
  payload: { campaignId, campaignName, history },
});

export const resetMediaSelection = () => ({
  type: ActionTypes.CampaignConfirmation.RESET_CAMPAIGN_CONFIRMATION,
});

//Create new campaign media
export const createMedia = (
  createMediaBean,
  imageFiles,
  campaignId,
  cityId
) => ({
  type: ActionTypes.MediaSelection.CREATE_CAMPAIGN_MEDIA,
  payload: { createMediaBean, imageFiles, campaignId, cityId },
});
