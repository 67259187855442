import LLHeatLayer from "../leaflet-heat-layer/LLHeatLayer";

/**
 * @param {*} allPoiPointsArr --> input of all points for "PoiTypeLayers"
 * @param {*} hmKey --> key to re-render "LLHeatLayer" component
 * @returns the HeatMapLayer for TargetGroup.
 */
function TargetGroupHeatmap({ hmkey, allPoiPointsArr = [] }) {
  const hmKeyForTg = hmkey ? hmkey : "target-group";

  if (allPoiPointsArr.length < 1) {
    return <LLHeatLayer />;
  }

  // calculating radius based on allPoiPointsArr
  const hmRadius =
    allPoiPointsArr.length > 10000
      ? 25
      : Math.abs(Math.round(100 - allPoiPointsArr.length / 100) - 20);

  const hmBlur = hmRadius > 50 ? 30 : 40;
  const options = {
    radius: hmRadius,
    blur: hmBlur,
    gradient: {
      0.3: "#800080",
      0.6: "#FF69B4",
      0.8: "#FFD700",
      1: "#800000",
    },
  };
  return (
    <LLHeatLayer key={hmKeyForTg} points={allPoiPointsArr} options={options} />
  );
}

export default TargetGroupHeatmap;
