export const RoadStretch = {
  GET_CITY_ROAD_STRETCHES: "GET_CITY_ROAD_STRETCHES",
  GET_CITY_ROAD_STRETCHES_SUCCESS: "GET_CITY_ROAD_STRETCHES_SUCCESS",
  GET_CITY_ROAD_STRETCHES_FAILURE: "GET_CITY_ROAD_STRETCHES_FAILURE",

  // Get Road Stretches by Stretch Ids Actions
  GET_ROAD_STRETCHES_BY_IDS: "GET_ROAD_STRETCHES_BY_IDS",
  GET_ROAD_STRETCHES_BY_IDS_SUCCESS: "GET_ROAD_STRETCHES_BY_IDS_SUCCESS",
  GET_ROAD_STRETCHES_BY_IDS_FAILURE: "GET_ROAD_STRETCHES_BY_IDS_FAILURE",

  // Get Road Stretches by Org Id
  GET_ROAD_STRETCHES_OF_ORG: "GET_ROAD_STRETCHES_OF_ORG",
  GET_ROAD_STRETCHES_OF_ORG_SUCCESS: "GET_ROAD_STRETCHES_OF_ORG_SUCCESS",
  GET_ROAD_STRETCHES_OF_ORG_FAILURE: "GET_ROAD_STRETCHES_OF_ORG_FAILURE",

  // get Road Stretch information by ID
  GET_ROAD_STRETCH_INFO_BY_ID: "GET_ROAD_STRETCH_INFO_BY_ID",
  GET_ROAD_STRETCH_INFO_BY_ID_SUCCESS: "GET_ROAD_STRETCH_INFO_BY_ID_SUCCESS",
  GET_ROAD_STRETCH_INFO_BY_ID_FAILURE: "GET_ROAD_STRETCH_INFO_BY_ID_FAILURE",

  // add/remove selected road-stretch
  ADD_SELECTED_ROAD_STRETCH: "ADD_SELECTED_ROAD_STRETCH",
  REMOVE_SELECTED_ROAD_STRETCH: "REMOVE_SELECTED_ROAD_STRETCH",

  // clear Selected Road-Stretch-Data from MapView
  CLEAR_ROAD_STRETCH_DATA: "CLEAR_ROAD_STRETCH_DATA",

  // Archive and Restore Stretch
  ARCHIVE_ROAD_STRETCH: "ARCHIVE_ROAD_STRETCH",
  ARCHIVE_ROAD_STRETCH_SUCCESS: "ARCHIVE_ROAD_STRETCH_SUCCESS",
  ARCHIVE_ROAD_STRETCH_FAILURE: "ARCHIVE_ROAD_STRETCH_FAILURE",

  // Refresh Pricing
  ROAD_STRETCH_PRICE_REFRESH: "ROAD_STRETCH_PRICE_REFRESH",
  ROAD_STRETCH_PRICE_REFRESH_SUCCESS: "ROAD_STRETCH_PRICE_REFRESH_SUCCESS",
  ROAD_STRETCH_PRICE_REFRESH_FAILURE: "ROAD_STRETCH_PRICE_REFRESH_FAILURE",

  RESTORE_ROAD_STRETCH: "RESTORE_ROAD_STRETCH",
  RESTORE_ROAD_STRETCH_SUCCESS: "RESTORE_ROAD_STRETCH_SUCCESS",
  RESTORE_ROAD_STRETCH_FAILURE: "RESTORE_ROAD_STRETCH_FAILURE",

  UPDATE_ROAD_STRETCH_STATUS: "UPDATE_ROAD_STRETCH_STATUS",

  // Actions Related to Road Stretch Selection
  SELECT_ROAD_STRETCH: "SELECT_ROAD_STRETCH",
  SELECT_ROAD_STRETCHS: "SELECT_ROAD_STRETCHS",
  UN_SELECT_ROAD_STRETCH: "UN_SELECT_ROAD_STRETCH",

  // Reset
  RESET_ROAD_STRETCHES: "RESET_ROAD_STRETCHES",

  // Rest Selected Road Stretches
  RESET_SELECTED_ROAD_STRETCHES_IN_MAP_VIEW:
    "RESET_SELECTED_ROAD_STRETCHES_IN_MAP_VIEW",

  // to Add All Selected Road-Stretches while "restoring-save-map-state"
  ADD_ALL_SELECTED_ROAD_STRETCHES: "ADD_ALL_SELECTED_ROAD_STRETCHES",

  // Road stretch meta data form
  OPEN_ROAD_STRETCH_META_DATA_UPDATE_FORM:
    "OPEN_ROAD_STRETCH_META__DATA_UPDATE_FORM",
  CLOSE_ROAD_STRETCH_META_DATA_UPDATE_FORM:
    "CLOSE__ROAD_STRETCH_META__DATA_UPDATE_FORM",

  // Update road stretch meta data
  UPDATE_ROAD_STRETCH_META_DATA: "UPDATE_ROAD_STRETCH_META_DATA",
  UPDATE_ROAD_STRETCH_META_DATA_SUCCESS:
    "UPDATE_ROAD_STRETCH_META_DATA_SUCCESS",
  UPDATE_ROAD_STRETCH_META_DATA_FAILURE:
    "UPDATE_ROAD_STRETCH_META_DATA_FAILURE",

  // Update road stretch
  UPDATE_ROAD_STRETCH: "UPDATE_ROAD_STRETCH",
  UPDATE_ROAD_STRETCH_SUCCESS: "UPDATE_ROAD_STRETCH_SUCCESS",
  UPDATE_ROAD_STRETCH_FAILURE: "UPDATE_ROAD_STRETCH_FAILURE",
};

export const CreateNewRoadStretches = {
  // for only "single-latLongString"
  GET_CLOSEST_ROAD_SG: "GET_CLOSEST_ROAD_SG_NEW_ROAD_STRETCH",
  GET_CLOSEST_ROAD_SG_SUCCESS: "GET_CLOSEST_ROAD_SG_SUCCESS_NEW_ROAD_STRETCH",
  GET_CLOSEST_ROAD_SG_FAILURE: "GET_CLOSEST_ROAD_SG_FAILURE_NEW_ROAD_STRETCH",

  // to clear closest road-segments
  RESET_CLOSEST_ROAD_SG: "RESET_CLOSEST_ROAD_SG",

  // select the segments for creating the stretch
  ADD_ROAD_SEGMENT: "ADD_ROAD_SEGMENT_NEW_ROAD_STRETCH",
  REMOVE_ROAD_SEGMENT: "REMOVE_ROAD_SEGMENT_NEW_ROAD_STRETCH",

  // Create Road Stretch
  CREATE_ROAD_STRETCH: "CREATE_ROAD_STRETCH",
  CREATE_ROAD_STRETCH_SUCCESS: "CREATE_ROAD_STRETCH_SUCCESS",
  CREATE_ROAD_STRETCH_FAILURE: "CREATE_ROAD_STRETCH_FAILURE",

  // Clear selections
  RESET: "RESET_ROAD_STRETCH_SELECTIONS",
};
