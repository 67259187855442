import { ActionTypes } from "../../../constants/ActionConstants";

export const openPoiSelectionForm = () => ({
  type: ActionTypes.PoiSelectionForm.OPEN_POI_SELECTION_MODAL,
});

export const closePoiSelectionForm = () => ({
  type: ActionTypes.PoiSelectionForm.CLOSE_POI_SELECTION_MODAL,
});

export const getSelectedPoiInfo = (cityId, poiBrandIds, bbox) => ({
  type: ActionTypes.PoiSelectionForm.GET_SELECTED_POI_INFO,
  payload: { cityId, poiBrandIds, bbox },
});
