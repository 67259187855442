import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { toggleMediaType } from "../../actions/campaign-planning/CampaignPlanningActions";

// Constants and Utils
import { MediaTypes } from "./TabConstants";

// Components
import {
  ArterialRouteAndMediaTypeCheckboxList,
  HeaderWithActionButton,
} from "./PlanningUtils";

// Media Types Tab
export default function MediaTypesTab({
  handleTabHeaderBtnClick = () => {},
  tabHeaderBtnLoading = false,
}) {
  const dispatch = useDispatch();

  // Selected Media Types Map
  const selectedMediaTypesMap = useSelector(
    (state) => state.campaignPlanning.selectedMediaTypes
  );

  function handleCheckboxChange(mediaType) {
    dispatch(toggleMediaType([mediaType]));
  }

  return (
    <>
      <HeaderWithActionButton
        title="Media Facing"
        buttonText="Confirm"
        onButtonClick={handleTabHeaderBtnClick}
        buttonLoading={tabHeaderBtnLoading}
      />

      <ArterialRouteAndMediaTypeCheckboxList
        data={MediaTypes}
        selectedMap={selectedMediaTypesMap}
        onCheckboxChange={handleCheckboxChange}
      />
    </>
  );
}
