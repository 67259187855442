import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { getCampaignPerformanceById } from "../../actions/campaign-performance/CampaignPerformanceActions";
import { getCampaignPlanOverview } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getCampaignMedia } from "../../../actions/campaign-media/CampaignMediaActions";

// Utils and Constants
import { getDifferenceInDays } from "../../../common-utils/date-utils/DateUtils";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import { DurationConstants } from "../../../constants/GeneralConstants";
import { defaultPagination } from "../../../constants/UrlConstants";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import Spinner from "../../../components/spinner/Spinner";
import TableHeaders from "../../../components/table/TableHeaders";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import {
  CampaignDetails,
  CancelCampaign,
  StopCampaign,
} from "../components/HeaderNav";
import { ProgressBarLarge } from "../components/ProgressBarLarge";
import CampaignViewCards from "../components/CampaignViewCards";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Pages and Section
import LiveCityHighlightsTable from "./LiveCityHighlightsTable";
import CityDetailsTable from "../components/CityDetailsTable";

// Page Constants
function getLiveCityPerformanceTableHeaders(
  activeCities,
  totalCities,
  campaignDuration,
  campaignDaysCompleted,
  totalActiveSitesCount,
  totalMediaCount
) {
  return [
    {
      title: {
        displayName: (
          <span>
            {"Active Cities: "}
            <b>{`${activeCities} / ${totalCities}`}</b>
          </span>
        ),
        className: "col-3 border-right",
      },
    },
    {
      title: {
        displayName: (
          <div className="d-flex justify-content-between">
            <span>
              {"Active Media Sites: "}

              <b>{`${totalActiveSitesCount} / ${totalMediaCount || 0}`}</b>
            </span>
            <span>
              <b>{campaignDaysCompleted || 0}</b>
              {" of the total "}
              <b>{campaignDuration || 0}</b>
              {" days Completed"}
            </span>
          </div>
        ),
        className: "col-9",
      },
    },
  ];
}

// Page-Component
function LiveOverviewHeader({ campaign }) {
  const updateStateLoading = useSelector(
    (state) => state.c_campaignBasicInfo.updateStateLoading
  );

  if (!campaign) {
    return null;
  }

  const { id: campaignId } = campaign || {};

  const EnableCancelCampaign = isAgencySpecific(CancelCampaign);
  const EnableStopCampaign = isAgencySpecific(StopCampaign);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex mt-2">
      <div>
        {EnableCancelCampaign && (
          <EnableCancelCampaign campaignId={campaignId} />
        )}
        {EnableStopCampaign && <EnableStopCampaign campaignId={campaignId} />}
      </div>
      {updateStateLoading && <Spinner className="mx-3" />}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

function LiveCityPerformanceTableRow({
  cityId,
  eachLogSummary = {},
  infoByCity = {},
  eachCityInfo = {},
}) {
  const {
    totalGenericOts,
    totalGenericOtsLit,
    totalTargetOts,
    totalTargetOtsLit,
    targetGroupId,
  } = eachCityInfo;

  // Selector
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  const { targetOtsServedTotal, genericOtsServedTotal } = eachLogSummary || {};
  const { cityName, mediaCount, activeMediaCount } = infoByCity[cityId] || {};

  // Planned Est. Impressions
  // TODO:: Remove 30 as multiplication factor - fix PlanData
  const plannedGenericOts =
    (totalGenericOtsLit ?? totalGenericOts) * DurationConstants.THIRTY_DAYS;
  const plannedTargetOts =
    (totalTargetOtsLit ?? totalTargetOts) * DurationConstants.THIRTY_DAYS;

  return (
    <tr>
      <td className="border-right">
        <div className="text-right">
          <p className="mb-1 text-primary text-truncate" title={cityName}>
            {cityName}
          </p>
          <span className="text-muted mb-1">{"TG"}</span>
          <span className="text-muted d-block mt-1">{"General"}</span>
        </div>
      </td>
      <td>
        <div className="text-left">
          {/* TODO - show the count of active media count */}
          <p className="mb-1">{`${activeMediaCount || 0} of ${
            mediaCount || 0
          } sites are Active`}</p>

          {/* Tg Impression */}
          {targetGroupId && (
            <ProgressBarLarge
              impression={targetOtsServedTotal}
              plannedEstImpressions={plannedTargetOts}
              className="bg-success"
            />
          )}
          {/* General Impression */}
          <ProgressBarLarge
            impression={genericOtsServedTotal}
            plannedEstImpressions={plannedGenericOts}
            className="bg-primary"
          />
        </div>
      </td>
    </tr>
  );
}

function LiveCityPerformanceTable({
  campaignPlanOverview = {},
  campaign = {},
  campaignPerformance = {},
}) {
  const { planByCity = {} } = campaignPlanOverview || {};

  const totalCities = Object.keys(planByCity).length;

  // to get active city count
  const activeCities = Object.values(planByCity).reduce((acc, eachCity) => {
    if (eachCity.isLive) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const { infoByCity = {}, mediaCount } = campaign;
  const { startTimestamp, endTimestamp } = campaign;

  const totalActiveSitesCount = Object.values(infoByCity).reduce(
    (acc, eachCity) => acc + (eachCity?.activeMediaCount || 0),
    0
  );

  const campaignDuration = getDifferenceInDays(startTimestamp, endTimestamp);

  const todayDate = new Date();
  const campaignDaysCompleted = getDifferenceInDays(startTimestamp, todayDate);

  // Table headers
  const tableHeaders = getLiveCityPerformanceTableHeaders(
    activeCities,
    totalCities,
    campaignDuration,
    campaignDaysCompleted - 1,
    totalActiveSitesCount,
    mediaCount
  );

  return (
    <div className="mt-5">
      <h4>Performance</h4>
      <div className="table-responsive">
        <table className="table table-fixed">
          <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
          <tbody>
            {Object.keys(planByCity).map((eachCityId) => (
              <LiveCityPerformanceTableRow
                key={eachCityId}
                eachLogSummary={campaignPerformance[eachCityId]}
                cityId={eachCityId}
                infoByCity={infoByCity}
                eachCityInfo={planByCity[eachCityId]}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

/**
 * Page
 */
export default function CampaignLiveOverviewPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Get campaignPlan
  const campaignPlanOverview = useSelector(
    (state) => state.campaignPlan.campaignPlanOverview
  );
  const { planByCity = {} } = campaignPlanOverview || {};

  // Selector
  const campaignPerformance =
    useSelector((state) => state.campaignPerformance.campaignPerformance) || {};

  // Tg name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // target group id
  const targetGroupId = Object.values(planByCity)[0]?.targetGroupId;

  const { pageNo, pageSize } = defaultPagination;
  useEffect(() => {
    dispatch(getCampaignPlanOverview(campaignId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getCampaignPerformanceById(campaignId));

    // campaign medias
    dispatch(getCampaignMedia(campaignId, pageNo, pageSize));
  }, [dispatch, campaignId]);

  // get tg info
  useGetTgInfo(targetGroupId);

  // Sidebar Settings
  const redirectLinkObj = {
    campaignOverviewLink: `/campaign/${campaignId}/live`,
    citySummaryLink: `/campaign/${campaignId}/city/:cityId/live`,
  };

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      {/* Content-Section */}
      <div className="content-wrapper px-0">
        <LiveOverviewHeader campaign={campaign} />

        {/* Page-Content */}
        <div className="page-content">
          <CampaignViewCards
            tgName={tgName}
            campaign={campaign}
            campaignPlanOverview={campaignPlanOverview}
            mediaCount={campaign.mediaCount}
          />

          {/* Campaign Cities Performance */}
          <LiveCityPerformanceTable
            campaignPlanOverview={campaignPlanOverview}
            campaign={campaign}
            campaignPerformance={campaignPerformance}
          />

          {/* Cities Details Table */}
          {/* <LiveCityHighlightsTable
            campaignPlanOverview={campaignPlanOverview}
            campaignPerformance={campaignPerformance}
          /> */}

          {/* City Details Table */}
          <CityDetailsTable
            planByCity={planByCity}
            campaign={campaign}
            campaignPerformance={campaignPerformance}
          />
        </div>
      </div>
    </>
  );
}
