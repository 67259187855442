export const RouteTypes = {
  // Get Route Types
  GET_ROUTE_TYPES: "GET_ROUTE_TYPES",
  GET_ROUTE_TYPES_SUCCESS: "GET_ROUTE_TYPES_SUCCESS",
  GET_ROUTE_TYPES_FAILURE: "GET_ROUTE_TYPES_FAILURE",

  // clear
  CLEAR_ROUTE_TYPES: "CLEAR_ROUTE_TYPES",

  // toggle selection
  TOGGLE_ROUTE_TYPE_SELECTION: "TOGGLE_ROUTE_TYPE_SELECTION",

  // clear selection
  CLEAR_ROUTE_TYPES_SELECTION: "CLEAR_ROUTE_TYPES_SELECTION",
};
