import domtoimage from "dom-to-image";

/**
 * download Dom To Image
 * @param {*} input : element to download
 * @param {*} fileName : file name
 * @param {*} config : extra configuration
 */
export async function downloadDomToImage(
  input,
  fileName = "Image.png",
  config = {}
) {
  // config
  const { quality } = config || {};
  const imageQuality = quality ? quality : 0.95;

  // dom to image
  domtoimage.toPng(input, { quality: imageQuality }).then((dataUrl) => {
    // file link
    const link = document.createElement("a");

    // fileName
    link.download = fileName;

    // image url
    link.href = dataUrl;
    link.click();
  });
}
