import { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

// Actions
import { closeSingleCitySelectionForm } from "../../../actions/SingleCitySelectionFormActions";

// Constants
import {
  FormDataTargets,
  topCitiesNames,
} from "../../../constants/GeneralConstants";

// Page-Components
function SearchCitySection({
  regionsName = [],
  inputText,
  setInputText,
  setCityId,
  onSubmit,
}) {
  // Array of "All-Suggested-Cities" from Input-Search
  const [suggestedCities, setSuggestedCities] = useState([]);

  // Function
  // Search City function for Input
  function inputSearchCityFn(e) {
    const searchText = e.target.value;
    setInputText(searchText);
    if (searchText.length > 2) {
      const suggestion = regionsName.filter((cityObj) =>
        cityObj.label.toLowerCase().includes(searchText.toLowerCase())
      );
      setSuggestedCities(suggestion);
    } else {
      setSuggestedCities([]);
    }
  }

  // function after selecting city from suggestions
  function suggestedCitySelectFn(cityId) {
    onSubmit(cityId);
    setCityId("");
    setInputText("");
    setSuggestedCities([]);
  }

  return (
    <div>
      <input
        type="search"
        className="form-control rounded-lg"
        value={inputText}
        placeholder={"Search Or Select City from below list"}
        onChange={inputSearchCityFn}
      />
      {suggestedCities.length > 0 && (
        <div className="border rounded-lg mt-1 bg-alt">
          {suggestedCities.slice(0, 5).map((city) => (
            <li
              key={city.id}
              className="dropdown-item cursor-pointer"
              data-dismiss="modal"
              onClick={() => suggestedCitySelectFn(city.id)}
            >
              {city.label}
            </li>
          ))}
        </div>
      )}
    </div>
  );
}

function CityListingSection({
  selectedCitiesMap,
  citiesToShowInListing,
  setCityId,
  cityId,
}) {
  return (
    <div className="d-flex flex-wrap">
      {citiesToShowInListing.map((city) => (
        <div key={city.id} className="p-2 col-3 d-flex align-items-center">
          <input
            type="radio"
            name="optRadio"
            className="cursor-pointer"
            id={city.id}
            disabled={selectedCitiesMap[city.id] === true}
            checked={cityId ? cityId === city.id : false}
            onChange={() => setCityId(city.id)}
          />
          <label className="mb-0 px-2 cursor-pointer" htmlFor={city.id}>
            {city.label}
          </label>
        </div>
      ))}
    </div>
  );
}

/**
 * Form-Modal for "Single-City-Selection"
 */
function SingleCitySelectionForm({
  regionsName = [],
  selectedCitiesMap = {},
  onSubmit = () => {},
  selectedCityId = "",
}) {
  const dispatch = useDispatch();
  // State
  const [cityId, setCityId] = useState("");
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    setCityId(selectedCityId);
  }, [selectedCityId]);

  // Function
  function submitCitySelectionFormFn() {
    onSubmit(cityId);
    setInputText("");
  }

  function cancelCitySelectionFormFn() {
    setInputText("");
    dispatch(closeSingleCitySelectionForm());
  }

  return (
    <div
      className="modal fade"
      id={FormDataTargets.singleCitySelectionForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* Form Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">{"Select City"}</h4>
          </div>

          {/* Form body */}
          <div className="modal-body px-5">
            <SearchCitySection
              regionsName={regionsName}
              inputText={inputText}
              setInputText={setInputText}
              setCityId={setCityId}
              onSubmit={onSubmit}
            />
            <hr className="divider"></hr>
            <CityListingSection
              selectedCitiesMap={selectedCitiesMap}
              citiesToShowInListing={topCitiesNames} // hardcoded 40 cities from constant
              setCityId={setCityId}
              cityId={cityId}
            />
          </div>

          {/* Form Footer */}
          <div className="modal-footer px-5">
            <button
              type="button"
              className="btn btn-outline-primary btn-action mt-2 mr-3 rounded-lg shadow-none"
              data-dismiss="modal"
              onClick={cancelCitySelectionFormFn}
            >
              {"Cancel"}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-action mt-2 rounded-lg shadow-none"
              data-dismiss="modal"
              onClick={submitCitySelectionFormFn}
              disabled={!cityId}
            >
              {"Confirm"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleCitySelectionForm;
