// Utils
import { sendGetRequest } from "../utils/api/apiUtils";
import { LocalStorageKeys } from "../constants/GeneralConstants";
import { getItem } from "./../../src/utils/localstorage";

// Urls
import { getOrgRouteTypesUrl } from "../urls/OrgRouteTypesURL";

export async function getRouteTypesFn() {
  // Getting key from "localStorage"
  const user = JSON.parse(getItem(LocalStorageKeys.USER));
  const { orgId } = user || {};

  const url = getOrgRouteTypesUrl.replace(":orgId", orgId);
  return sendGetRequest(url);
}
