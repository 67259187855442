import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Polyline, Popup } from "react-leaflet";

// Actions
import {
  getRoadStretchInfoById,
  updateRoadStretch,
} from "../../../actions/org/OrgRoadStretchActions";

// Utils and Constants
import { OrgViewPageLoader } from "../OrgUtils";
import { MapZoom } from "../../../constants/GeneralConstants";
import { SelectedRouteStyle } from "../../../constants/CssConstants";

// Components
import LLMap from "../../../components/map/leaflet-map/LLMap";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import TrafficFlowArrow from "../../../components/map/traffic-flow-arrow/TrafficFlowArrow";
import TitleEditor from "../../../components/title-editor/TitleEditor";

// Pages and Sections
import OrgSettingsSidebar from "../OrgSettingsSidebar";

// CSS
import "../OrgSettings.scss";

// Page Component
function RoadStretchInfoSection({ roadStretchInfo }) {
  const roadSegmentIds = roadStretchInfo.segmentIds || [];

  return (
    <div className="my-3">
      <h3>{"List Of Road-Segment Ids ::"}</h3>
      <ul className="mb-0">
        {roadSegmentIds.map((eachSegmentId, index) => (
          <li key={index} className="p-1">
            <span>{eachSegmentId}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function RoadStretchViewMapSection({ roadStretchInfo }) {
  if (!roadStretchInfo || !roadStretchInfo.trace) {
    return null;
  }

  const stretchTrace = roadStretchInfo.trace || [];
  const formattedCenter = stretchTrace[0];

  return (
    <div className="map-layout-right">
      <div className="org-map-cont leaflet-container">
        <LLMap center={formattedCenter} zoom={MapZoom.zoomLevel13}>
          <Polyline pathOptions={SelectedRouteStyle} positions={stretchTrace}>
            <Popup>
              <b className="d-block">{roadStretchInfo.name}</b>
            </Popup>
          </Polyline>

          <TrafficFlowArrow trace={stretchTrace} />
        </LLMap>
      </div>
    </div>
  );
}

/**
 * Page
 */
function RoadStretchViewPage() {
  const dispatch = useDispatch();
  const { id: stretchId } = useParams();

  // Selector
  const roadStretchLoading = useSelector(
    (state) => state.orgRoadStretch.roadStretchInfoLoading
  );
  const roadStretchInfo =
    useSelector(
      (state) => state.orgRoadStretch.roadStretchInfoMap[stretchId]
    ) || {};
  const { name, segmentIds } = roadStretchInfo || {};

  // update
  const updateRoadStretchLoading = useSelector(
    (state) => state.orgRoadStretch.updateRoadStretchLoading
  );

  useEffect(() => {
    dispatch(getRoadStretchInfoById(stretchId));
  }, [dispatch, stretchId]);

  // Page Title
  const pageTitle = (
    <div className="d-flex align-items-center w-100 flex-wrap">
      <h3 className="mb-0 min-w-165">Road Stretch ::</h3>
      <TitleEditor
        title={name}
        onTitleChange={(titleStr) =>
          dispatch(updateRoadStretch(stretchId, titleStr, segmentIds))
        }
        loader={updateRoadStretchLoading}
        inputBoxClass={"rounded-lg ml-2 col-6 min-w-300"}
        titleClass={"px-0 mb-0 h1"}
        containerClass="pl-1 pr-0 max-w-500"
      />
    </div>
  );

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={5} />

      <div className="content-wrapper map-content-wrapper">
        {roadStretchLoading && <OrgViewPageLoader />}
        {!roadStretchLoading && (
          <>
            {/** Map Layout :: Left */}
            <div className="map-layout-left">
              <PageHeader
                title={pageTitle}
                shadow={false}
                border={true}
                className="align-items-center"
              />

              {/* Road-Stretch info Section */}
              <RoadStretchInfoSection roadStretchInfo={roadStretchInfo} />
            </div>

            {/** Map Section :: Right */}
            <RoadStretchViewMapSection roadStretchInfo={roadStretchInfo} />
          </>
        )}
      </div>
    </>
  );
}

export default RoadStretchViewPage;
