export const createCampaignUrl = "/api/v3/campaign/new";
export const createCampaignUrl2 = "/api/v3/campaign";
export const updateCampaignTitleUrl = "/api/v3/campaign/:campaignId";
export const getCampaignListUrl = "/api/v3/campaign/search";
export const updateCampaignCityInfoUrl = "/api/v3/campaign/:id/city/:cityId";
export const getCampaignUrl = "/api/v3/campaign/:campaignId";
export const updateCampaignStateUrl =
  "/api/v3/campaign/:campaignId/plan/status";
export const getSharedCampaignUrl = "/api/v3/campaign/shared";
export const updateCampaignUrl = "/api/v3/campaign/:campaignId";
export const addCitiesToCampaignUrl = "/api/v3/campaign/:campaignId/city";
export const removeCityFromCampaignUrl =
  "/api/v3/campaign/:campaignId/city/:cityId";
