import { DefaultPagination, PageSize } from "../../constants/GeneralConstants";
import { sendGetRequest } from "../../utils/api/apiUtils";

// Urls
import {
  getFrequentlyOptedLocationsUrl,
  getSubZonesUrl,
  getZonesUrl,
} from "../urls/ZonesAndSubZonesURL";

export async function getZonesFn(pageNo, pageSize) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    _type: "CITY",
    pn: pageNo || DefaultPagination.pageNumber,
    ps: pageSize || PageSize.zones,
  };

  return sendGetRequest(getZonesUrl, params, true, headerParams);
}

export async function getSubZonesFn(regionId, pageNo, pageSize) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    _type: "ZONE",
    pn: pageNo || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  const url = getSubZonesUrl.replace(":regionId", regionId);

  return sendGetRequest(url, params, true, headerParams);
}

export async function getFrequentlyOptedLocationsFn(cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    cityId: cityId,
  };

  return sendGetRequest(
    getFrequentlyOptedLocationsUrl,
    params,
    true,
    headerParams
  );
}
