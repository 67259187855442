import { ActionTypes } from "../../constants/ActionConstants";

// set Media Types
export const setMediaTypes = (mediaTypesCountMap) => ({
  type: ActionTypes.MapView.SET_MEDIA_TYPES,
  payload: { mediaTypesCountMap },
});

// select CampaignMedia Type
export const selectMediaType = (mediaType) => ({
  type: ActionTypes.MapView.SELECT_MEDIA_TYPE,
  payload: { mediaType },
});

// unselect CampaignMedia Type
export const unselectMediaType = (mediaType) => ({
  type: ActionTypes.MapView.UNSELECT_MEDIA_TYPE,
  payload: { mediaType },
});

// set roadStretches data
export const setRoadStretches = (selectedStretchIds, unSelectedStretchIds) => ({
  type: ActionTypes.MapView.SET_ROAD_STRETCHES,
  payload: { selectedStretchIds, unSelectedStretchIds },
});

// select RoadStretches which are selected
export const selectRoadStretchType = (stretchType) => ({
  type: ActionTypes.MapView.SELECT_ROAD_STRETCH_TYPE,
  payload: { stretchType },
});

// unselect RoadStretches which are selected
export const unselectRoadStretchType = (stretchType) => ({
  type: ActionTypes.MapView.UNSELECT_ROAD_STRETCH_TYPE,
  payload: { stretchType },
});

// select POI
export const selectPoi = (poi) => ({
  type: ActionTypes.MapView.SELECT_POI,
  payload: { poi },
});

// unselect POI
export const unselectPoi = (poi) => ({
  type: ActionTypes.MapView.UNSELECT_POI,
  payload: { poi },
});

// Set PoiAndItsBrandInfo
export const setPoiAndBrandInfo = (poiToBrandMap, cityId, bbox) => ({
  type: ActionTypes.MapView.SET_POI_AND_BRANDS,
  payload: { poiToBrandMap, cityId, bbox },
});

// set TargetGroup
export const setTg = (tgId) => ({
  type: ActionTypes.MapView.SET_TG,
  payload: { tgId },
});

// select TargetGroup
export const selectTargetGroup = (tgId) => ({
  type: ActionTypes.MapView.SELECT_TG,
  payload: { tgId },
});

// unselect TargetGroup
export const unselectTargetGroup = (tgId) => ({
  type: ActionTypes.MapView.UNSELECT_TG,
  payload: { tgId },
});
