import { useSelector } from "react-redux";

// Utils & constants
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { formatText } from "../../../common-utils/string-utils/StringUtils";
import { PerformanceCardWidth } from "../../../constants/CssConstants";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";
import { getCitySpecificMediaList } from "../media-selection/MediaContainerUtil";
import { getTotalCostOfMedias } from "../../../utils/MediaUtils";

// Components
import PerformanceCards from "../../components/performance-card/PerformanceCard";
import {
  constructDateString,
  getDifferenceInDays,
} from "../../../common-utils/date-utils/DateUtils";

// Page Functions
function constructSitesOrCityMessage(infoByCity, sitesCount) {
  const card = {
    subValue: "",
  };

  const cityCount = Object.keys(infoByCity).length;

  card.subValue = (
    <div className="d-flex justify-content-between mt-n2">
      <div>
        <p>Cities</p>
        <h3>{cityCount}</h3>
      </div>
      <div className="text-truncate ml-2" title="Media Sites">
        <p>Media Sites</p>
        <h3>{sitesCount || 0}</h3>
      </div>
    </div>
  );
  return card;
}

function getTotalGenericAndTgOts(planByCity = {}, campaignMedias = []) {
  // if no media in campaign, no ots
  if (campaignMedias.length === 0) {
    return { totalGenericImp: 0, totalTargetImp: 0 };
  }

  // total impressions (generic and tg)
  const totalImpressionsMap = Object.keys(planByCity).reduce(
    (acc, cityId) => {
      const cityInfo = planByCity[cityId] || {};
      const { roadStretchOtsMap = {} } = cityInfo;

      // city mediaList
      const cityMedias = getCitySpecificMediaList(campaignMedias, cityId);

      // by looping over each city medias,
      // capture stretchId and then add that stretch specific impression to total
      cityMedias.forEach((media) => {
        const { roadStretchId } = media;
        const stretchOtsInfo = roadStretchOtsMap[roadStretchId] || {};
        const { ots, otsLit, targetOts, targetOtsLit } = stretchOtsInfo || {};
        const finalGenericOts = otsLit ?? ots;
        const finalTargetOts = targetOtsLit ?? targetOts;

        // Total Impressions (generic, target)
        if (finalGenericOts) {
          acc.totalGenericImp = acc.totalGenericImp + finalGenericOts;
        }
        if (finalTargetOts) {
          acc.totalTargetImp = acc.totalTargetImp + finalTargetOts;
        }
      });

      return acc;
    },
    { totalGenericImp: 0, totalTargetImp: 0 }
  );

  return totalImpressionsMap;
}

function getCardInfo(
  campaign = {},
  campaignPlanOverview = {},
  sitesCount,
  campaignMedias = []
) {
  // plan overview
  const { summary: campaignPlanSummary = {}, planByCity = {} } =
    campaignPlanOverview || {};

  const {
    genericOtsTotal,
    genericOtsLitTotal,
    targetOtsTotal,
    targetOtsLitTotal,
    estimatedCostTotal,
    startTimestamp,
    endTimestamp,
  } = campaignPlanSummary || {};
  const { infoByCity = {} } = campaign || {};

  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  const totalDays = getDifferenceInDays(startTimestamp, endTimestamp);
  const cityOrMediaCount = constructSitesOrCityMessage(infoByCity, sitesCount);
  const otsSpecific = targetOtsLitTotal ?? targetOtsTotal;
  const otsGeneric = genericOtsLitTotal ?? genericOtsTotal;

  const formattedSpecificOts =
    otsSpecific === 0
      ? 0
      : formatText(toStringWithRoundUp(otsSpecific * totalDays));

  const formattedGenericOts =
    otsGeneric === 0
      ? 0
      : formatText(toStringWithRoundUp(otsGeneric * totalDays));

  const formattedEstCost =
    estimatedCostTotal === 0
      ? 0
      : formatText(toStringWithRoundUp(estimatedCostTotal * totalDays));

  // getting total impression for generic and tg based on stretches
  const { totalGenericImp, totalTargetImp } = getTotalGenericAndTgOts(
    planByCity,
    campaignMedias
  );
  const formattedTotalGenericImp = formatText(
    toStringWithRoundUp(totalGenericImp)
  );
  const formattedTotalTargetImp = formatText(
    toStringWithRoundUp(totalTargetImp)
  );

  // total cost of medias
  const totalCostOfMedias = getTotalCostOfMedias(campaignMedias);
  const formattedTotalCostOfMedias = formatText(
    toStringWithRoundUp(totalCostOfMedias)
  );

  const cardInfo = [
    cityOrMediaCount,
    {
      title: "Dates",
      value: dateString,
      subValue: `Duration: ${totalDays} days`,
    },
    {
      title: "Impressions (Estimated)",
      // value: <p className="mb-0">TG: {formattedSpecificOts}</p>,
      // subValue: <p className="mb-0 h4">General: {formattedGenericOts}</p>,
      value: <p className="mb-0">TG: {formattedTotalTargetImp}</p>,
      subValue: <p className="mb-0 h4">General: {formattedTotalGenericImp}</p>,
    },
    {
      title: "Total Est Cost",
      // value: `Rs. ${formattedEstCost}`,
      value: `Rs. ${formattedTotalCostOfMedias}`,
    },
  ];

  return cardInfo;
}

/**
 * Campaign Cards Component
 */
function CampaignViewCards({
  tgName,
  campaign = {},
  campaignPlanOverview = {},
  mediaCount,
}) {
  // Selector
  const campaignMedias = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // no data
  if (Object.keys(campaignPlanOverview).length < 1 || !campaign) {
    return null;
  }

  const cardInfo = getCardInfo(
    campaign,
    campaignPlanOverview,
    mediaCount,
    campaignMedias
  );

  return (
    <>
      <h4>{"Overview"}</h4>
      <div className="row mx-0">
        {cardInfo.map((info, i) => (
          <PerformanceCards
            info={info}
            key={i}
            className={`${PerformanceCardWidth[i]} performance-card`}
          />
        ))}
      </div>
    </>
  );
}

export default CampaignViewCards;
