import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { toggleRouteTypeSelection } from "../../../actions/org/OrgRouteTypesActions";
import { closeTabContentPreviewForm } from "../../actions/campaign-planning/TabContentPreviewFormActions";
import { unSelectSubZone } from "../../actions/campaign-planning/ZonesAndSubZonesActions";
import {
  toggleMediaType,
  unSelectTgAttribute,
} from "../../actions/campaign-planning/CampaignPlanningActions";
import { unSelectPoiBrands } from "../../../actions/map-view/poi/PoiSelectionActions";
import { setPoiStatus } from "../../../actions/map-view/poi/PoiActions";

// Constants
import { ProohFormDataTargets } from "../../constants/GeneralConstants";
import { MediaTypes } from "./TabConstants";
import { Status } from "../../../constants/GeneralConstants";

// Components
import Chips from "../../../components/Chips/Chips";

// Page Functions
function constructSelectedTgs(selectedTgAttributesMap = {}) {
  const allTgAttributes = [];

  Object.keys(selectedTgAttributesMap).forEach((key) => {
    const tgAttributes = selectedTgAttributesMap[key];
    tgAttributes.forEach((tgAttr) => {
      const { tgName, tgAttrId, tgAttrName } = tgAttr;
      const constructedTgAttributes = {
        id: tgAttrId,
        label: `${tgName} - ${tgAttrName}`,
        ...tgAttr,
      };
      allTgAttributes.push(constructedTgAttributes);
    });
  });

  return allTgAttributes;
}

function constructSelectedZones(selectedZonesAndSubZonesMap) {
  const allSubZones = [];

  Object.keys(selectedZonesAndSubZonesMap).forEach((key) => {
    const subZones = selectedZonesAndSubZonesMap[key];
    subZones.forEach((subZone) => {
      const { subZoneId, subZoneName, zoneName } = subZone;
      const constructedSubZone = {
        id: subZoneId,
        label: `${zoneName} - ${subZoneName}`,
        ...subZone,
      };
      allSubZones.push(constructedSubZone);
    });
  });

  return allSubZones;
}

function constructChipsData(data) {
  const constructedData = [];
  data.forEach((item) => {
    const constructedItem = {
      id: item,
      label: item,
    };
    constructedData.push(constructedItem);
  });
  return constructedData;
}

// checking selected content present or not
function checkIsSelectedContentPresent(...arrays) {
  return arrays.some((array) => array.length > 0);
}

// Selected Tg Chips
function SelectedTargetGroupsChips({ constructedTargetGroups = [] }) {
  const dispatch = useDispatch();

  // functions
  function handleUnSelectTgAttr(_id, tgAttr) {
    dispatch(unSelectTgAttribute(tgAttr));
  }

  if (constructedTargetGroups.length === 0) {
    return null;
  }

  return (
    <Chips
      title="Target Group"
      selectedItems={constructedTargetGroups}
      showHrTag={true}
      containerClassName="flex-wrap"
      onDeselect={handleUnSelectTgAttr}
    />
  );
}

// Selected Zones Chips
function SelectedZonesChips({ constructedZones = [] }) {
  const dispatch = useDispatch();

  // functions
  function handleUnSelectZone(_id, subZone) {
    dispatch(unSelectSubZone(subZone));
  }

  if (constructedZones.length === 0) {
    return null;
  }

  return (
    <Chips
      title="Zone"
      selectedItems={constructedZones}
      showHrTag={true}
      containerClassName="flex-wrap"
      onDeselect={handleUnSelectZone}
    />
  );
}

function SelectedArterialRoutesChips({ constructedArterialRoutes = [] }) {
  const dispatch = useDispatch();

  function handleUnSelectZone(_id, route) {
    dispatch(toggleRouteTypeSelection(route.id));
  }

  if (constructedArterialRoutes.length === 0) {
    return null;
  }

  return (
    <Chips
      title="Route Type"
      selectedItems={constructedArterialRoutes}
      showHrTag={true}
      containerClassName="flex-wrap"
      onDeselect={handleUnSelectZone}
    />
  );
}

function SelectedMediaTypesChips({ constructedMediaTypes = [] }) {
  const dispatch = useDispatch();

  function handleUnSelectZone(_id, mediaType) {
    dispatch(toggleMediaType([mediaType.id]));
  }

  if (constructedMediaTypes.length === 0) {
    return null;
  }

  return (
    <Chips
      title="Media Type"
      selectedItems={constructedMediaTypes}
      showHrTag={true}
      containerClassName="flex-wrap"
      onDeselect={handleUnSelectZone}
    />
  );
}

function SelectedPoiBrandsChips({ poiBrands }) {
  const dispatch = useDispatch();

  function handleUnselect(brandId, brandObj) {
    const { label = "" } = brandObj;
    dispatch(setPoiStatus(label, Status.UNCHECKED));
    dispatch(unSelectPoiBrands([brandId]));
  }

  if (poiBrands.length === 0) {
    return null;
  }

  return (
    <Chips
      title="Poi Type"
      selectedItems={poiBrands}
      showHrTag={true}
      containerClassName="flex-wrap"
      onDeselect={handleUnselect}
    />
  );
}

/**
 * Tab Content Preview Form
 */
export default function TabContentPreviewForm() {
  const dispatch = useDispatch();

  // Zones And SubZones Map
  const selectedZonesAndSubZonesMap = useSelector(
    (state) => state.zonesAndSubZones.selectedZonesAndSubZonesMap
  );

  // Arterial Routes
  const selectedArterialRoutesMap = useSelector(
    (state) => state.orgRouteTypes.selectedArterialRoutes
  );
  const selectedArterialRoutes = Object.keys(selectedArterialRoutesMap);

  // Media Types
  const selectedMediaTypesMap = useSelector(
    (state) => state.campaignPlanning.selectedMediaTypes
  );
  const selectedMediaTypes = MediaTypes.filter(
    (type) => selectedMediaTypesMap[type]
  );

  // Target Group
  const selectedTgAttributesMap = useSelector(
    (state) => state.campaignPlanning.selectedTgAttributesMap
  );

  const brandIds = useSelector((state) => state.poiSelection.brandIds) || {};
  const brandIdToLabel = useSelector((state) => state.poi.brandIdToLabel);
  const selectedPoiBrands = Object.keys(brandIds).filter(
    (brandId) => brandIds[brandId].selected
  );

  const poiBrands = selectedPoiBrands.map((id) => ({
    id: id,
    label: brandIdToLabel[id],
  }));

  // construct data
  const constructedZones = constructSelectedZones(selectedZonesAndSubZonesMap);
  const constructedArterialRoutes = constructChipsData(selectedArterialRoutes);
  const constructedMediaTypes = constructChipsData(selectedMediaTypes);
  const constructedTargetGroups = constructSelectedTgs(selectedTgAttributesMap);

  // check is selected content present or not
  const isSelectedContentPresent = checkIsSelectedContentPresent(
    constructedZones,
    constructedArterialRoutes,
    constructedMediaTypes,
    // constructedTargetGroups,
    poiBrands
  );

  return (
    <div
      className="modal fade"
      id={ProohFormDataTargets.tabContentPreviewForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* Form Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">Selected Information</h4>
          </div>

          {/* Form Body */}
          <div className="modal-body px-5">
            {/* if content not present */}
            {!isSelectedContentPresent && (
              <h5 className="text-center py-4 font-italic text-muted">
                No content selected
              </h5>
            )}

            {/* if content present */}
            {isSelectedContentPresent && (
              <>
                {/* Target Groups */}
                {/* <SelectedTargetGroupsChips
                  constructedTargetGroups={constructedTargetGroups}
                /> */}

                {/* Zones & SubZones */}
                <SelectedZonesChips constructedZones={constructedZones} />

                {/* Arterial Routes */}
                <SelectedArterialRoutesChips
                  constructedArterialRoutes={constructedArterialRoutes}
                />

                {/* Media Types */}
                <SelectedMediaTypesChips
                  constructedMediaTypes={constructedMediaTypes}
                />

                <SelectedPoiBrandsChips poiBrands={poiBrands} />
              </>
            )}
          </div>

          {/* Form Footer */}
          <div className="modal-footer px-5">
            <button
              type="button"
              className="btn btn-outline-primary btn-action mt-2 mr-3 rounded-lg"
              data-dismiss="modal"
              onClick={() => dispatch(closeTabContentPreviewForm())}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
