//Utils
import {
  sendGetRequest,
  sendCreateOrUpdateRequest,
  deleteRequest,
} from "../utils/api/apiUtils";

//Constants
import {
  createCampaignUrl,
  updateCampaignTitleUrl,
  getCampaignListUrl,
  updateCampaignCityInfoUrl,
  getCampaignUrl,
  updateCampaignStateUrl,
  createCampaignUrl2,
  getSharedCampaignUrl,
  updateCampaignUrl,
  addCitiesToCampaignUrl,
  removeCityFromCampaignUrl,
} from "../urls/CampaignURL";

export async function createCampaignData(body) {
  return sendCreateOrUpdateRequest(createCampaignUrl, body);
}

export async function createNewCampaign(body) {
  return sendCreateOrUpdateRequest(createCampaignUrl2, body);
}

export async function updateCampaignTitle(campaignId, titleStr) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    updateCampaignTitleUrl.replace(":campaignId", campaignId),
    { title: titleStr },
    true,
    "PUT",
    headerParams
  );
}

export async function getCampaignList(searchText, pageNumber, pageSizeCount) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const params = {
    pn: pageNumber,
    ps: pageSizeCount,
  };

  if (searchText) {
    params._kw = searchText;
  }

  return sendGetRequest(getCampaignListUrl, params, true, headerParams);
}

export async function updateCityInfo(campaignId, cityId, requestBody) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  return sendCreateOrUpdateRequest(
    updateCampaignCityInfoUrl
      .replace(":id", campaignId)
      .replace(":cityId", cityId),
    requestBody,
    true,
    "PUT",
    headerParams
  );
}

export async function getCampaign(campaignId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getCampaignUrl.replace(":campaignId", campaignId);
  return sendGetRequest(url, {}, true, headerParams);
}

export async function updateCampaignState(campaignId, state) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    status: state,
  };

  const url = updateCampaignStateUrl.replace(":campaignId", campaignId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams, params);
}

export async function getSharedCampaigsFn() {
  return sendGetRequest(getSharedCampaignUrl);
}

export async function updateCampaignFn(title, buyerId, campaignId) {
  const url = updateCampaignUrl.replace(":campaignId", campaignId);
  const body = { title, buyerId };

  return sendCreateOrUpdateRequest(url, body, true, "PUT");
}

/**
 * @param {*} selectedCities
 * @returns converting "id" to "cityId" for eachCity
 */
function constructCitiesBean(selectedCities) {
  const body = selectedCities.map((city) => {
    return {
      cityId: city.id,
      cityName: city.label,
    };
  });
  return body;
}

export async function addCitiesToCampaignFn(campaignId, selectedCities) {
  const body = constructCitiesBean(selectedCities);
  return sendCreateOrUpdateRequest(
    addCitiesToCampaignUrl.replace(":campaignId", campaignId),
    body
  );
}

export async function removeCityFromCampaignFn(campaignId, cityId) {
  const url = removeCityFromCampaignUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);
  return deleteRequest(url);
}
