import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getCampaignList } from "../../../actions/campaign/CampaignBasicInfoActions";

// Constants and Utils
import { CampaignState } from "../components/planningConstants";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import { CampaignStatus } from "../components/HeaderNav";
import Spinner from "../../../components/spinner/Spinner";
import { CampaignRedirect } from "../components/CampaignRedirect";

// Utils
import { formatText } from "../../../common-utils/string-utils/StringUtils";

const tableHeaders = [
  {
    title: {
      displayName: "Campaign Name",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Client Name",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Status",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "No Of Sites",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Last Updated",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Action",
      className: "col-1",
    },
  },
];

function CampaignRow({ campaign = {} }) {
  const { title, buyerName, planStatus, mediaCount, updatedOn, id } =
    campaign || {};

  // checking campaign status as review or draft
  const isPlanStatusDraft = planStatus === CampaignState.DRAFT;
  const isPlanStatusReview = planStatus === CampaignState.REVIEW;
  const isPlanStatusConfirmed = planStatus === CampaignState.CONFIRMED;
  const isPlanStatusScheduled = planStatus === CampaignState.SCHEDULED;
  const isPlanStatusLive = planStatus === CampaignState.LIVE;
  const isPlanStatusCompleted = planStatus === CampaignState.COMPLETED;
  const isPlanStatusCancelled = planStatus === CampaignState.CANCELLED;
  const isPlanStatusStopped = planStatus === CampaignState.STOPPED;

  // only showing DRAFT & REVIEW & CONFIRMED & SCHEDULED & LIVE & COMPLETED & CANCELLED & STOPPED
  const isShowCampaign =
    isPlanStatusDraft ||
    isPlanStatusReview ||
    isPlanStatusConfirmed ||
    isPlanStatusScheduled ||
    isPlanStatusLive ||
    isPlanStatusCompleted ||
    isPlanStatusCancelled ||
    isPlanStatusStopped;

  // showing campaign
  if (isShowCampaign) {
    return (
      <tr>
        <td>{title}</td>
        <td>{formatText(buyerName)}</td>
        <td>
          <CampaignStatus state={planStatus} />
        </td>
        <td>{mediaCount}</td>
        <td>{new Date(updatedOn).toDateString()}</td>
        <td>
          <CampaignRedirect id={id} state={planStatus} />
        </td>
      </tr>
    );
  }

  return null;
}

function TableTitle() {
  return (
    <div className="d-flex justify-content-between">
      <h4 className="card-title my-1">{"Campaigns"}</h4>
    </div>
  );
}

function Loader() {
  return (
    <div className="d-flex justify-content-center border p-2 mt-3 bg-alt">
      <Spinner />
    </div>
  );
}

function CampaignsTableBody({ campaigns = [] }) {
  const isCampaignsPresent = campaigns.length > 0;

  if (!isCampaignsPresent) {
    return (
      <tr>
        <td colSpan={6}>
          <span className="d-flex justify-content-center">
            {"No Campaigns Present"}
          </span>
        </td>
      </tr>
    );
  }

  return (
    <>
      {campaigns.map((campaign) => (
        <CampaignRow key={campaign.id} campaign={campaign} />
      ))}
    </>
  );
}

/**
 * Table Component
 */
export default function CampaignsTable() {
  const dispatch = useDispatch();

  // Selector States
  const campaigns = useSelector((state) => state.c_campaignList.camList);

  // Campaign Loading
  const campaignsLoading = useSelector(
    (state) => state.c_campaignList.camListLoading
  );

  useEffect(() => {
    dispatch(getCampaignList());
  }, [dispatch]);

  return (
    <div className="card shadow mt-5">
      <div className="card-body">
        <TableTitle />

        {campaignsLoading && <Loader />}
        {!campaignsLoading && (
          <div className="table-responsive mt-3">
            <table className="table border table-reports">
              <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
              <tbody>
                <CampaignsTableBody campaigns={campaigns} />
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
