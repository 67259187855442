import {
  archiveTargetGroupUrl,
  createTargetGroupUrl,
  getTargetGroupListUrl,
  getTargetGroupUrl,
  restoreTargetGroupUrl,
  targetGroupAttributesUrl,
  updateTargetGroupUrl,
} from "../urls/OrgTargetGroupURL";
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

export async function getTargetGroupsList(
  includeDefault,
  keyWord,
  pageNumber,
  pageSize,
  isArchived,
  excludeTgWithName
) {
  // Here, If isArchived == true ==> Meaning we will only get archived Tg's
  // If isArchived == false ==> Meaning we will only get  un-archived Tg's
  // If isArchived == "" ==> Meaning we will get all Tg's
  // (Here,isArchived == "" param is skipped by serializeQueryParams in sendGetRequest,so we will get all Tg's )

  const params = {
    inclDefault: includeDefault,
    pn: pageNumber,
    ps: pageSize,
    _ar: isArchived,
  };

  if (keyWord) {
    params._kw = keyWord;
  }

  // adding param to exclude tgs with some name
  if (excludeTgWithName) {
    params._ex_name = false;
  }

  return sendGetRequest(getTargetGroupListUrl, params, true);
}

export async function getTargetGroupAttributes() {
  return sendGetRequest(targetGroupAttributesUrl);
}

// create TG
export async function createTargetGroup(targetGroupBean) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  return sendCreateOrUpdateRequest(
    createTargetGroupUrl,
    targetGroupBean,
    true,
    "POST",
    headerParams
  );
}

// Update TG
export async function updateTargetGroup(targetGroupBean, tgId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  return sendCreateOrUpdateRequest(
    updateTargetGroupUrl.replace(":tgId", tgId),
    targetGroupBean,
    true,
    "PUT",
    headerParams
  );
}

export async function getTg(tgId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getTargetGroupUrl.replace(":tgId", tgId);

  return sendGetRequest(url, {}, true, headerParams);
}

export async function archiveTargetGroup(tgId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = archiveTargetGroupUrl.replace(":tgId", tgId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams);
}

export async function restoreTargetGroup(tgId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = restoreTargetGroupUrl.replace(":tgId", tgId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams);
}
