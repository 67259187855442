// remove keys from object whose values is empty array
function filterTgAndTgAttrMap(clonedTgAttributesMap = {}) {
  const filteredMap = Object.keys(clonedTgAttributesMap)
    .filter(
      (key) =>
        Array.isArray(clonedTgAttributesMap[key]) &&
        clonedTgAttributesMap[key].length != 0
    )
    .reduce((acc, key) => {
      acc[key] = clonedTgAttributesMap[key];
      return acc;
    }, {});

  return filteredMap;
}

// select/un-select :: update Tg Attributes selection Map
export function updateTgAttributesMap(
  tgAttributesMap = {},
  tgAttribute = {},
  isSelect = true // key used for "select = true" & "unselect = false"
) {
  const { tgAttrId, tgId } = tgAttribute || {};

  const clonedTgAttributesMap = { ...tgAttributesMap };

  const existingTgIds = Object.keys(clonedTgAttributesMap);

  // append tg in map
  if (!existingTgIds.includes(tgId)) {
    clonedTgAttributesMap[tgId] = [];
  }

  // update tg attr for "Select"
  if (isSelect) {
    const selectedTgToAddTgAttr = clonedTgAttributesMap[tgId];
    const clonedSelectedTgToAddTgAttr = [...selectedTgToAddTgAttr];
    clonedSelectedTgToAddTgAttr.push(tgAttribute);
    clonedTgAttributesMap[tgId] = clonedSelectedTgToAddTgAttr;
  }

  // update tg attr for "Un Select"
  if (!isSelect) {
    const selectedTgToRemoveTgAttr = clonedTgAttributesMap[tgId];
    const clonedSelectedTgToRemoveTgAttr = [...selectedTgToRemoveTgAttr];

    const tgAttrIndex = clonedSelectedTgToRemoveTgAttr.findIndex(
      (tgaAttr) => tgaAttr.tgAttrId === tgAttrId
    );

    // only splice array when item is found
    if (tgAttrIndex > -1) {
      clonedSelectedTgToRemoveTgAttr.splice(tgAttrIndex, 1);
    }

    // update after removing tg attribute
    clonedTgAttributesMap[tgId] = clonedSelectedTgToRemoveTgAttr;
  }

  // filter map by removing tg with no tg attr
  const filteredTgAndTgAttrMap = filterTgAndTgAttrMap(clonedTgAttributesMap);

  // final return
  return filteredTgAndTgAttrMap;
}
