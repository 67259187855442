import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Actions
import { resetTgGroup } from "../../../actions/org/OrgTargetGroupActions";
import {
  getCampaignPlan,
  resetCampaignPlanDetails,
} from "../../../actions/campaign-plan/CampaignPlanActions";
import {
  getBasicCampaignDetails,
  restCampaignDetails,
} from "../../../actions/campaign/CampaignBasicInfoActions";
import { resetRoadStretches } from "../../../actions/org/OrgRoadStretchActions";
import { resetSellerMediaByStretchIds } from "../../../actions/seller/SellerMediaActions";
import { resetMediaSelection } from "../../actions/media-selection/MediaSelectionActions";

// Utils and constants
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import {
  getCitySpecificMediaList,
  getSelectedStretchesAndMediaCount,
  getSitesTotalImpressionsAndCost,
  getStaticAndDoohSitesCount,
} from "./MediaContainerUtil";
import {
  getStretchesTotalOtsSplit,
  getTotalOtsSplitForStretchesBasedOnSelectedMedias,
} from "../../utils/campaignPlanningUtil";
// import { getDifferenceInDays } from "../../../common-utils/date-utils/DateUtils";
// import { DurationConstants } from "../../../constants/GeneralConstants";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import FiltersAndSelectionChipsCard from "../components/FiltersAndSelectionChipsCard";
import SelectionOverviewCard from "../components/SelectionOverviewCard";
// import CityViewCards from "../components/CityViewCards";
// import { EstimatedPriceBreakDown } from "../components/EstimatedPriceBreakDown";
import { continueToPlanning } from "../components/CampaignRedirect";
import { ContinueEditing, Review, SaveAsDraft } from "../components/HeaderNav";
import RoadAndMediaSection from "./RoadAndMediaSection";
import CreateMediaForm from "./CreateMediaForm";

// Page Components
function MediaSelectionHeader({ campaign = {}, cityId }) {
  const history = useHistory();
  const { id, title = "" } = campaign;

  const pageActions = (
    <div className="d-flex">
      <ContinueEditing
        onClickAction={() => continueToPlanning(id, cityId, history)}
      />
      <SaveAsDraft />
      <Review campaignId={id} cityId={cityId} />
    </div>
  );

  return <PageHeader title={title} actions={pageActions} shadow={true} />;
}

// Stretch Selection Overview
function StretchSelectionOverview({ campaign = {}, cityPlan = {} }) {
  // stretch details map (overall stretch data including all medias)
  const roadStretchDetailsMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // city plan
  const {
    roadStretchOtsMap = {},
    // startTimestamp,
    // endTimestamp,
  } = cityPlan || {};
  // const cityPlanDuration = getDifferenceInDays(startTimestamp, endTimestamp);

  // selected stretch data and total mediaCount on stretch
  const { totalStretchesCount, totalMediaCount } =
    getSelectedStretchesAndMediaCount(cityPlan, roadStretchDetailsMap);

  // total ots values
  const { ots = 0, targetOts = 0 } =
    getStretchesTotalOtsSplit(roadStretchOtsMap);

  // overview data
  const stretchesOverviewData = [
    {
      title: "Stretches",
      value: totalStretchesCount,
    },
    {
      title: "Total Sites",
      value: totalMediaCount,
    },
    {
      title: "Total Impressions",
      value: ots,
    },
    {
      title: "TG Impressions",
      value: targetOts,
    },
  ];

  return (
    <div className="row align-items-stretch">
      <div className="col-12 col-xl-6">
        <h5>Stretch Selection Overview</h5>
        <SelectionOverviewCard data={stretchesOverviewData} />
      </div>

      {/* filters card */}
      <FiltersAndSelectionChipsCard campaign={campaign} cityPlan={cityPlan} />
    </div>
  );
}

// Site Selection Overview
function SiteSelectionOverview({
  campaign = {},
  cityPlan = {},
  campaignMedias = [],
}) {
  const { cityId } = useParams();

  // stretch details map (overall stretch data including all medias)
  const roadStretchDetailsMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // selected stretch data and total mediaCount on stretch
  const { selectedStretchesMap, totalMediaCount } =
    getSelectedStretchesAndMediaCount(cityPlan, roadStretchDetailsMap);

  // campaign data
  const { infoByCity = {} } = campaign || {};
  const selectedCity = infoByCity[cityId] || {};
  const { mediaCount = 0 } = selectedCity || {};

  // city specific medias
  const citySpecificMediaList = getCitySpecificMediaList(
    campaignMedias,
    cityId
  );
  const { static: staticSitesCount = 0, DOOH: doohSitesCount = 0 } =
    getStaticAndDoohSitesCount(citySpecificMediaList);

  // sites card data
  const sitesCardData = [
    {
      title: "Total",
      value: totalMediaCount,
    },
    {
      title: "Selected",
      value: mediaCount,
    },
    {
      title: "Static",
      value: staticSitesCount,
    },
    {
      title: "DOOH",
      value: doohSitesCount,
    },
  ];

  // total impressions and cost
  const {
    // genericImpressions = 0,
    // tgImpressions = 0,
    totalEstCost = 0,
  } = getSitesTotalImpressionsAndCost(citySpecificMediaList);

  // city plan data
  const {
    roadStretchOtsMap = {},
    // startTimestamp,
    // endTimestamp,
  } = cityPlan || {};

  // const cityPlanDuration = getDifferenceInDays(startTimestamp, endTimestamp);

  // Total OtsSplit For Stretches Based On Selected Medias
  const { ots = 0, targetOts = 0 } =
    getTotalOtsSplitForStretchesBasedOnSelectedMedias(
      roadStretchOtsMap,
      selectedStretchesMap,
      citySpecificMediaList
    );

  // ots & cost card data
  const otsAndCostCardData = [
    {
      title: "Total Impressions",
      value: ots,
    },
    {
      title: "TG Impressions",
      value: targetOts,
    },
    {
      title: "Estimated Cost",
      value: totalEstCost,
    },
  ];

  return (
    <>
      <h5 className="mt-5">Site Selection Overview</h5>
      <div className="row align-items-stretch">
        <div className="col-6">
          <SelectionOverviewCard data={sitesCardData} />
        </div>
        <div className="col-6">
          <SelectionOverviewCard data={otsAndCostCardData} />
        </div>
      </div>
    </>
  );
}

/**
 * Page
 */
export default function MediaSelectionPage() {
  const dispatch = useDispatch();
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const cityCampaignPlan =
    useSelector((state) => state.campaignPlan.campaignPlan) || {};
  // const { targetGroupId } = cityCampaignPlan || {};

  // tg-name
  // const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  //open media modal
  const openCreateMediaModal = useSelector(
    (state) => state.createMediaModal.openModal
  );

  // CampaignMedias
  const campaignMediasMap = useSelector(
    (state) => state.mediaSelection.mediaIdToCampaignMedia
  );
  const campaignMedias = Object.values(campaignMediasMap);

  useEffect(() => {
    // Reset Actions
    dispatch(resetRoadStretches());
    dispatch(resetSellerMediaByStretchIds());
    dispatch(resetRoadStretches());
    dispatch(resetCampaignPlanDetails());
    dispatch(restCampaignDetails());
    dispatch(resetTgGroup());
    dispatch(resetMediaSelection());

    // Get Data Actions
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
  }, [dispatch, campaignId, cityId]);

  // get tg info
  // useGetTgInfo(targetGroupId);

  return (
    <div className="content-wrapper media-selection">
      <MediaSelectionHeader campaign={campaign} cityId={cityId} />

      <div className="page-content">
        {/* city view Cards */}
        {/* <CityViewCards
          tgName={tgName}
          campaign={campaign}
          cityPlan={cityCampaignPlan}
          campaignMedia={campaignMedia}
        /> */}

        <StretchSelectionOverview
          campaign={campaign}
          cityPlan={cityCampaignPlan}
        />

        <SiteSelectionOverview
          campaign={campaign}
          cityPlan={cityCampaignPlan}
          campaignMedias={campaignMedias}
        />

        {/* Estimated Price Breakdown Table */}
        {/* <EstimatedPriceBreakDown
          tgName={tgName}
          campaign={campaign}
          campaignPlan={cityCampaignPlan}
          submissionImpact={true}
        /> */}

        {/* Stretch And Media Selection Table */}
        <RoadAndMediaSection
          cityPlan={cityCampaignPlan}
          campaignMediasMap={campaignMediasMap}
        />
      </div>

      {/* MODAL */}
      {openCreateMediaModal && (
        <CreateMediaForm campaignId={campaignId} cityId={cityId} />
      )}
    </div>
  );
}
