import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getSharedCampaigns } from "../../../actions/campaign/CampaignActions";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import { CampaignRedirect } from "../components/CampaignRedirect";
import { CampaignStatus } from "../components/HeaderNav";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Campaign Name",
      className: "col-2",
    },
  },

  {
    title: {
      displayName: "Status",
      className: "text-left col-2",
    },
  },

  {
    title: {
      displayName: "No. of Sites",
      className: "text-right col-1",
    },
  },

  {
    title: {
      displayName: "Target Impressions",
      className: "text-right col-2 d-none",
    },
  },

  {
    title: {
      displayName: "Cost",
      className: "text-right col-2 d-none",
    },
  },

  {
    title: {
      displayName: "Last updated",
      className: "text-right col-2",
    },
  },

  {
    title: {
      displayName: "Actions",
      className: "text-center col-1",
    },
  },
];

// Page Components
function CampaignName({ title }) {
  return <td>{title}</td>;
}

function SiteCount({ siteCount }) {
  return <td className="text-right">{siteCount}</td>;
}

function TargetImpressions() {
  return <td className="text-right d-none">--</td>;
}

function Cost({ eachCampaign }) {
  const { cost, planStatus } = eachCampaign;

  const costStatus = {
    LIVE: "Live: Rs ",
    SCHEDULED: "Est: ",
    COMPLETED: "Final: ",
    REVIEW: "Est Rs ",
    DRAFT: "Est: ",
  };
  return (
    <td className="text-right d-none">
      {costStatus[planStatus]}
      {cost}
    </td>
  );
}

function LastUpdated({ lastUpdated }) {
  const lastUpdatedDate = new Date(lastUpdated).toDateString();
  return <td className="text-right">{lastUpdatedDate}</td>;
}

function TableAction({ id, state }) {
  return (
    <td className="text-center">
      <CampaignRedirect id={id} state={state} />
    </td>
  );
}

function SharedCampaignsTableRow({ eachCampaign }) {
  return (
    <tr>
      <CampaignName title={eachCampaign.title} />
      <td>
        <CampaignStatus state={eachCampaign.planStatus} />
      </td>
      <SiteCount siteCount={eachCampaign.mediaCount} />
      <TargetImpressions targetImpressions={eachCampaign} />
      <Cost eachCampaign={eachCampaign} />
      <LastUpdated lastUpdated={eachCampaign.updatedOn} />
      <TableAction id={eachCampaign.id} state={eachCampaign.planStatus} />
    </tr>
  );
}

function SharedCampaignsTable() {
  // Selector
  const sharedCampaignList = useSelector(
    (state) => state.campaign.sharedCampaignList
  );

  return (
    <div className="card shadow">
      <div className="card-body">
        <h4 className="my-3">{"Campaigns"}</h4>

        {/* Site Campaigns Table */}
        <div className="table-responsive mt-1">
          <table className="table table-media">
            {/* Table Headers */}
            <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
            <tbody>
              {/* Table row */}
              {sharedCampaignList.map((eachCampaign) => (
                <SharedCampaignsTableRow
                  key={eachCampaign.id}
                  eachCampaign={eachCampaign}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

/**
 * Page
 */
export default function BuyerHomePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSharedCampaigns());
  }, [dispatch]);

  const pageActions = (
    <>
      <p className="pt-2">Version : {process.env.REACT_APP_VERSION}</p>
      {/* <p>Last updated Thursday, March 20, 2021, 10:45 PM</p> */}
    </>
  );

  return (
    <div className="content-wrapper bg-alt min-vh-100">
      {/* Page Header */}
      <PageHeader
        title={"Dashboard"}
        border={false}
        shadow={false}
        actions={pageActions}
      />

      <div className="page-content">
        <SharedCampaignsTable />
      </div>
    </div>
  );
}
