import { Link } from "react-router-dom";

/// Utils and Constants
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { CampaignState } from "./planningConstants";

export function addCityAction(campaignId, selectedCities, tgId, history) {
  history.push({
    pathname: constructRedirectPath(`/campaign/${campaignId}/planning`),
    search: `?_extCities=${selectedCities}&_tg=${tgId}`,
  });
}

export function continueToPlanning(campaignId, cityId, history) {
  history.push({
    pathname: constructRedirectPath(`/campaign/${campaignId}/planning`),
    search: `?_city=${cityId}`,
  });
}

export function CityRedirect({
  campaignId,
  cityId,
  campaignPlanState,
  displayText,
  className = "",
}) {
  const redirectObject = {
    [CampaignState.DRAFT]: `/campaign/${campaignId}/city/${cityId}/draft`,
    [CampaignState.REVIEW]: `/campaign/${campaignId}/city/${cityId}/review`,
    [CampaignState.CONFIRMED]: `/campaign/${campaignId}/city/${cityId}/confirm`,
    [CampaignState.SCHEDULED]: `/campaign/${campaignId}/city/${cityId}/schedule`,
    [CampaignState.LIVE]: `/campaign/${campaignId}/city/${cityId}/live`,
    [CampaignState.COMPLETED]: `/campaign/${campaignId}/city/${cityId}/complete`,
    [CampaignState.CANCELLED]: `/campaign/${campaignId}/city/${cityId}/cancel`,
    [CampaignState.STOPPED]: `/campaign/${campaignId}/city/${cityId}/stop`,
  };

  const redirectUrl1 = redirectObject[campaignPlanState] || "";

  return (
    <Link className={className} to={constructRedirectPath(redirectUrl1)}>
      {displayText}
    </Link>
  );
}

export function CampaignRedirect({ id, state }) {
  const redirectObject = {
    [CampaignState.DRAFT]: `/campaign/${id}/draft`,
    [CampaignState.REVIEW]: `/campaign/${id}/review`,
    [CampaignState.CONFIRMED]: `/campaign/${id}/confirm`,
    [CampaignState.SCHEDULED]: `/campaign/${id}/schedule`,
    [CampaignState.LIVE]: `/campaign/${id}/live`,
    [CampaignState.COMPLETED]: `/campaign/${id}/complete`,
    [CampaignState.CANCELLED]: `/campaign/${id}/cancel`,
    [CampaignState.STOPPED]: `/campaign/${id}/stop`,
  };

  const redirectUrl = redirectObject[state] || "";

  return <Link to={constructRedirectPath(redirectUrl)}>View Details</Link>;
}
