import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// API
import { getRouteTypesFn } from "../../apis/OrgRouteTypesAPI";

// Constants and Utils
import { getErrorMessage } from "../../utils/util";
import { RouteTypes } from "../../constants/action-constants/RouteTypesActionConstants";

function* getRouteTypes() {
  try {
    const routeTypes = yield getRouteTypesFn();

    yield put({
      type: RouteTypes.GET_ROUTE_TYPES_SUCCESS,
      payload: { routeTypes },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: RouteTypes.GET_ROUTE_TYPES_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(RouteTypes.GET_ROUTE_TYPES, getRouteTypes)]);
}
