import { getPoiTypeLayerDataUrl } from "../urls/GeoDataURL";
import { sendGetRequest } from "../utils/api/apiUtils";
import PoiTypeLayerPoints from "../utils/PoiTypeLayerPoints.json";

// sdk
import AtlasClient from "../sdk/AtlasClient";

// TODO::Integrate Atlas API
export async function getPoiTypeLayerDataFn(poiTypeLayer, bbox) {
  // Construct NorthWest and SouthEast coordinates from the bbox
  const bboxArray = bbox.split(",");
  const nw = `${bboxArray[3]},${bboxArray[2]}`;
  const se = `${bboxArray[1]},${bboxArray[0]}`;

  // const params = {
  //   nw: nw,
  //   se: se,
  // };

  // const headerParams = {
  //   "Content-Type": "application/json",
  // };

  // key, which decides which API to call (KYP or ATLAS)
  // const geoService = process.env.REACT_APP_GEO_SERVICE;
  // if (geoService === "KYP") {
  //   const url = getPoiTypeLayerDataUrl.replace(":poiTypeLayer", poiTypeLayer);

  //   const poiTypeLayerData = sendGetRequest(url, params, true, headerParams);
  //   return poiTypeLayerData;
  // }

  // getting unique keys
  const uniquePoiTypeLayer = [...new Set(poiTypeLayer)];

  // api
  const poiTypeLayerData = await AtlasClient.heatmapGeoWithinBBox(
    nw,
    se,
    uniquePoiTypeLayer
  );

  return poiTypeLayerData;
}
