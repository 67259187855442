import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// APIs
import { getPoiBrandData } from "../../../apis/PoiAPI";

// Constants and Utils
import { getErrorMessage } from "../../../utils/util";
import { ActionTypes } from "../../constants/ActionConstants";

function* getPoiBrandInfo(action) {
  try {
    const { poiToBrandMap = {}, cityId, bbox } = action.payload;
    const poiIdToBrandData = {};

    // Note we cannot use forEach with yield keyword
    for (const eachPoiId in poiToBrandMap) {
      const brandIds = poiToBrandMap[eachPoiId];
      const poiTyeIds = Object.keys(poiToBrandMap);
      const brandInfoArray = [];
      for (const eachPoiTyeId of poiTyeIds) {
        const brandInfo = yield getPoiBrandData(
          bbox,
          poiToBrandMap[eachPoiTyeId],
          eachPoiTyeId
        );
        brandInfoArray.push(brandInfo);
      }
      poiIdToBrandData[eachPoiId] = brandInfoArray;
    }

    yield put({
      type: ActionTypes.MapView.SET_POI_AND_BRANDS_SUCCESS,
      payload: { poiIdToBrandData, cityId },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: ActionTypes.MapView.SET_POI_AND_BRANDS_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.MapView.SET_POI_AND_BRANDS, getPoiBrandInfo),
  ]);
}
