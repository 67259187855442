// Constants
import { DurationConstants } from "../../constants/GeneralConstants";

// This field stores the no of days used in backend for the impressions
// For example if the response has 300000 imp as a value
// Then imp per day = 300000/30
export const IMP_DAYS = DurationConstants.THIRTY_DAYS;

/**
 * This function updates the segment/stretch trace to be able to
 * display on the map..
 * @param {*} roadObject
 */
export function updateRoadTrace(roadObject = {}) {
  const { trace = [] } = roadObject || {};
  const newTrace = trace.map((eachCoordinate) => [
    eachCoordinate.latitude,
    eachCoordinate.longitude,
  ]);
  roadObject.trace = newTrace;
  return roadObject;
}

export function updateImpDetails(roadObject = {}) {
  const { otsAvg, otsLitAvg } = roadObject || {};
  roadObject.otsAvg = otsAvg ? otsAvg / IMP_DAYS : otsAvg;
  roadObject.otsLitAvg = otsLitAvg ? otsLitAvg / IMP_DAYS : otsLitAvg;
  return roadObject;
}

export function updateTgImpDetails(roadObj = {}) {
  const { targetOts, targetOtsLit, genericOts, genericOtsLit, ots, otsLit } =
    roadObj || {};
  roadObj.targetOts = targetOts ? targetOts / IMP_DAYS : targetOts;
  roadObj.targetOtsLit = targetOtsLit ? targetOtsLit / IMP_DAYS : targetOtsLit;
  roadObj.genericOts = genericOts ? genericOts / IMP_DAYS : genericOts;
  roadObj.genericOtsLit = genericOtsLit
    ? genericOtsLit / IMP_DAYS
    : genericOtsLit;
  roadObj.ots = ots ? ots / IMP_DAYS : ots;
  roadObj.otsLit = otsLit ? otsLit / IMP_DAYS : otsLit;

  return roadObj;
}
